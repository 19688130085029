import { MultiSelectOption } from "../@types/advancedFormFilter.types";

export const consutantGraduationLevelOption: MultiSelectOption[] = [
    { label: "UG", value: "UG" },
    { label: "PG", value: "PG" },
    { label: "PhD", value: "PhD" },
];

export const skillLevelOption: MultiSelectOption[] = [
    { label: "BEGINNER", value: "BEGINNER" },
    { label: "INTERMEDIATE", value: "INTERMEDIATE" },
    { label: "EXPERT", value: "EXPERT" },
];

export const consultantCurrentStatusOption: MultiSelectOption[] = [
    { label: "Available", value: "Available" },
    { label: "Working", value: "Working" },
    { label: "Booked", value: "Booked" },
];

// TODO: @umesh include all the country names
export const countryNameOption: MultiSelectOption[] = [
    { label: "China", value: "China" },
    { label: "Hong Kong", value: "Hong Kong" },
    { label: "India", value: "India" },
    { label: "Japan", value: "Japan" },
    { label: "Macau", value: "Macau" },
    { label: "Mongolia", value: "Mongolia" },
    { label: "North Korea", value: "North Korea" },
    { label: "South Korea", value: "South Korea" },
];

// TODO: @umesh include all the prefecture names
export const prefectureOption: MultiSelectOption[] = [
    { label: "Hokkaidō", value: "Hokkaidō" },
    { label: "Aomori", value: "Aomori" },
    { label: "Iwate", value: "Iwate" },
    { label: "Miyagi", value: "Miyagi" },
    { label: "Akita", value: "Akita" },
    { label: "Yamagata", value: "Yamagata" },
    { label: "Fukushima", value: "Fukushima" },
    { label: "Ibaraki", value: "Ibaraki" },
    { label: "Tochigi", value: "Tochigi" },
    { label: "Gunma", value: "Gunma" },
    { label: "Saitama", value: "Saitama" },
    { label: "Chiba", value: "Chiba" },
    { label: "Kanagawa", value: "Kanagawa" },
    { label: "Niigata", value: "Niigata" },
    { label: "Toyama", value: "Toyama" },
    { label: "Ishikawa", value: "Ishikawa" },
    { label: "Fukui", value: "Fukui" },
    { label: "Yamanashi", value: "Yamanashi" },
    { label: "Nagano", value: "Nagano" },
    { label: "Gifu", value: "Gifu" },
    { label: "Shizuoka", value: "Shizuoka" },
    { label: "Aichi", value: "Aichi" },
    { label: "Mie", value: "Mie" },
    { label: "Shiga", value: "Shiga" },
    { label: "Kyoto", value: "Kyoto" },
    { label: "Osaka", value: "Osaka" },
    { label: "Hyōgo", value: "Hyōgo" },
    { label: "Nara", value: "Nara" },
    { label: "Wakayama", value: "Wakayama" },
    { label: "Tottori", value: "Tottori" },
    { label: "Shimane", value: "Shimane" },
    { label: "Okayama", value: "Okayama" },
    { label: "Hiroshima", value: "Hiroshima" },
    { label: "Yamaguchi", value: "Yamaguchi" },
    { label: "Tokushima", value: "Tokushima" },
    { label: "Kagawa", value: "Kagawa" },
    { label: "Ehime", value: "Ehime" },
    { label: "Kōchi", value: "Kōchi" },
    { label: "Fukuoka", value: "Fukuoka" },
    { label: "Saga", value: "Saga" },
    { label: "Nagasaki", value: "Nagasaki" },
    { label: "Kumamoto", value: "Kumamoto" },
    { label: "Ōita", value: "Ōita" },
    { label: "Miyazaki", value: "Miyazaki" },
    { label: "Kagoshima", value: "Kagoshima" },
    { label: "Okinawa", value: "Okinawa" },
];

export const hobbiesOption: MultiSelectOption[] = [
    { label: "Writing", value: "Writing" },
    { label: "Blogging", value: "Blogging" },
    { label: "Podcasting", value: "Podcasting" },
    { label: "Marketing", value: "Marketing" },
    { label: "Learning languages", value: "Learning languages" },
    { label: "Photography", value: "Photography" },
    { label: "Travel", value: "Travel" },
    { label: "Sports", value: "Sports" },
    { label: "Dance", value: "Dance" },
    { label: "Reading", value: "Reading" },
    { label: "Listening to music", value: "Listening to music" },
];

// TODO @umesh add other required languages
export const languageOption: MultiSelectOption[] = [
    { label: "Japanese", value: "Japanese" },
    { label: "English", value: "English" },
    { label: "Mandarin", value: "Mandarin" },
    { label: "French", value: "French" },
    { label: "Arabic", value: "Arabic" },
    { label: "Bengali", value: "Bengali" },
    { label: "Russian", value: "Russian" },
    { label: "Portuguese", value: "Portuguese" },
    { label: "Indonesian", value: "Indonesian" },
    { label: "Hindi", value: "Hindi" },
    { label: "Telugu", value: "Telugu" },
    { label: "Tamil", value: "Tamil" },
    { label: "Malayalam", value: "Malayalam" },
    { label: "Kannada", value: "Kannada" },
];
