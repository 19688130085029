import { useMutation, useQuery } from "react-query";
import { JobApplicationReqBodyInf, JobApplicationRequestInf } from "../../@types/jobApplication.types";
import { get, post, patch } from "../../adapters";
import { CONSULTANT_APPLY_JOB, JOB_APPLN_STATUS_CHECK, POCKETBASE_URL } from "../../constants/ApiPaths-constants";
import axios from "axios";

export const useConsultantApplyJob = () => {
    return useMutation((data: JobApplicationReqBodyInf) =>
        post({
            url: `${POCKETBASE_URL}/api/collections/jobApplications/records`,
            requestBody: {
                jobDescription: data?.jobDescriptionId,
                jobAppliedStatus: data?.isActive,
                jobApplicationStatus: data?.jobApplicationStatus,
                consultant: data?.consultantId,
            },
        }),
    );
};

export const useCheckJobApplnStatus = (jobDescId: any, consultantId: string | null) => {
    return useQuery(
        ["check_job_appln_status", "jobDescId", jobDescId, "consultantId", consultantId],

        async () => {
            const res = await get({
                url: `${POCKETBASE_URL}/api/collections/jobApplications/records?perPage=1&filter=${encodeURIComponent(
                    `(jobDescription='${jobDescId}' && consultant='${consultantId}')`,
                )}`,
            })
                .then((response: any) => {
                    if (response?.data?.items.length > 0) return response?.data?.items[0];
                    return {};
                })
                .catch((err: any) => {
                    throw new Error(`ERROR:: While fetching invitations by consultant :- ${JSON.stringify(err)}`);
                });
            return res;
        },
        {
            refetchOnWindowFocus: true,
            enabled: !!(consultantId && jobDescId),
            retry: 2,
        },
    );
};
