import { Divider, Text, useToast } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ConsultantProfileFormInf, ConsultantProfilePayload } from "../../../@types/consultantProfileForm.types";
import PageHeader from "../../../assets/ReactSvgIcons/PageHeader-component";
import { formDataModifier } from "../../../components/consultantViewPage-components/ConsultantProfileForm/ConsultantProfile.helper";
import ConsultantProfileForm from "../../../components/consultantViewPage-components/ConsultantProfileForm/ConsultantProfileForm";
import AppLayout from "../../../components/global/AppLayout/AppLayout-component";
import Breadcrumbs from "../../../components/global/Breadcrumbs/Breadcrumbs-component";
import Footer from "../../../components/global/Footer/Footer-component";
import NavBar from "../../../components/global/NavBar/NavBar-component";
import { UserType } from "../../../constants/Enum-constants";
import { CONSULTANT_JOB_SEARCH_PAGE, CONSULTANT_CREATE_PROFILE_PAGE } from "../../../constants/WebAppRoute-constants";
import { useUpdateConsultantProfile } from "../../../hooks/consultantProfile.hook";
import {
    useGetSubscriptionId,
    useGetSubscriberId,
    useLocalStConsultantIdHook,
    useLocalSttLoggedInUserTypeHook,
} from "../../../lib/authFns";

//TODO: Umesh - Replace this values with logged in User info.

const CreateConsultantProfile = () => {
    const { t } = useTranslation("consultantProfileForm");

    const _subscriptionId = useGetSubscriptionId();
    const _subscriberId = useGetSubscriberId();
    const userInfo = {
        subscriptionId: _subscriptionId,
        userId: _subscriberId ? _subscriberId.toString() : null,
    };

    const { mutateAsync, isLoading } = useUpdateConsultantProfile();

    const toast = useToast();
    const navigate = useNavigate();
    // eslint-disable-next-line no-unused-vars
    const [consultantIdLocalState, setConsultantId] = useLocalStConsultantIdHook();
    // eslint-disable-next-line no-unused-vars
    const [userTypeLocalState, setUserType] = useLocalSttLoggedInUserTypeHook();

    const handleFormSubmit = async (formData: ConsultantProfileFormInf) => {
        // TODO: @umesh Make this payload proper as the API request body
        const payload: ConsultantProfilePayload = {
            ...formDataModifier(formData),
            ...userInfo,
            consultantAvatar: "",
        };

        try {
            await mutateAsync(payload, {
                onSuccess: (data: any) => {
                    const _resData = data?.data;
                    toast({
                        title: t("createConsultantProfile.success"),
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                    if (_resData?.consultantId !== null) {
                        setConsultantId(_resData?.consultantId || null);
                        setUserType(UserType.CONSULTANT);
                        navigate(CONSULTANT_JOB_SEARCH_PAGE.url, { replace: true });
                        //TODO: @umesh- Remove this once the recoil is added and App.tsx file should detect this change.
                        window.location.reload();
                    }
                },
                onError: () => {
                    toast({
                        title: t("createConsultantProfile.failure"),
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                },
            });
        } catch (error) {
            toast({
                title: t("createConsultantProfile.failure"),
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const breadCrumbsData = [
        {
            _jsxContent: CONSULTANT_CREATE_PROFILE_PAGE.pageName,
            pageId: CONSULTANT_CREATE_PROFILE_PAGE.pageId,
            url: CONSULTANT_CREATE_PROFILE_PAGE.url,
            isCurrentPage: true,
        },
    ];
    return (
        <AppLayout>
            <NavBar />
            <PageHeader pageText={t("createConsultantProfilePageTitle")} previousPagePath={null} />
            <Breadcrumbs data={breadCrumbsData} />
            <Divider h={4} borderBottomWidth={0} />
            <Text w="full" textAlign={"left"} fontSize={"xs"} px="4vw">
                {t("subtitle")}
            </Text>
            <ConsultantProfileForm
                onFormSubmit={(formData: ConsultantProfileFormInf) => handleFormSubmit(formData)}
                isLoading={isLoading}
            />
            <Footer />
        </AppLayout>
    );
};

export default CreateConsultantProfile;
