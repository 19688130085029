import { Box, Flex, Heading, Text, IconButton, UnorderedList, ListItem, VStack, Avatar } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ConsultantProfileResponse } from "../../../@types/consultant.types";
import BannerBg from "../../../assets/Images/ClientProfileBanner.svg";
import EditIcon from "../../../assets/ReactSvgIcons/EditIcon-component";
import LocationThinIcon from "../../../assets/ReactSvgIcons/LocationThinIcon-component";
import MailIcon from "../../../assets/ReactSvgIcons/MailIcon-component";
import PhoneIcon from "../../../assets/ReactSvgIcons/PhoneIcon-component";
import { CONSULTANT_EDIT_PROFILE_PAGE } from "../../../constants/WebAppRoute-constants";
import AppLayout from "../../global/AppLayout/AppLayout-component";
import Footer from "../../global/Footer/Footer-component";
import NavBar from "../../global/NavBar/NavBar-component";
import SvgIconWrapper from "../../global/SvgIconWrapper/SvgIconWrapper.component";

interface EducationData {
    institution: string;
    course: string;
    startDate: string;
    endDate: string;
}

interface CertificationData {
    certificationTitle: string;
    issuer: string;
}

interface ExperienceData {
    project?: string;
    company?: string;
    role: string;
    startDate: string;
    endDate: string;
}

interface ProfileProp {
    consultantName: string;
    role: string;
    phoneNo: string;
    profileImageSrc: string;
    email: string;
    address: string;
    about: string;
    currentProject?: ExperienceData;
    education: EducationData[];
    experience?: ExperienceData[];
    certifications?: CertificationData[];
    hobbies: string;
}

const getProfilePropsFromProfileResponse = (response: any): ProfileProp => {
    return {
        consultantName: `${response.consultantName}`,
        address: `${response.address}`,
        profileImageSrc: response.consultantProfilePic || response.consultantAvatar,
        about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean amet, aliquam auctor pharetra in.\
         Mattis lectus scelerisque gravida ut ultrices aenean quis vel duis. Scelerisque massa id sed adipiscing \
          tortor est quis gravida. Fermentum sed amet diam adipiscing adipiscing habitant. Tristique \
          suspendisse suspendisse habitant amet eget bibendum.",
        phoneNo: response.phoneNo,
        email: response?.expand?.userDetails?.email ?? "",
        role: response.consultantCurrentRole,
        currentProject: {
            project: response.consultantCurrentProject,
            startDate: response.consultantCurrentProjectStart,
            endDate: response.consultantCurrentProjectEnd,
            role: response.consultantCurrentRole,
        },
        education: response.consultantEducations.map((education) => ({
            course: education.educationCoursename,
            institution: education.educationInstitution,
            startDate: education.educationCourseStartyear,
            endDate: education.educationCourseEndyear,
        })),
        experience: response.consultantWorkExperiences.map((experience) => ({
            company: experience.workExperienceCompany,
            role: experience.workExperienceRole,
            startDate: experience.workExperienceFrom,
            endDate: experience.workExperienceTo,
        })),
        certifications: response.consultantCertifications.map((certification) => ({
            certificationTitle: certification.certificationTitle,
            issuer: certification.certificationIssuedby,
        })),
        hobbies: response.consultantHobbies,
    };
};

const textStyles = {
    fontFamily: "Poppins",
    fontSize: "xs",
    lineHeight: 7,
    fontWeight: "normal",
    color: "gray.800",
    mt: 5,
};

const NamedList = ({
    title,
    elements,
    alignItems = "center",
}: {
    title: string;
    elements: string[];
    headerSize: string;
    alignItems?: string;
}) => (
    <Flex direction="column" alignItems={alignItems}>
        <Heading fontFamily={"Raleway"} as="h2" size="md">
            {title}
        </Heading>
        <UnorderedList listStyleType="none">
            {elements.map((row, idx) => (
                <ListItem key={idx}>
                    <Text {...textStyles} textAlign={"center"}>
                        {row}
                    </Text>
                </ListItem>
            ))}
        </UnorderedList>
    </Flex>
);

const ConsultantProfileView = ({ data }: { data: ConsultantProfileResponse }) => {
    const profileData: ProfileProp = getProfilePropsFromProfileResponse(data);

    const navigate = useNavigate();

    const handleEditProfileClick = () => {
        navigate(CONSULTANT_EDIT_PROFILE_PAGE.url);
    };

    return (
        <AppLayout minH={"100vh"}>
            <NavBar />
            <Box
                borderRadius={"2xl"}
                mt={10}
                mx={"4vw"}
                py={12}
                style={{ backgroundImage: `url(${BannerBg})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}
                textAlign={"center"}
            >
                <Text
                    fontFamily="Poppins"
                    fontWeight={"semibold"}
                    fontSize={"5xl"}
                    color={"gray.100"}
                    textAlign={"center"}
                >
                    {profileData.consultantName}
                </Text>
                <Text
                    fontFamily="Poppins"
                    color={"gray.100"}
                    textAlign={"center"}
                    fontWeight={"medium"}
                    fontSize={"2xl"}
                >
                    {profileData.role}
                </Text>

                <Box
                    display="flex"
                    flexDirection={"row"}
                    paddingRight={20}
                    paddingLeft={20}
                    justifyContent="space-between"
                >
                    <Box display="flex" flexDirection={"column"} pb={5}>
                        <Box display="flex" pb={4}>
                            <IconButton
                                colorScheme="whiteAlpha"
                                variant="ghost"
                                aria-label="phone"
                                icon={<SvgIconWrapper svgComp={<PhoneIcon />} w={10} h={10} />}
                            />
                            <Text ml={8} color={"gray.100"} fontWeight={"medium"} fontSize={"xl"}>
                                {profileData.phoneNo}
                            </Text>
                        </Box>
                        <Box display="flex" pt={4} pb={4}>
                            <IconButton
                                colorScheme="whiteAlpha"
                                variant="ghost"
                                aria-label="mail"
                                icon={<SvgIconWrapper svgComp={<MailIcon />} w={10} h={10} />}
                            />
                            <Text ml={8} color={"gray.100"} fontWeight={"medium"} fontSize={"xl"}>
                                {profileData.email}
                            </Text>
                        </Box>
                        <Box display="flex" pt={4}>
                            <IconButton
                                colorScheme="whiteAlpha"
                                variant="ghost"
                                aria-label="location"
                                icon={<LocationThinIcon stroke="#8BB9E2" />}
                            />
                            <Text ml={8} color={"gray.100"} fontWeight={"medium"} fontSize={"xl"}>
                                {profileData.address}
                            </Text>
                        </Box>
                    </Box>

                    <Box position={"relative"}>
                        {/* <Avatar
                            position={"absolute"}
                            right={-3}
                            bottom={-20}
                            width={"3xs"}
                            height={"3xs"}
                            src={profileData.profileImageSrc}
                        /> */}
                    </Box>
                    <Box position={"relative"}>
                        <IconButton
                            colorScheme="whiteAlpha"
                            variant="ghost"
                            aria-label="edit"
                            position={"absolute"}
                            right={0}
                            bottom={5}
                            icon={<EditIcon width="2em" stroke="#8BB9E2" />}
                            onClick={handleEditProfileClick}
                        />
                    </Box>
                </Box>
            </Box>
            <VStack spacing="16" mx={"20"} py="12" px="12vw" borderRadius="lg" borderWidth="thin" mt={24}>
                {profileData.about && <NamedList headerSize="h2" title="About" elements={[profileData.about]} />}
                {profileData.currentProject && (
                    <NamedList
                        {...textStyles}
                        headerSize="h2"
                        title="Current Project"
                        elements={[
                            `${profileData.currentProject.project}, (${profileData.currentProject.startDate} ${
                                profileData.currentProject.endDate
                                    ? `- ${profileData.currentProject.endDate}`
                                    : "present"
                            } )`,
                        ]}
                    />
                )}
                {profileData.education && (
                    <NamedList
                        headerSize="h2"
                        title="Education"
                        elements={profileData.education.map(
                            (education) =>
                                `${education.course}, ${education.institution}, (${education.startDate} - ${education.endDate}),`,
                        )}
                    />
                )}
                {profileData.certifications && (
                    <NamedList
                        headerSize="h2"
                        title="Certifications"
                        elements={profileData.certifications.map(
                            (certification) => `${certification.certificationTitle}, ${certification.issuer}`,
                        )}
                    />
                )}
                {profileData.experience && (
                    <NamedList
                        headerSize="h2"
                        title="Experience"
                        elements={profileData.experience.map(
                            (experience) =>
                                `${experience.company} (${experience.startDate}${
                                    experience.endDate ? `- ${experience.endDate}` : ""
                                }),`,
                        )}
                    />
                )}
                {profileData.hobbies && <NamedList headerSize="h2" title="Hobbies" elements={[profileData.hobbies]} />}
            </VStack>

            <Box w="full" mt="auto">
                <Footer />
            </Box>
        </AppLayout>
    );
};

export default ConsultantProfileView;
