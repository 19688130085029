import React, { useMemo } from "react";
import { INVITATION_TABLE_COLUMNS } from "./invitationTableColumns";
import { InvitationTableInf } from "../../../@types/InvitationTable.types";
import ReactTable from "../../global/Table/Table-component";

const InvitationTable = ({ data }: InvitationTableInf) => {
    //  TODO: data to be replaced by that from API

    const tableData = useMemo(() => data, [data]);
    const columns = useMemo(() => INVITATION_TABLE_COLUMNS, []);

    //  TODO: header styles to be modified
    return <ReactTable data={tableData} columns={columns} />;
};

export default InvitationTable;
