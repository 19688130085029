import React from "react";

const NotificationIcon = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.1667 15.8503V11.6666C22.1667 7.91348 19.6175 4.75181 16.1642 3.80098C15.8223 2.93998 14.987 2.33331 14 2.33331C13.013 2.33331 12.1777 2.93998 11.8358 3.80098C8.3825 4.75298 5.83333 7.91348 5.83333 11.6666V15.8503L3.84183 17.8418C3.73328 17.95 3.6472 18.0785 3.58853 18.2201C3.52987 18.3616 3.49978 18.5134 3.5 18.6666V21C3.5 21.3094 3.62292 21.6061 3.84171 21.8249C4.0605 22.0437 4.35725 22.1666 4.66667 22.1666H23.3333C23.6428 22.1666 23.9395 22.0437 24.1583 21.8249C24.3771 21.6061 24.5 21.3094 24.5 21V18.6666C24.5002 18.5134 24.4701 18.3616 24.4115 18.2201C24.3528 18.0785 24.2667 17.95 24.1582 17.8418L22.1667 15.8503ZM22.1667 19.8333H5.83333V19.1496L7.82483 17.1581C7.93338 17.05 8.01947 16.9214 8.07814 16.7799C8.1368 16.6383 8.16689 16.4865 8.16667 16.3333V11.6666C8.16667 8.45015 10.7835 5.83331 14 5.83331C17.2165 5.83331 19.8333 8.45015 19.8333 11.6666V16.3333C19.8333 16.6436 19.9558 16.94 20.1752 17.1581L22.1667 19.1496V19.8333ZM14 25.6666C14.7225 25.6676 15.4274 25.4433 16.0166 25.0252C16.6058 24.607 17.0501 24.0156 17.2877 23.3333H10.7123C10.9499 24.0156 11.3942 24.607 11.9834 25.0252C12.5726 25.4433 13.2775 25.6676 14 25.6666V25.6666Z"
                fill="#0D491C"
            />
            <circle cx="19.8333" cy="6.99404" r="5.83333" fill="#FF0000" />
            <path
                d="M18.021 5.04842C18.3819 4.90531 18.7428 4.72176 19.1037 4.49776C19.4708 4.27376 19.7943 4.00931 20.0743 3.70442H20.8677V10.1724H19.7383V5.21642C19.5703 5.34709 19.3588 5.47465 19.1037 5.59909C18.8485 5.72353 18.6028 5.8262 18.3663 5.90709L18.021 5.04842Z"
                fill="white"
            />
        </svg>
    );
};

export default NotificationIcon;
