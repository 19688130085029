import React from "react";

const BrandLogo = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.7695 39.6594C19.7629 39.6543 19.7551 39.6511 19.7469 39.6501C19.3507 39.4116 19.0246 39.0662 18.8035 38.6508L13.7192 29.3081V0.00457764H28.3367V29.305L23.2469 38.657C23.0822 38.959 22.8616 39.2245 22.5977 39.4384C22.3338 39.6522 22.0318 39.8102 21.7089 39.9033C21.386 39.9964 21.0485 40.0228 20.7157 39.9809C20.383 39.9391 20.0615 39.8298 19.7695 39.6594Z"
                fill="#231F20"
            />
            <path
                d="M11.6647 25.5359L7.13072 17.2067L7.11705 17.1784L0.403274 4.83602C0.134577 4.34221 -0.00442354 3.78423 0.000107324 3.21759C0.00463822 2.65095 0.15254 2.09541 0.429097 1.60628C0.705655 1.11715 1.10122 0.711473 1.57642 0.429625C2.05163 0.147779 2.5899 -0.00040558 3.13766 -0.000182855H11.6647V25.5359Z"
                fill="#3A6D92"
            />
            <path
                d="M30.3353 25.5359L34.8693 17.2067L34.883 17.1784L41.5967 4.83602C41.8654 4.34221 42.0044 3.78423 41.9999 3.21759C41.9954 2.65095 41.8475 2.09541 41.5709 1.60628C41.2943 1.11715 40.8988 0.711473 40.4236 0.429625C39.9484 0.147779 39.4101 -0.00040558 38.8623 -0.000182855H30.3353V25.5359Z"
                fill="#3A6D92"
            />
            <g opacity="0.28">
                <g opacity="0.28">
                    <path
                        d="M19.7676 39.6574C19.7609 39.6523 19.7531 39.649 19.7449 39.6481C19.3487 39.4096 19.0227 39.0642 18.8016 38.6488L13.7173 29.3061V8.21198C13.7767 9.08887 13.813 9.97019 13.8493 10.8471C13.8856 11.724 13.9313 12.6053 13.954 13.4866C14.0134 15.2448 14.0544 16.9982 14.077 18.7568C14.1091 20.5151 14.1198 22.2733 14.1227 24.0315L14.118 26.671L14.1001 29.2023C15.0252 30.9513 15.935 32.7079 16.8294 34.472C17.2852 35.3728 17.736 36.2683 18.1735 37.1779L18.8344 38.5356C18.9386 38.761 19.0702 38.9717 19.2262 39.1624C19.3821 39.354 19.5646 39.5208 19.7676 39.6574Z"
                        fill="#231F20"
                    />
                </g>
            </g>
            <g opacity="0.35">
                <g opacity="0.35">
                    <path
                        d="M28.3334 24.8659C28.2604 23.8291 28.2193 22.7922 28.1694 21.7597C28.1194 20.7272 28.092 19.6854 28.051 18.6486L27.9779 15.5423L27.9352 12.436L27.9215 9.32489L27.9352 6.21862L27.9779 3.11237L28.0416 0.283663C27.126 0.250515 26.21 0.227092 25.294 0.174937C24.2827 0.118363 23.2709 0.0807925 22.2605 0.000793457H28.3343L28.3334 24.8659Z"
                        fill="white"
                    />
                </g>
            </g>
        </svg>
    );
};

export default BrandLogo;
