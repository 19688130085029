import React from "react";
import CommonTabArea from "./CommonTabArea";
import { MatchingProfilesTabCompPropsInf } from "../../../@types/consultantTable.types";
import { useGetMatchingPrfls } from "../../../hooks/jobDescriptions.hook";

const MatchingProfilesTab = (props: MatchingProfilesTabCompPropsInf) => {
    const { jobDescriptionId, activeTableViewType, defaultTabKey } = props;
    const matchingProfilesGetQueryRes = useGetMatchingPrfls(jobDescriptionId);

    return (
        <CommonTabArea
            tableData={matchingProfilesGetQueryRes.data || []}
            jobDescriptionId={jobDescriptionId}
            activeTableViewType={activeTableViewType}
            isFilterShown={false}
            defaultTabKey={defaultTabKey}
            isTableDataLoading={matchingProfilesGetQueryRes.isLoading}
        />
    );
};

export default MatchingProfilesTab;
