import { Flex, Heading, IconButton, Link, Tag, Text, useToast } from "@chakra-ui/react";
import { formatDistanceToNow } from "date-fns";
import React from "react";
import { Link as RRDLink } from "react-router-dom";
import { JobDataInf } from "../../../@types/job.types";
import CopyIcon from "../../../assets/ReactSvgIcons/CopyIcon-component";
import ExperienceIcon from "../../../assets/ReactSvgIcons/ExperienceIcon-component";
import LocationIcon from "../../../assets/ReactSvgIcons/LocationIcon-component";
import SaveIcon from "../../../assets/ReactSvgIcons/SaveIcon-component";
import { CLIENT_JOB_DETAILS_PAGE } from "../../../constants/WebAppRoute-constants";
import SvgIconWrapper from "../../global/SvgIconWrapper/SvgIconWrapper.component";

interface JobListCardPropsInf extends JobDataInf {
    onClickRedirectionLink?: string | null;
    isSelected?: boolean;
}

const JobListCard = (props: JobListCardPropsInf) => {
    const [
        jobDescriptionId,
        position,
        type,
        workLocation,
        experienceRequiredInYears,
        jobMinSalary,
        jobMaxSalary,
        dateOfJobListed,
        isConsultantView = false,
        onClickRedirectionLink = null,
        isSelected = false,
    ] = [
        props.id,
        props.jobPosition,
        props.jobType,
        props.jobLocation,
        props.jobExpectedExperienceYrs,
        props.jobMinSalary,
        props.jobMaxSalary,
        props.created,
        props?.isConsultantView || false,
        props?.onClickRedirectionLink,
        props.isSelected,
    ];
    const location = workLocation;
    const monthlyMaxSalaryAmount = jobMaxSalary ? jobMaxSalary / 1000 : "-";
    const monthlyMinSalaryAmoiunt = jobMinSalary ? jobMinSalary / 1000 : "-";
    const monthlySalary = `${`₹`} ${monthlyMinSalaryAmoiunt}k - \
    ${`₹`} ${monthlyMaxSalaryAmount}k `;
    const listingTimeDistanceFromNow = formatDistanceToNow(new Date(dateOfJobListed));
    const companyName = props?.expand?.clientDetails?.companyName;

    const jobDetailsUrl = `${CLIENT_JOB_DETAILS_PAGE.url}/${jobDescriptionId}`;
    const toast = useToast();

    return (
        <Link as={RRDLink} to={onClickRedirectionLink || "#"}>
            <Flex
                flexDirection={"column"}
                w="full"
                borderWidth={isSelected ? 3 : 1}
                borderColor={isSelected ? "brand.600" : "brand.200"}
                p={4}
                pl={6}
                pt={6}
                borderRadius="xl"
                maxW={"30rem"}
                _hover={{ borderColor: "brand.600", bg: "gray.50", cursor: "pointer" }}
                _active={{ bg: "brand.50" }}
            >
                <Flex w="full" justifyContent={"space-between"} alignItems={"start"}>
                    <Flex flexDirection={"column"} alignItems={"start"}>
                        <Text fontSize={"xx-small"} color={"gray.500"} fontWeight={"medium"} noOfLines={1}>
                            {companyName}
                        </Text>
                        <Heading
                            as="h3"
                            fontWeight="semibold"
                            size="xs"
                            color="gray.700"
                            textAlign={"left"}
                            noOfLines={1}
                        >
                            {position}
                        </Heading>
                        <Tag
                            fontWeight={"medium"}
                            size="xs"
                            fontSize={"0.625rem"}
                            colorScheme={"brand"}
                            mt={1}
                            py={1}
                            px={4}
                            borderRadius="2xl"
                        >
                            {type}
                        </Tag>
                    </Flex>
                    <Text fontSize={"0.625rem"} fontWeight="light" minW="fit-content" noOfLines={1}>
                        {/* 1 day ago */}
                        {listingTimeDistanceFromNow} ago
                    </Text>
                </Flex>
                <Flex justifyContent={"flex-start"} alignItems="center" mt={3}>
                    <SvgIconWrapper svgComp={<LocationIcon />} w={4} h={8} />
                    <Text fontWeight={"normal"} fontSize="xs" ml={4} color={"gray.600"}>
                        {location}
                    </Text>
                </Flex>
                <Flex justifyContent={"flex-start"} alignItems="center" mt={1}>
                    <SvgIconWrapper svgComp={<ExperienceIcon />} w={4} h={8} />
                    <Text
                        fontWeight={"normal"}
                        fontSize="xs"
                        ml={4}
                        color={"gray.600"}
                        textAlign="left"
                        maxW={"80%"}
                        noOfLines={1}
                    >
                        {experienceRequiredInYears} years of experience
                    </Text>
                </Flex>
                <Flex justifyContent={"space-between"} alignItems="center" mt={1}>
                    <Text fontWeight={"semibold"} fontSize="sm" color="brand.900">
                        {monthlySalary}
                    </Text>
                    <IconButton
                        aria-label="copy job link"
                        colorScheme={"whiteAlpha"}
                        boxShadow={"lg"}
                        borderRadius={"lg"}
                        _hover={{ bg: "brand.200" }}
                        _active={{ bg: "brand.400" }}
                        icon={<SvgIconWrapper svgComp={isConsultantView ? <SaveIcon /> : <CopyIcon />} w={4} h={8} />}
                        onClick={(e) => {
                            e.preventDefault();
                            // Copying job details page url to clipboard
                            navigator.clipboard.writeText(`${window.location.hostname}${jobDetailsUrl}`);
                            toast({
                                title: "Job details link copied to clipboard",
                                status: "info",
                                duration: 1500,
                                isClosable: true,
                            });
                        }}
                    />
                </Flex>
            </Flex>
        </Link>
    );
};

export default JobListCard;
