/* eslint-disable react/no-children-prop */
// The above expection in eslint is required since there is a variation with Chakra UI native components
import { CalendarIcon } from "@chakra-ui/icons";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    SimpleGrid,
    Textarea,
    FormControl,
    FormErrorMessage,
    Flex,
    forwardRef,
    Input,
    InputGroup,
    InputRightElement,
} from "@chakra-ui/react";
import React from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm, SubmitHandler, FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface InterviewFormDataInf {
    jobInvitationMessage: string;
    jobInterviewSchedule: Date | string;
    jobInterviewLink: string;
    jobInterviewRemarks: string;
}
interface InterviewScheduleModalPropInf {
    isOpen: boolean;
    onClose: () => void;
    // eslint-disable-next-line no-unused-vars
    onSubmit: (formData: InterviewFormDataInf) => void;
    isSubmitLoading: boolean;
}

const InterviewScheduleModal = (props: InterviewScheduleModalPropInf) => {
    const { isOpen, onClose, onSubmit, isSubmitLoading } = props;
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<FieldValues>({
        mode: "onBlur",
    });

    const { t } = useTranslation("consultantListing");

    const handleFormSubmit: SubmitHandler<FieldValues> = (data) => {
        console.log("On Form submit - data=", data);
        onSubmit({
            jobInvitationMessage: data.invitationText,
            jobInterviewSchedule: data.interviewDate,
            jobInterviewLink: "http://meet.itmap.com",
            jobInterviewRemarks: "",
        });
    };

    const CustomDatePickerField = forwardRef(({ value, onClick, placeholder }) => {
        return (
            <InputGroup h={12}>
                <Input
                    onClick={onClick}
                    value={value}
                    placeholder={placeholder}
                    bg="white"
                    borderStyle="none"
                    autoComplete="off"
                />
                <InputRightElement children={<CalendarIcon />} onClick={onClick} cursor="pointer" mt={1} />
            </InputGroup>
        );
    });

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg={"gray.100"} borderRadius="2xl" py={8} px={36} minW="2xl">
                    <ModalHeader w="full" textAlign={"center"} fontSize="lg" fontWeight={"medium"}>
                        {t("interviewInviteModal.heading")}
                    </ModalHeader>
                    <ModalBody>
                        <SimpleGrid columns={1} spacingX="40px" spacingY="20px" justifyContent={"center"}>
                            <form onSubmit={handleSubmit(handleFormSubmit)}>
                                <Controller
                                    control={control}
                                    name="invitationText"
                                    rules={{
                                        required: t("interviewInviteModal.field1.requiredPromptText"),
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <FormControl isInvalid={!!error?.message} id="workPeriodStart" mb={4}>
                                            <Textarea
                                                {...field}
                                                placeholder={t("interviewInviteModal.field1.placeholder")}
                                                isInvalid={!!errors.invitationText}
                                                bg="white"
                                                fontSize={"xs"}
                                            />
                                            <FormErrorMessage fontSize={"xs"} mb={4} ml={2}>
                                                {error?.message}
                                            </FormErrorMessage>
                                        </FormControl>
                                    )}
                                />

                                <Controller
                                    name="interviewDate"
                                    control={control}
                                    rules={{
                                        required: t("interviewInviteModal.field2.requiredPromptText"),
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <FormControl isInvalid={!!error?.message}>
                                            <DatePicker
                                                {...field}
                                                dateFormat="dd/MM/yyyy"
                                                selected={field.value}
                                                placeholderText={t("interviewInviteModal.field2.placeholder")}
                                                autoComplete="off"
                                                customInput={<CustomDatePickerField />}
                                                showYearDropdown
                                                scrollableYearDropdown
                                            />
                                            <FormErrorMessage fontSize={"xs"} mt={4} ml={2}>
                                                {error?.message}
                                            </FormErrorMessage>
                                        </FormControl>
                                    )}
                                />
                                <Flex alignItems={"center"} justifyContent="center">
                                    <Button
                                        colorScheme="blackAlpha"
                                        bg="blackAlpha.900"
                                        color="white"
                                        px={12}
                                        py={6}
                                        mt={10}
                                        borderRadius="lg"
                                        type="submit"
                                        isLoading={isSubmitLoading}
                                    >
                                        Send
                                    </Button>
                                </Flex>
                            </form>
                        </SimpleGrid>
                    </ModalBody>

                    <ModalFooter alignItems={"center"} justifyContent="center"></ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default InterviewScheduleModal;
