import React from "react";

const LocationIcon = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.99998 9.8C9.32546 9.8 10.4 8.72548 10.4 7.4C10.4 6.07452 9.32546 5 7.99998 5C6.67449 5 5.59998 6.07452 5.59998 7.4C5.59998 8.72548 6.67449 9.8 7.99998 9.8Z"
                stroke="#4D4D4D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.99998 1C6.30259 1 4.67473 1.67428 3.47449 2.87452C2.27426 4.07475 1.59998 5.70261 1.59998 7.4C1.59998 8.9136 1.92158 9.904 2.79998 11L7.99998 17L13.2 11C14.0784 9.904 14.4 8.9136 14.4 7.4C14.4 5.70261 13.7257 4.07475 12.5255 2.87452C11.3252 1.67428 9.69736 1 7.99998 1V1Z"
                stroke="#4D4D4D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default LocationIcon;
