import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation("footer");
    return (
        <Flex bg="gray.200" py={3} w="full" mt={10}>
            <Text fontSize={"md"} color="gray.700" w="full" fontWeight={"medium"} textAlign="center">
                {t("copyrightText")}
            </Text>
        </Flex>
    );
};

export default Footer;
