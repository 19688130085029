import { useLocalStorage } from "react-use";
import {
    AUTH_TOKEN_LOCAL_STORAGE_KEY,
    CONSULTANT_ID_LOCAL_STORAGE_KEY,
    SUBSCRIBER_ID_LOCAL_STORAGE_KEY,
    SUBSCRIPTION_ID_LOCAL_STORAGE_KEY,
    USER_RECORD_LOCAL_STORAGE_KEY,
    USER_TYPE_LOCAL_STORAGE_KEY,
} from "../constants/Auth-constants";

// This return a single value only
export const useLoggedInUserType = () => {
    const [loggedInUserType] = useLocalStorage(USER_TYPE_LOCAL_STORAGE_KEY);
    return loggedInUserType || null;
};

export const useGetSubscriberId = () => {
    const [subscriberIdLocalState] = useLocalStorage(SUBSCRIBER_ID_LOCAL_STORAGE_KEY);
    return subscriberIdLocalState ? subscriberIdLocalState : null;
};

export const useGetSubscriptionId = () => {
    const [subscriptionIdLocalState] = useLocalStorage(SUBSCRIPTION_ID_LOCAL_STORAGE_KEY);
    return typeof subscriptionIdLocalState === "number" ? subscriptionIdLocalState : null;
};
export const useGetConsultantId = () => {
    const [subscriberIdLocalState] = useLocalStorage(SUBSCRIBER_ID_LOCAL_STORAGE_KEY);
    return subscriberIdLocalState ? subscriberIdLocalState : null;
};

// This return the entire state
export const useLocalSttLoggedInUserTypeHook = () => {
    return useLocalStorage<null | string>(USER_TYPE_LOCAL_STORAGE_KEY, null);
};

export const useLocalStSubscriberIdHook = () => {
    return useLocalStorage<null | string>(SUBSCRIBER_ID_LOCAL_STORAGE_KEY, null);
};

export const useLocalStSubscriptionIdHook = () => {
    return useLocalStorage<null | string>(SUBSCRIPTION_ID_LOCAL_STORAGE_KEY, null);
};

export const useLocalStConsultantIdHook = () => {
    return useLocalStorage<null | string>(CONSULTANT_ID_LOCAL_STORAGE_KEY, null);
};

// DEMO setup help code

export const useLocalStUserRecord = () => {
    return useLocalStorage<any>(USER_RECORD_LOCAL_STORAGE_KEY, null);
};
export const useLocalSAuthToken = () => {
    return useLocalStorage<any>(AUTH_TOKEN_LOCAL_STORAGE_KEY, null);
};
// END of DEMO setup help code =======
