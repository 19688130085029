// TODO @hari-anitha translation is not used in this component
import { Avatar, Flex, IconButton, Progress, Text, WrapItem } from "@chakra-ui/react";
import React from "react";
import { ConsultantCardPropsInf } from "../../../@types/consultant.types";
import ExperienceIcon from "../../../assets/ReactSvgIcons/ExperienceIcon-component";
import LocationIcon from "../../../assets/ReactSvgIcons/LocationIcon-component";
import WishlistIcon from "../../../assets/ReactSvgIcons/WishlistIcon-component";
import { InfToggleWatchListingFn } from "../../../pages/client-pages/individual-job-page/CommonTabArea";
import SvgIconWrapper from "../../global/SvgIconWrapper/SvgIconWrapper.component";

export interface ConsultantCardExtPropsInf extends ConsultantCardPropsInf {
    isSelected?: boolean;
    jobDescriptionId: number | string;
    handleConsultantCardClick: (
        // eslint-disable-next-line no-unused-vars
        _consultantId: number,
    ) => void;
    // eslint-disable-next-line no-unused-vars
    setWatchListStateInParent?: (currentWishListState: boolean) => void;
    // eslint-disable-next-line no-unused-vars
    toggleWatchListing?: (args: InfToggleWatchListingFn) => void | null;
}

const ConsultantCard = (props: ConsultantCardExtPropsInf) => {
    const {
        consultantId,
        nickName,
        avatarUrl,
        qualification,
        jobPosition,
        location,
        expInYrs,
        activeRemainingWrkInMonths,
        wishlisted,
        isSelected,
        handleConsultantCardClick,
        toggleWatchListing,
        setWatchListStateInParent,
    } = props;

    const [isWatchListed, setWatchListedState] = React.useState(wishlisted);
    const [isWishListingProgressing, setWatchListingLoading] = React.useState(false);

    React.useEffect(() => {
        if (isSelected && typeof setWatchListStateInParent === "function") {
            setWatchListStateInParent(wishlisted);
            setWatchListedState(wishlisted);
        }
    }, [isSelected, wishlisted]);

    React.useEffect(() => {
        setWatchListedState(wishlisted);
    }, [wishlisted]);

    return (
        <Flex
            flexDirection={"column"}
            onClick={() => {
                handleConsultantCardClick(consultantId);
            }}
            borderWidth={isSelected ? 2 : 1}
            borderRadius={12}
            py={4}
            px={5}
            borderColor={isSelected ? "blue.700" : "blue.100"}
            _hover={{ borderColor: "brand.600", bg: "gray.50", cursor: "pointer" }}
            _active={{ bg: "brand.50" }}
            h="fit-content"
        >
            <Flex flexDirection={"row"} alignItems="center">
                <WrapItem>
                    <Avatar name={nickName} src={avatarUrl} />
                </WrapItem>
                <Flex flexDirection={"column"} ml={4}>
                    <Text
                        display={"block"}
                        fontWeight="semibold"
                        fontSize={"sm"}
                        lineHeight="taller"
                        noOfLines={1}
                        textAlign="left"
                    >
                        {nickName}
                    </Text>
                    <Text
                        display={"block"}
                        fontWeight="medium"
                        fontSize={10}
                        lineHeight="short"
                        mt={1}
                        noOfLines={1}
                        textAlign="left"
                    >
                        {qualification}
                    </Text>
                </Flex>
            </Flex>
            <Text fontWeight="semibold" fontSize={"sm"} lineHeight="taller" mt={3} noOfLines={1} textAlign="left">
                {jobPosition}
            </Text>
            <Flex justifyContent={"flex-start"} alignItems="center">
                <SvgIconWrapper svgComp={<LocationIcon />} w={4} h={8} />
                <Text fontWeight={"normal"} fontSize={10} ml={4} color={"gray.600"} noOfLines={1}>
                    {location}
                </Text>
            </Flex>
            <Flex justifyContent={"flex-start"} alignItems="center">
                <SvgIconWrapper svgComp={<ExperienceIcon />} w={4} h={8} />
                <Text fontWeight={"normal"} fontSize={10} ml={4} color={"gray.600"} noOfLines={1}>
                    {expInYrs} years exp
                </Text>
            </Flex>
            <Flex flexDirection={"row"} justifyContent="space-between" mt={2}>
                <Flex flexDirection={"column"} w="60%">
                    <Progress
                        max={12}
                        value={
                            parseInt(activeRemainingWrkInMonths.toString()) > 12
                                ? 0
                                : 12 - parseInt(activeRemainingWrkInMonths.toString())
                        }
                        w="full"
                        borderRadius={2}
                        h={2}
                    />
                    <Text
                        fontSize={"10"}
                        lineHeight={5}
                        fontWeight="semibold"
                        color={"gray.600"}
                        mt={2}
                        noOfLines={1}
                        textAlign="left"
                    >
                        {parseInt(activeRemainingWrkInMonths.toString()) > 12
                            ? "More than 1 year"
                            : activeRemainingWrkInMonths}{" "}
                        Remaining
                    </Text>
                </Flex>
                <IconButton
                    ml={2}
                    variant="outline"
                    boxShadow="md"
                    icon={
                        <SvgIconWrapper
                            w="fit-contents"
                            svgComp={<WishlistIcon iconColor={isWatchListed ? "#279BEF" : null} />}
                        />
                    }
                    aria-label="Wishlist consultant"
                    isLoading={isWishListingProgressing}
                    onClick={(e) => {
                        e.stopPropagation();
                        setWatchListingLoading(true);
                        toggleWatchListing &&
                            toggleWatchListing({
                                consultantId: consultantId,
                                isWatchListedCurrently: isWatchListed,
                                onSuccess: () => {
                                    setWatchListedState((prevState) => !prevState);
                                    setWatchListingLoading(false);
                                },
                                onFailure: () => {
                                    setWatchListingLoading(false);
                                },
                            });
                    }}
                />
            </Flex>
        </Flex>
    );
};

export default ConsultantCard;
