import React from "react";

const MailIcon = () => {
    return (<svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.9 12.076V22C3.9 23.0874 4.33196 24.1302 5.10086 
        24.8991C5.86976 25.668 6.91261 26.1 8 26.1H24C25.0874 26.1 
        26.1302 25.668 26.8991 24.8991C27.668 24.1302 28.1 23.0874 
        28.1 22V10C28.1 8.91261 27.668 7.86976 26.8991 7.10086C26.1302 
        6.33196 25.0874 5.9 24 5.9H8C6.91261 5.9 5.86976 6.33196 5.10086 
        7.10086C4.33196 7.86976 3.9 8.91261 3.9 10V12.076ZM8 8.1H24C24.5039 
        8.1 24.9872 8.30018 25.3435 8.6565C25.6998 9.01282 25.9 9.49609 25.9 
        10V11.4203L16 16.7504L6.1 11.4203V10C6.1 9.49609 6.30018 9.01282 6.6565 
        8.6565C7.01282 8.30018 7.49609 8.1 8 8.1ZM6.1 22V13.9194L15.4786 
        18.9681C15.6389 19.0543 15.818 19.0995 16 19.0995C16.182 19.0995 
        16.3611 19.0543 16.5214 18.9681L25.9 13.9194V22C25.9 22.5039 25.6998 
        22.9872 25.3435 23.3435C24.9872 23.6998 24.5039 23.9 24 23.9H8C7.49609 
        23.9 7.01282 23.6998 6.6565 23.3435C6.30018 22.9872 6.1 22.5039 6.1 22Z" fill="#8BB9E2" stroke="#8BB9E2" strokeWidth="0.2"/>
    </svg>);
}

export default MailIcon;