import { useToast } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ConsultantProfileFormInf, ConsultantProfilePayload } from "../../../@types/consultantProfileForm.types";
import HomeIcon from "../../../assets/ReactSvgIcons/HomeIcon-component";
import PageHeader from "../../../assets/ReactSvgIcons/PageHeader-component";
import { formDataModifier } from "../../../components/consultantViewPage-components/ConsultantProfileForm/ConsultantProfile.helper";
import ConsultantProfileForm from "../../../components/consultantViewPage-components/ConsultantProfileForm/ConsultantProfileForm";
import AppLayout from "../../../components/global/AppLayout/AppLayout-component";
import Breadcrumbs from "../../../components/global/Breadcrumbs/Breadcrumbs-component";
import Footer from "../../../components/global/Footer/Footer-component";
import NavBar from "../../../components/global/NavBar/NavBar-component";
import SvgIconWrapper from "../../../components/global/SvgIconWrapper/SvgIconWrapper.component";
import { CONSULTANT_EDIT_PROFILE_PAGE, CONSULTANT_JOB_SEARCH_PAGE } from "../../../constants/WebAppRoute-constants";
import { useEditConsultantProfile, useGetConsultantProfileById } from "../../../hooks/consultantProfile.hook";
import { useGetConsultantId, useGetSubscriptionId, useGetSubscriberId } from "../../../lib/authFns";

const EditConsultantProfile = () => {
    const { t } = useTranslation("consultantProfileForm");

    const _consultantId = useGetConsultantId();
    const _subscriptionId = useGetSubscriptionId();
    const _subscriberId = useGetSubscriberId();

    const userInfo = {
        consultantId: _consultantId,
        subscriptionId: _subscriptionId,
        userId: _subscriberId ? _subscriberId.toString() : null,
    };

    const { mutateAsync, isLoading: isEditLoading } = useEditConsultantProfile(_consultantId);
    const { data, isLoading } = useGetConsultantProfileById(_consultantId);

    const toast = useToast();
    const navigate = useNavigate();

    const handleFormSubmit = async (formData: ConsultantProfileFormInf) => {
        // TODO: @umesh Make this payload proper as the API request body
        const payload: ConsultantProfilePayload = {
            ...formDataModifier(formData),
            ...userInfo,
            consultantAvatar: "",
        };

        try {
            await mutateAsync(payload, {
                onSuccess: () => {
                    toast({
                        title: t("editConsultantProfile.success"),
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                    navigate(CONSULTANT_JOB_SEARCH_PAGE.url);
                },
                onError: () => {
                    toast({
                        title: t("editConsultantProfile.failure"),
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                },
            });
        } catch (error) {
            toast({
                title: t("editConsultantProfile.failure"),
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const breadCrumbsData = [
        {
            _jsxContent: <SvgIconWrapper svgComp={<HomeIcon />} alignItems="center" w={3} h="full" mt={0} />,
            pageId: CONSULTANT_JOB_SEARCH_PAGE.pageId,
            url: CONSULTANT_JOB_SEARCH_PAGE.url,
        },
        {
            _jsxContent: CONSULTANT_EDIT_PROFILE_PAGE.pageName,
            pageId: CONSULTANT_EDIT_PROFILE_PAGE.pageId,
            url: CONSULTANT_EDIT_PROFILE_PAGE.url,
            isCurrentPage: true,
        },
    ];
    return (
        <AppLayout>
            <NavBar />
            <PageHeader
                pageText={t("editConsultantProfilePageTitle")}
                previousPagePath={CONSULTANT_JOB_SEARCH_PAGE.reactRouteWithPathParams}
            />
            <Breadcrumbs data={breadCrumbsData} />
            <ConsultantProfileForm
                onFormSubmit={(formData: ConsultantProfileFormInf) => handleFormSubmit(formData)}
                isLoading={isLoading || isEditLoading}
                data={data}
            />
            <Footer />
        </AppLayout>
    );
};

export default EditConsultantProfile;
