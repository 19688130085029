import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

interface BreadcrumbIndDataInf {
    _jsxContent: ReactNode | string;
    pageId: string;
    isCurrentPage?: boolean;
    url: string;
}
interface BreadcrumbItemPropInf {
    data: BreadcrumbIndDataInf[];
}

const Breadcrumbs = (props: BreadcrumbItemPropInf) => {
    const { data } = props;
    /**
     * example of data = [ 
        {
    ._jsxContent: <SvgIconWrapper svgComp={<HomeIcon />} alignItems="center" w={3} h="full" mt={0} />,
        pageId: "home",
        url: "/",
        },
    ]
     */
    return (
        <Breadcrumb
            /**
             * TODO: Need to improve the styling
             */
            display={{ base: "none", md: "flex" }}
            justifyContent={"flex-start"}
            borderRadius={"md"}
            mx={"4vw"}
            py={2}
            px={4}
            mt={6}
            bg={"blackAlpha.100"}
            w="max-content"
        >
            {data.map((_data: BreadcrumbIndDataInf) => {
                const { isCurrentPage = false } = _data;
                return (
                    <BreadcrumbItem key={_data.pageId} h={6} alignItems="center" isCurrentPage={isCurrentPage}>
                        <BreadcrumbLink
                            to={_data.url}
                            as={Link}
                            alignItems={"center"}
                            fontSize="sm"
                            fontWeight={isCurrentPage ? "bold" : "medium"}
                        >
                            {_data._jsxContent}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                );
            })}
        </Breadcrumb>
    );
};

export default Breadcrumbs;
