import React from "react";
import CommonTabArea from "./CommonTabArea";
import { MatchingProfilesTabCompPropsInf } from "../../../@types/consultantTable.types";
import { useJobAppList } from "../../../hooks/jobDescriptions.hook";

const ApplicationsTab = (props: MatchingProfilesTabCompPropsInf) => {
    const { jobDescriptionId, isFilterShown, activeTableViewType, defaultTabKey } = props;
    const _jobDescriptionId = jobDescriptionId;
    const jobApplnQuery = useJobAppList({
        jobDescriptionId: _jobDescriptionId,
    });
    return (
        <CommonTabArea
            tableData={jobApplnQuery.data || []}
            isTableDataLoading={jobApplnQuery.isLoading}
            jobDescriptionId={jobDescriptionId}
            activeTableViewType={activeTableViewType}
            isFilterShown={isFilterShown}
            defaultTabKey={defaultTabKey}
        />
    );
};

export default ApplicationsTab;
