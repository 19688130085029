import {
    Avatar,
    Divider,
    Flex,
    IconButton,
    List,
    ListIcon,
    ListItem,
    Tag,
    Text,
    VStack,
    WrapItem,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { JobDataInf } from "../../../@types/job.types";
import ExperienceIcon from "../../../assets/ReactSvgIcons/ExperienceIcon-component";
import LocationIcon from "../../../assets/ReactSvgIcons/LocationIcon-component";
import RemainingWorkIcon from "../../../assets/ReactSvgIcons/RemainingWorkIcon-component";
import UnorderedListIcon from "../../../assets/ReactSvgIcons/UnorderedListIcon-component";
import WishlistIcon from "../../../assets/ReactSvgIcons/WishlistIcon-component";
import { dateDistanceFromToday } from "../../../lib/dateTimeFns";
import SvgIconWrapper from "../../global/SvgIconWrapper/SvgIconWrapper.component";
interface ConsultantDetailsCompPropsInf extends JobDataInf {
    children?: ReactNode;
}
const JobDetailsPane = (props: ConsultantDetailsCompPropsInf) => {
    const {
        jobProjectOverview,
        jobPosition,
        jobType,
        jobLocation,
        jobExpectedExperienceYrs,
        jobStartFrom,
        jobEndAt,
        jobMinSalary,
        jobMaxSalary,
        jobExpectedSkills,
        jobBenefits,
        children,
    } = props;

    const companyName = props?.expand?.clientDetails?.companyName;

    // TODO @hari-anitha Remove the whishlist constant
    const wishlisted = false;
    const monthlyMaxSalaryAmount = jobMaxSalary ? jobMaxSalary / 1000 : "-";
    const monthlyMinSalaryAmoiunt = jobMinSalary ? jobMinSalary / 1000 : "-";
    const moreDetailsSecData = {
        jobDescription: jobProjectOverview,
        contractPeriod: [`${jobStartFrom} - ${jobEndAt}`],
        salary: `${`₹`} ${monthlyMinSalaryAmoiunt}k - \
    ${`₹`} ${monthlyMaxSalaryAmount}k `,
        expectedSkills: [jobExpectedSkills],
        benefits: [jobBenefits],
    };

    const { t } = useTranslation("consultantViewJobListing");

    const textStyles = {
        fontSize: "xs",
        lineHeight: 6,
        fontWeight: "medium",
        color: "gray.600",
    };
    const headingStyles = {
        fontSize: "base",
        lineHeight: "tight",
        fontFamily: "Raleway",
        fontWeight: "bold",
        color: "gray.900",
        pt: 4,
    };

    const BulletIcon = () => <SvgIconWrapper w={2} pt={2} svgComp={<UnorderedListIcon />} display="inline-block" />;

    const renderList = (dataArray: Array<string>) => {
        return (
            <List spacing={2} mt={0}>
                {dataArray.map((str) => {
                    return (
                        <ListItem key={str} display={"flex"} alignItems="start" justifyContent={"start"}>
                            <ListIcon
                                as={BulletIcon}
                                color="green.500"
                                mr={0}
                                p={0}
                                w="fit-content"
                                display={"inline-block"}
                            />
                            <Text {...textStyles} display={"inline-block"} ml={2}>
                                {str}
                            </Text>
                        </ListItem>
                    );
                })}
            </List>
        );
    };

    return (
        <Flex flexDirection={"column"} w="full">
            <Flex flexDirection={"column"} w="full" bg="gray.50" borderTopRadius="lg">
                <Flex
                    w="full"
                    justifyContent={"space-between"}
                    px={8}
                    py={6}
                    mt={1}
                    bg={"white"}
                    boxShadow="md"
                    borderRadius={"lg"}
                >
                    <Flex py={6}>
                        <WrapItem justifyContent={"center"} alignItems="center">
                            <Avatar
                                name="Kola Tioluwani"
                                src={`https://vectorwiki.com/images/6rLvU__spotify.svg`}
                                w={20}
                                h={20}
                                bg="gray.800"
                            />
                        </WrapItem>
                        <Flex flexDirection={"column"} ml={8}>
                            <Text
                                display={"block"}
                                fontWeight="regular"
                                fontSize={"xs"}
                                lineHeight="base"
                                noOfLines={1}
                                color={"gray.500"}
                            >
                                {companyName}
                            </Text>
                            <Text
                                display={"block"}
                                fontWeight="semibold"
                                fontSize={"sm"}
                                lineHeight="taller"
                                noOfLines={1}
                            >
                                {jobPosition}T
                            </Text>
                            <Text
                                display={"block"}
                                fontWeight="medium"
                                fontSize={"xs"}
                                lineHeight="short"
                                mt={2}
                                mr={4}
                                noOfLines={2}
                            >
                                {jobProjectOverview}
                            </Text>

                            <Tag
                                variant="solid"
                                bg="blue.100"
                                color="blue.700"
                                alignItems={"center"}
                                justifyContent="center"
                                py={1}
                                px={4}
                                w={"fit-content"}
                                borderRadius="2xl"
                                mt={4}
                                fontSize="xs"
                            >
                                {jobType}
                            </Tag>
                        </Flex>
                    </Flex>
                    <Flex flexDirection={"column"} py={6}>
                        <Flex justifyContent={"flex-start"} alignItems="center">
                            <SvgIconWrapper svgComp={<RemainingWorkIcon />} w={4} h={8} />
                            <Text fontWeight={"normal"} fontSize={10} ml={4} color={"gray.600"} noOfLines={1}>
                                {dateDistanceFromToday(jobStartFrom)} Remaining
                            </Text>
                        </Flex>
                        <Flex justifyContent={"flex-start"} alignItems="center">
                            <SvgIconWrapper svgComp={<LocationIcon />} w={4} h={8} />
                            <Text fontWeight={"normal"} fontSize={10} ml={4} color={"gray.600"} noOfLines={1}>
                                {jobLocation}
                            </Text>
                        </Flex>
                        <Flex justifyContent={"flex-start"} alignItems="center">
                            <SvgIconWrapper svgComp={<ExperienceIcon />} w={4} h={8} />
                            <Text fontWeight={"normal"} fontSize={10} ml={4} color={"gray.600"} noOfLines={1}>
                                {jobExpectedExperienceYrs} years exp
                            </Text>
                        </Flex>
                    </Flex>
                    <IconButton
                        ml={2}
                        variant="outline"
                        bg={wishlisted ? "blue.800" : "white"}
                        boxShadow="md"
                        icon={<SvgIconWrapper w="fit-contents" svgComp={<WishlistIcon />} />}
                        aria-label="Wishlist consultant"
                    />
                </Flex>
                <Divider h={6} w="full" bg="white" />
                <VStack align="stretch" my={6} px={8} py={6}>
                    <Text {...headingStyles} textAlign="left">
                        {t("jobDetailsPane.jobDesc")}
                    </Text>
                    <Text {...textStyles} textAlign="left">
                        {moreDetailsSecData.jobDescription}
                    </Text>
                    <Text {...headingStyles} textAlign="left">
                        {t("jobDetailsPane.contractPeriod")}
                    </Text>
                    {renderList(moreDetailsSecData.contractPeriod)}
                    <Text {...headingStyles} textAlign="left">
                        {t("jobDetailsPane.salary")}
                    </Text>
                    <Text {...textStyles} textAlign="left">
                        {moreDetailsSecData.salary}
                    </Text>
                    <Text {...headingStyles} textAlign="left">
                        {t("jobDetailsPane.expectedSkills")}
                    </Text>
                    {renderList(moreDetailsSecData.expectedSkills)}
                    <Text {...headingStyles}>{t("jobDetailsPane.benefits")}</Text>
                    {renderList(moreDetailsSecData.benefits)}
                </VStack>
            </Flex>
            {children}
        </Flex>
    );
};

export default JobDetailsPane;
