/**
 * Fn to get all searchParams from URL
 */
export const getAllUrlSearchParams = (searchParamsObj: URLSearchParams, searchParamsKeys: string[]) => {
    const searchParamObj: { [key: string]: string | null } = {};
    searchParamsKeys.forEach((_key: string) => {
        searchParamObj[_key] = searchParamsObj.get(_key);
    });
    return searchParamObj;
};
