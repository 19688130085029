import React, { useMemo } from "react";
import { INTERVIEW_STATUS_COLUMNS } from "./interviewStatusTableColumns";
import { interviewStatusTableInf } from "../../../@types/interviewStatusTable.types";
import ReactTable from "../../global/Table/Table-component";

const InterviewStatusTable = ({ data }: interviewStatusTableInf) => {
    //  TODO: data to be replaced by that from API

    const tableData = useMemo(() => data, [data]);
    const columns = useMemo(() => INTERVIEW_STATUS_COLUMNS, []);

    //  TODO: header styles to be modified
    return <ReactTable data={tableData} columns={columns} />;
};

export default InterviewStatusTable;
