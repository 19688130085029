// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * This component will help to scroll to top during page change
 * Doc: https://v5.reactrouter.com/web/guides/scroll-restoration
 */
const ScrollToTop = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    return null;
};

export default ScrollToTop;
