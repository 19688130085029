import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
    getUIParamsBasedOnJobApplnStatus,
    handleJobApplReply,
    handleJobInterviewReq,
    handleWatchListToggle,
    useGetActiveTab,
    useGetSelectedConsultantId,
} from "./individualJobDescriptionPageHelpers";
import { MatchingProfilesTabCompPropsInf } from "../../../@types/consultantTable.types";
import ConsultantTable from "../../../components/consultantViewPage-components/ConsultantTable/ConsultantTable-component";
import ConsultantTableCardView from "../../../components/consultantViewPage-components/ConsultantTable/ConsultantTableCardView-component";
import ConsultantTableWithDetailedView from "../../../components/consultantViewPage-components/ConsultantTable/ConsultantTableWithDetailedView-component";
import InterviewScheduleModal, {
    InterviewFormDataInf,
} from "../../../components/consultantViewPage-components/InterviewScheduleModal/InterviewScheduleModal-component";
import { jobInvitationStatuses, tableViewTypes } from "../../../constants/Enum-constants";
import { useRespondJobAppl } from "../../../hooks/client-hooks/jobApplMng.hooks";
import { useCheckJobApplnStatus } from "../../../hooks/consultant-hooks/applyJob.hook";
import {
    useCreateJobInvitationFromClient,
    useGetJobInviteData,
    useUpdateJobInvitationFromClient,
} from "../../../hooks/consultant-hooks/invitations.hook";
import { useGetConsultantById } from "../../../hooks/consultantsCommon.hooks";
import { useJobWatchListToggle } from "../../../hooks/jobDescriptions.hook";
import { useGetSubscriberId } from "../../../lib/authFns";
import { consultantDataSchemeTransport, modifyConsultantArrayData } from "../../../lib/dataModifiers";
import CircleLoading from "../../../components/global/CircleLoading/CircleLoading-component";

export interface CommonTabAreaInf extends MatchingProfilesTabCompPropsInf {
    tableData: any;
    isTableDataLoading: boolean;
}
export interface InfToggleWatchListingFn {
    consultantId: number;
    isWatchListedCurrently: boolean;
    onSuccess: () => void;
    onFailure: () => void;
}

const CommonTabArea = (props: CommonTabAreaInf) => {
    const { jobDescriptionId, activeTableViewType, tableData, isTableDataLoading } = props;

    const [sendInviteModalIsOpen, setSendInviteModalState] = useState(false);

    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const subscriberId = useGetSubscriberId(); // subs.id of the client
    const activeTabName = useGetActiveTab();
    const selectedCstId = useGetSelectedConsultantId();
    const selectedConsultantDataFetchQuery = useGetConsultantById(selectedCstId || "");

    // Queries
    const jobHiringStatusChkQry = useCheckJobApplnStatus(jobDescriptionId ? jobDescriptionId : null, selectedCstId);
    const currentJobInviteDetailsQry = useGetJobInviteData(jobDescriptionId ? jobDescriptionId : null, selectedCstId);

    // Mutations
    const createInviteMtn = useCreateJobInvitationFromClient();
    const updateInvitationMtn = useUpdateJobInvitationFromClient();
    const jobApplnReplyMtnQuery = useRespondJobAppl();
    const watchListToggleMtn = useJobWatchListToggle();

    const handleConsultantSelectionClick = (_consultantId: any) => {
        navigate(
            `/client-view/job/${jobDescriptionId}?active-tab=${activeTabName}&table-view=card&consultant-id=${_consultantId}`,
        );
    };

    // IN DEMO db jobInterviewId is record of jobApplication collection rows.
    const jobInterviewId = currentJobInviteDetailsQry?.data?.id || null;
    const consultantId = selectedCstId;
    const userId = subscriberId ? subscriberId.toString() : null;
    const _jobDescriptionId = jobDescriptionId ? jobDescriptionId : null;
    const jobApplicationId = jobHiringStatusChkQry?.data ? jobHiringStatusChkQry?.data?.id : null;

    const handleCreateInvite = async (formData: InterviewFormDataInf) => {
        const _invStatusDuringCreation = jobInvitationStatuses.NEW;
        await handleJobInterviewReq({
            newInvitationState: _invStatusDuringCreation,
            jobDescriptionId: _jobDescriptionId,
            consultantId,
            userId,
            jobInterviewId,
            createInviteMtn,
            updateInvitationMtn,
            _reqBody: { ...formData, jobInvitationStatus: _invStatusDuringCreation, jobInterviewStatus: null },
            queryClient,
        });
        setSendInviteModalState(false);
    };

    const handleUpdateInvite = (newInvitationStatus: string, newInterviewStatus: string) => {
        const { data: currentInterviewData = {} } = currentJobInviteDetailsQry.data;
        handleJobInterviewReq({
            newInvitationState: newInvitationStatus,
            jobDescriptionId: _jobDescriptionId,
            consultantId,
            userId,
            jobInterviewId,
            createInviteMtn,
            updateInvitationMtn,
            queryClient,
            _reqBody: {
                ...currentInterviewData,
                jobInvitationStatus: newInvitationStatus,
                jobInterviewStatus: newInterviewStatus,
            },
        });
    };

    const handleJobApplicationResFromClient = (newJobApplicationStatus: string) => {
        handleJobApplReply({
            jobDescriptionId: _jobDescriptionId,
            jobApplicationId,
            consultantId,
            newJobApplicationStatus,
            clientSubscriberId: subscriberId,
            jobApplnReplyMtnHook: jobApplnReplyMtnQuery,
            queryClient,
        });
    };

    const invalidateJobApplicationApiQuery = async () => {
        return await queryClient.invalidateQueries([
            "check_job_appln_status",
            "jobDescId",
            jobDescriptionId,
            "consultantId",
            consultantId,
        ]);
    };
    /**
     * Handle sending job interview invite with details
     */
    const sendInterviewInvite = async (props: {
        jobInvitationMessage: string;
        jobInterviewSchedule: string | Date;
        jobInterviewLink: string;
        jobInterviewRemarks: string;
    }) => {
        /**If jobApplicationId is not existing, that means application process (job Application DB doc not created ) -
         * and the client is initiating the job app. process which begins with the sending of interview invite -
         * to the consultant directly. So we need to use the createJobApplication hook for this process.
         */
        if (!jobApplicationId) {
            const args = {
                ...props,
                jobDescription: jobDescriptionId,
                consultant: consultantId,
                jobInvitationStatus: jobInvitationStatuses.NEW,
            };
            try {
                await createInviteMtn.mutateAsync(args);
                await invalidateJobApplicationApiQuery();
                setSendInviteModalState(false);
            } catch (error) {
                console.error("ERROR: handleJobInterviewStatusUpdate try failed, failed to update interview details");
            }
        } else {
            const args = {
                reqBody: { ...props, jobInvitationStatus: jobInvitationStatuses.NEW },
                jobApplnId: jobApplicationId,
            };
            try {
                await jobApplnReplyMtnQuery.mutateAsync(args);
                await invalidateJobApplicationApiQuery();
                setSendInviteModalState(false);
            } catch (error) {
                console.error("ERROR: handleJobInterviewStatusUpdate try failed, failed to update interview details");
            }
        }
    };

    const sendInterviewResult = async (props: { jobInterviewStatus }) => {
        if (props?.jobInterviewStatus) {
            const args = {
                /**In this case jobInterviewStatus and jobInvitationStatus becomes the same */
                reqBody: {
                    ...props,
                    jobInvitationStatus: props?.jobInterviewStatus,
                    jobInterviewStatus: props?.jobInterviewStatus,
                },
                jobApplnId: jobApplicationId,
            };
            try {
                await jobApplnReplyMtnQuery.mutateAsync(args);
                await invalidateJobApplicationApiQuery();
                setSendInviteModalState(false);
            } catch (error) {
                console.error("ERROR: handleJobInterviewStatusUpdate try failed, failed to update interview details");
            }
        } else {
            console.error("No interview status passed to send the interview response");
        }
    };

    const toggleWatchListing = async (args: InfToggleWatchListingFn) => {
        const { consultantId, isWatchListedCurrently, onSuccess, onFailure } = args;
        const onSuccessFnMod = () => {
            if (activeTabName === "watch-list") {
                navigate(
                    `/client-view/job/${jobDescriptionId}?active-tab=${activeTabName}&table-view=${activeTableViewType}`,
                    { replace: true },
                );
            }
            onSuccess();
        };
        await handleWatchListToggle({
            jobDescriptionId: _jobDescriptionId,
            consultantId,
            clientSubscriberId: subscriberId,
            isWatchListedCurrently,
            watchListToggleMtn,
            queryClient,
            onSuccesAction: onSuccessFnMod,
            onFailureAction: onFailure,
        });
    };

    const isQryReqWaiting =
        createInviteMtn.isLoading ||
        updateInvitationMtn.isLoading ||
        jobApplnReplyMtnQuery.isLoading ||
        jobHiringStatusChkQry.isLoading ||
        currentJobInviteDetailsQry.isLoading;

    const { data: jobHiringStatusData } = jobHiringStatusChkQry;

    const { message, isReqWaiting, submitBtns } = getUIParamsBasedOnJobApplnStatus({
        jobApplicationStatus: jobHiringStatusChkQry.isSuccess ? jobHiringStatusData?.jobApplicationStatus : "ERROR",
        jobInvitationStatus: jobHiringStatusChkQry.isSuccess ? jobHiringStatusData?.jobInvitationStatus : "ERROR",
        jobInterviewStatus: jobHiringStatusChkQry.isSuccess ? jobHiringStatusData?.jobInterviewStatus : "ERROR",
        isReqWaiting: isQryReqWaiting,
        onSendInviteBtnClick: () => {
            // Invitation btn click opens only the modal and all other logic is made in the modal handling
            setSendInviteModalState(true);
        },
        updateInviteHandler: handleUpdateInvite,
        updateJobAppnStatusHandler: handleJobApplicationResFromClient,
        sendInterviewResult,
    });
    if (isTableDataLoading) {
        return (
            <Box mt={20}>
                <CircleLoading />
            </Box>
        );
    }
    if (selectedCstId) {
        return (
            <>
                <ConsultantTableWithDetailedView
                    data={tableData ? modifyConsultantArrayData(tableData) : []}
                    selectedCstId={selectedCstId}
                    jobDescriptionId={jobDescriptionId || 0}
                    selectedConsultantData={
                        !selectedConsultantDataFetchQuery.isLoading
                            ? consultantDataSchemeTransport(selectedConsultantDataFetchQuery.data)
                            : null
                    }
                    handleConsultantCardClick={handleConsultantSelectionClick}
                    isReqWaiting={isReqWaiting}
                    submitAreaTxt={message}
                    submitBtnsData={submitBtns}
                    toggleWatchListing={toggleWatchListing}
                />
                <InterviewScheduleModal
                    isOpen={sendInviteModalIsOpen}
                    onSubmit={sendInterviewInvite}
                    onClose={() => {
                        setSendInviteModalState(false);
                    }}
                    isSubmitLoading={createInviteMtn.isLoading || jobApplnReplyMtnQuery.isLoading}
                />
            </>
        );
    }
    return activeTableViewType === tableViewTypes.LIST ? (
        <Box>
            <ConsultantTable
                data={Array.isArray(tableData) ? modifyConsultantArrayData(tableData) : []}
                jobDescriptionId={jobDescriptionId || 0}
                handleConsultantCardClick={handleConsultantSelectionClick}
                activeTabName={activeTabName || "applications"}
            />
        </Box>
    ) : (
        <ConsultantTableCardView
            data={Array.isArray(tableData) ? modifyConsultantArrayData(tableData) : []}
            jobDescriptionId={jobDescriptionId || 0}
            handleConsultantCardClick={handleConsultantSelectionClick}
            toggleWatchListing={toggleWatchListing}
        />
    );
};

export default CommonTabArea;
