import { useMutation, useQuery } from "react-query";
import { ConsultantProfilePayload } from "./../@types/consultantProfileForm.types";
import {
    CONSULTANT_IND_DATA,
    CREATE_CONSULTANT_PROFILE,
    EDIT_CONSULTANT_PROFILE_DATA,
    POCKETBASE_URL,
} from "./../constants/ApiPaths-constants";

import { get, post, put } from "../adapters";

/* Contain all the query hooks for consultant profile
 * Includes the entire CRUD queries related to consultant profile
 */

export const useUpdateConsultantProfile = () => {
    return useMutation(
        (formData: ConsultantProfilePayload) =>
            post({
                url: CREATE_CONSULTANT_PROFILE,
                requestBody: JSON.parse(JSON.stringify(formData)),
            }),
        {
            retry: 1,
            retryDelay: 5000,
        },
    );
};

export const useEditConsultantProfile = (consultantId: number | null) => {
    return useMutation(
        (formData: ConsultantProfilePayload) =>
            put({
                url: EDIT_CONSULTANT_PROFILE_DATA(consultantId),
                requestBody: JSON.parse(JSON.stringify(formData)),
            }),
        {
            retry: 1,
            retryDelay: 5000,
        },
    );
};

export const useGetConsultantProfileById = (consultantId: number | null) => {
    return useQuery(
        `getConsultantProfile/${consultantId}`,
        async () => {
            const res = await get({
                url: `${POCKETBASE_URL}/api/collections/consultants/records/${consultantId}?expand=userDetails`,
            })
                .then((response: any) => {
                    return response.data;
                })
                .catch((err: any) => {
                    throw new Error(`ERROR:: Failed to get consultant profile data => ${JSON.stringify(err)}`);
                });
            return res;
        },
        {
            refetchOnWindowFocus: true,
            // enabled: !!Number(consultantId),
        },
    );
};

// TODO: @umesh add getAll consultant profile API here.
