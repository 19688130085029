import { Flex, FlexProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface AppLayoutPropInf extends FlexProps {
    children: ReactNode;
}

const AppLayout = (props: AppLayoutPropInf) => {
    const { children, ...otherProps } = props;
    return (
        <Flex direction={"column"} justifyContent={"space-between"} {...otherProps}>
            {children}
        </Flex>
    );
};

export default AppLayout;
