import { Button, Text, Badge, Box, Link } from "@chakra-ui/react";
import { format } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RRDLink } from "react-router-dom";
import { CLIENT_JOB_DETAILS_PAGE } from "../../../constants/WebAppRoute-constants";
import { jobInvitationStatuses } from "../../../constants/Enum-constants";

const headerStyles = {
    fontSize: "sm",
    fontFamily: "Raleway",
    fontWeight: "bold",
    alignItems: "center",
    justifyContent: "center",
    color: "gray.900",
};

const textStyles = {
    fontFamily: "Poppins",
    fontSize: "xs",
    lineHeight: 7,
    fontWeight: "normal",
    color: "gray.600",
};

const TranslationFunction = (translationVariable: string) => {
    const { t } = useTranslation("interviewStatusTable");
    return t(translationVariable);
};

export const INTERVIEW_STATUS_COLUMNS = [
    {
        Header: () => (
            <Text {...headerStyles} textAlign={"center"} textTransform={"none"}>
                {TranslationFunction("interviewStatusTableHeaders.nickName")}
            </Text>
        ),
        accessor: "nickName",
        Cell: (props: any) => {
            return (
                <Text {...textStyles} textAlign={"center"}>
                    {props.row.original.nickName}
                </Text>
            );
        },
    },
    {
        Header: () => (
            <Text {...headerStyles} textAlign={"center"} textTransform={"none"}>
                {TranslationFunction("interviewStatusTableHeaders.role")}
            </Text>
        ),
        accessor: "jobPosition",
        Cell: (props: any) => {
            return (
                <Text {...textStyles} textAlign={"center"}>
                    {props.row.original.jobPosition}
                </Text>
            );
        },
    },
    {
        Header: () => (
            <Text {...headerStyles} textAlign={"center"} textTransform={"none"}>
                {TranslationFunction("interviewStatusTableHeaders.interviewDate")}
            </Text>
        ),
        accessor: "interviewDate",
        // TODO: any to be replaced
        Cell: (props: any) => {
            return (
                <Text {...textStyles} textAlign={"center"}>
                    {props?.value ? format(new Date(props?.value), "yyyy-MM-dd hh:mm a") : "-"}
                </Text>
            );
        },
    },
    // TODO @hari-anitha : Check with Sakthi if Reschedule column needs to be here ?
    // {
    //     Header: () => (
    //         <Text {...headerStyles} textAlign={"center"} textTransform={"none"}>
    //             {TranslationFunction("interviewStatusTableHeaders.reschedule")}
    //         </Text>
    //     ),
    //     accessor: "reschedule",
    //     // TODO: any to be replaced
    //     Cell: (props: any) => {
    //         return (
    //             <Text {...textStyles} textAlign={"center"}>
    //                 <IconButton
    //                     bg="white"
    //                     outline="outline"
    //                     icon={<CalendarIcon />}
    //                     aria-label={"Button to Reschedule"}
    //                     isDisabled={!!props?.value}
    //                 />
    //             </Text>
    //         );
    //     },
    // },
    {
        Header: () => (
            <Text {...headerStyles} textAlign={"center"} textTransform={"none"}>
                Invitation Status
            </Text>
        ),
        accessor: "status",
        // TODO: any to be replaced
        Cell: (props: any) => {
            const badgeAddtnlStyle = {
                colorScheme: "gray",
                color: "gray.600",
            };
            // TODO @hari-anitha : Use i18n translation here
            let badgeTxt = "-";

            switch (props.value) {
                case jobInvitationStatuses.NEW:
                    badgeAddtnlStyle.colorScheme = "blue";
                    badgeAddtnlStyle.color = "blue.600";
                    // TODO @hari-anitha : use JS Capitalize function to text case instead of hard coding
                    badgeTxt = "New";
                    break;
                case jobInvitationStatuses.ACCEPTED:
                    badgeAddtnlStyle.colorScheme = "green";
                    badgeAddtnlStyle.color = "green.400";
                    badgeTxt = "Accepted";
                    break;
                case jobInvitationStatuses.DENIED:
                    badgeAddtnlStyle.colorScheme = "red";
                    badgeAddtnlStyle.color = "red.500";
                    badgeTxt = "Denied";
                    break;
                case jobInvitationStatuses.SELECTED:
                    badgeAddtnlStyle.colorScheme = "red";
                    badgeAddtnlStyle.color = "red.500";
                    badgeTxt = "Selected";
                    break;
                case jobInvitationStatuses.REJECTED:
                    badgeAddtnlStyle.colorScheme = "red";
                    badgeAddtnlStyle.color = "red.500";
                    badgeTxt = "Rejected";
                    break;
                default:
                    break;
            }
            return (
                <Box style={{ textAlign: "center" }}>
                    <Badge
                        fontFamily="Poppins"
                        fontSize="xs"
                        lineHeight={7}
                        fontWeight="normal"
                        width={28}
                        p={0}
                        borderRadius={20}
                        textAlign={"center"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        variant="subtle"
                        textTransform={"none"}
                        {...badgeAddtnlStyle}
                    >
                        {badgeTxt}
                    </Badge>
                </Box>
            );
        },
    },

    {
        Header: () => (
            <Text {...headerStyles} textAlign={"center"} textTransform={"none"}>
                Interview Status
            </Text>
        ),
        accessor: "jobInterviewStatus",
        // TODO: any to be replaced
        Cell: (props: any) => {
            const badgeAddtnlStyle = {
                colorScheme: "gray",
                color: "gray.600",
            };
            // TODO @hari-anitha : Use i18n translation here
            let badgeTxt = "-";

            switch (props.value) {
                case jobInvitationStatuses.NEW:
                    badgeAddtnlStyle.colorScheme = "blue";
                    badgeAddtnlStyle.color = "blue.600";
                    // TODO @hari-anitha : use JS Capitalize function to text case instead of hard coding
                    badgeTxt = "New";
                    break;
                case jobInvitationStatuses.ACCEPTED:
                    badgeAddtnlStyle.colorScheme = "green";
                    badgeAddtnlStyle.color = "green.400";
                    badgeTxt = "Accepted";
                    break;
                case jobInvitationStatuses.DENIED:
                    badgeAddtnlStyle.colorScheme = "red";
                    badgeAddtnlStyle.color = "red.500";
                    badgeTxt = "Denied";
                    break;
                case jobInvitationStatuses.SELECTED:
                    badgeAddtnlStyle.colorScheme = "green";
                    badgeAddtnlStyle.color = "green.500";
                    badgeTxt = "Selected";
                    break;
                case jobInvitationStatuses.REJECTED:
                    badgeAddtnlStyle.colorScheme = "red";
                    badgeAddtnlStyle.color = "red.500";
                    badgeTxt = "Rejected";
                    break;
                default:
                    break;
            }
            return (
                <Box style={{ textAlign: "center" }}>
                    <Badge
                        fontFamily="Poppins"
                        fontSize="xs"
                        lineHeight={7}
                        fontWeight="normal"
                        width={28}
                        p={0}
                        borderRadius={20}
                        textAlign={"center"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        variant="subtle"
                        textTransform={"none"}
                        {...badgeAddtnlStyle}
                    >
                        {badgeTxt}
                    </Badge>
                </Box>
            );
        },
    },

    {
        Header: () => (
            <Text {...headerStyles} textAlign={"center"} textTransform={"none"}>
                {TranslationFunction("interviewStatusTableHeaders.jd")}
            </Text>
        ),
        accessor: "jobDescriptionId",
        // TODO: any to be replaced
        Cell: (props: any) => {
            return (
                //  TODO: colorScheme of Button to be confirmed
                <Box style={{ textAlign: "center" }}>
                    <Link
                        as={RRDLink}
                        to={`${CLIENT_JOB_DETAILS_PAGE.url}/${props.value}?active-tab=applications&table-view=card&consultant-id=${props?.row?.original?.consultantId}`}
                    >
                        <Button
                            fontFamily="Poppins"
                            fontWeight="normal"
                            fontSize="xs"
                            lineHeight={6}
                            borderRadius="lg"
                            alignItems="center"
                            bg="White"
                            color="gray.800"
                            width={20}
                            height={7}
                            colorScheme="BlackAlpha"
                            variant="outline"
                            disabled={!props.value}
                            textAlign={"center"}
                        >
                            {TranslationFunction("actionButtonText.view")}
                        </Button>
                    </Link>
                </Box>
            );
        },
    },
];
