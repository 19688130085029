import { Box, Button, chakra, Flex, Grid, Heading, IconButton, Skeleton, Spinner, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import ConsultantJobApplicationStatus from "./ConsultantJobApplicationStatus-component";
import ConsultantSearchBanner from "./ConsultantSearchBanner-component";
import { JobDataInf } from "../../../@types/job.types";
import BannerBg from "../../../assets/Images/ConsultantSearchBanner.svg";
import FilterIcon from "../../../assets/ReactSvgIcons/FilterIcon-component";
import AppLayout from "../../../components/global/AppLayout/AppLayout-component";
import Footer from "../../../components/global/Footer/Footer-component";
import NavBar from "../../../components/global/NavBar/NavBar-component";
import QueryRenderer from "../../../components/global/QueryRenderer/QueryRenderer-component";
import SvgIconWrapper from "../../../components/global/SvgIconWrapper/SvgIconWrapper.component";
import JobDetailsPane from "../../../components/jobListPage-components/JobDetailsPane/JobDetailsPane-component";
import JobListCard from "../../../components/jobListPage-components/JobListCard/JobListCard";
import { JOB_DESC_ID } from "../../../constants/UrlParams";
import { CONSULTANT_JOB_SEARCH_PAGE } from "../../../constants/WebAppRoute-constants";
import { useGetJobDescriptionById, useJobDescriptions } from "../../../hooks/jobDescriptions.hook";

const ConsultantJobSearchList = () => {
    const [searchParams] = useSearchParams();

    const { t } = useTranslation("jobList");

    const getSelectedJobDescId = () => {
        const _id = searchParams.get(JOB_DESC_ID);
        return _id || _id === "0" ? _id : null;
    };

    const jobDetailsQueryRes = useGetJobDescriptionById(getSelectedJobDescId() || 0);

    /** The check for a number is vital because job_desc_id can be zero(0) and of course JS is mad at 0 */
    const isRightPaneActive = !!getSelectedJobDescId();

    const queryResponse = useJobDescriptions();
    const { data, fetchNextPage, hasNextPage, isFetching } = queryResponse;
    return (
        <AppLayout minH={"100vh"}>
            <NavBar />
            <Box
                borderRadius={"2xl"}
                mt={10}
                mx={"4vw"}
                py={12}
                style={{ backgroundImage: `url(${BannerBg})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}
                textAlign={"center"}
            >
                <ConsultantSearchBanner />
            </Box>

            <Flex justifyContent={"space-between"} alignItems="center" px={"4vw"} mt={12} textAlign={"center"}>
                <Heading
                    as="h2"
                    color="brand.900"
                    mx="auto"
                    w="full"
                    pl={12}
                    fontWeight="normal"
                    fontSize={"2xl"}
                    fontFamily={"body"}
                >
                    {t("searchResult.header.title1")}{" "}
                    <chakra.span color={"brand.900"} fontWeight={"bold"}>
                        {t("searchResult.header.title2")}
                    </chakra.span>
                </Heading>
                <IconButton
                    icon={<SvgIconWrapper svgComp={<FilterIcon />} />}
                    aria-label="filter-jobs"
                    colorScheme={"blue"}
                    bg={"brand.900"}
                    isRound
                    w={12}
                    h={12}
                />
            </Flex>

            <Flex justifyContent={"space-between"} mt={6}>
                <Box
                    maxH={isRightPaneActive ? "6xl" : "full"}
                    overflowY={"auto"}
                    w={isRightPaneActive ? `35%` : `full`}
                    pb={isRightPaneActive ? 6 : 0}
                >
                    {data?.pages.map(
                        (group, i) =>
                            group.length > 0 && (
                                <Grid
                                    templateColumns={{
                                        base: "repeat(1, 1fr)",
                                        md: `repeat(${isRightPaneActive ? `1` : `2`}, 1fr)`,
                                        lg: `repeat(${isRightPaneActive ? `1` : `3`}, 1fr)`,
                                        "2xl": `repeat(${isRightPaneActive ? `1` : `4`}, 1fr)`,
                                    }}
                                    gap={{ base: "2rem", md: "2rem", lg: "2rem" }}
                                    pr={isRightPaneActive ? 4 : `4vw`}
                                    mb={8}
                                    w={"full"}
                                    key={i}
                                    pl={"4vw"}
                                >
                                    {group.map((inddata: JobDataInf) => {
                                        return (
                                            <JobListCard
                                                key={inddata.id}
                                                {...inddata}
                                                isConsultantView={true}
                                                onClickRedirectionLink={`${CONSULTANT_JOB_SEARCH_PAGE.url}?${JOB_DESC_ID}=${inddata.id}`}
                                                isSelected={inddata.id === getSelectedJobDescId()}
                                            />
                                        );
                                    })}
                                </Grid>
                            ),
                    )}
                    {isFetching && (
                        <Flex alignItems={"center"} justifyContent={"center"} h="full" my={12}>
                            <Spinner color="brand.500" thickness="4px" size={{ base: "sm", md: "md", lg: "lg" }} />
                        </Flex>
                    )}
                    <Flex
                        flexDirection={"column"}
                        w="full"
                        alignItems="center"
                        justifyContent="center"
                        mt={isRightPaneActive ? {} : { base: 10, lg: 20 }}
                        pl={isRightPaneActive ? "4vw" : 0}
                        display={!isFetching ? "flex" : "none"}
                    >
                        {hasNextPage ? (
                            <Button
                                variant={"outline"}
                                textColor={"brand.800"}
                                colorScheme="brand"
                                alignItems={"center"}
                                justifyContent={"center"}
                                alignContent="center"
                                size="lg"
                                w={"22%"}
                                py={6}
                                px={20}
                                fontFamily="body"
                                fontWeight={"medium"}
                                fontSize={"sm"}
                                borderWidth={"1px"}
                                borderRadius={"xl"}
                                onClick={() => {
                                    fetchNextPage();
                                }}
                            >
                                {t("table.moreBtnText")}
                            </Button>
                        ) : (
                            <Text>{t("table.noMoreDataTxt")}</Text>
                        )}
                    </Flex>
                </Box>
                {isRightPaneActive &&
                    QueryRenderer({
                        queryResponse: jobDetailsQueryRes,
                        loadingElement: (
                            <Skeleton
                                ml={4}
                                mr={"4vw"}
                                h={"7xl"}
                                w="65%"
                                mb={8}
                                borderRadius="lg"
                                startColor={"gray.50"}
                                endColor={"gray.300"}
                            ></Skeleton>
                        ),
                        jsx: (queryResponse: any) => {
                            const data: JobDataInf = queryResponse.data;
                            return (
                                <Box ml={4} mr={"4vw"} w={"65%"}>
                                    <JobDetailsPane {...data}>
                                        <ConsultantJobApplicationStatus />
                                    </JobDetailsPane>
                                </Box>
                            );
                        },
                    })}
            </Flex>

            <Footer />
        </AppLayout>
    );
};

export default ConsultantJobSearchList;
