import { Avatar, Progress, Button, Text, Box, Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

const headerStyles = {
    fontSize: "sm",
    fontFamily: "Raleway",
    fontWeight: "bold",
    alignItems: "center",
    justifyContent: "center",
    color: "gray.900",
};

const textStyles = {
    fontFamily: "Poppins",
    fontSize: "xs",
    lineHeight: 7,
    fontWeight: "normal",
    color: "gray.800",
};

const TranslationFunction = (translationVariable: string) => {
    const { t } = useTranslation("consultantTable");
    return t(translationVariable);
};

export const CONSULTANT_APPLICATION_COLUMNS = (
    handleConsultantCardClick: (
        // eslint-disable-next-line no-unused-vars
        _consultantId: number,
    ) => void,
) => [
    {
        Header: () => (
            <Text {...headerStyles} textAlign={"center"} textTransform={"none"}>
                {TranslationFunction("consultantTableHeaders.avatar")}
            </Text>
        ),
        accessor: "avatarUrl",
        // TODO: any to be replaced
        Cell: (props: any) => {
            return (
                <Box textAlign="center">
                    <Avatar name={props.row.original.nickName} src={props.value} textAlign={"center"} size="sm" />
                </Box>
            );
        },
    },
    {
        Header: () => (
            <Text {...headerStyles} textAlign={"center"} textTransform={"none"}>
                {TranslationFunction("consultantTableHeaders.nickName")}
            </Text>
        ),
        accessor: "nickName",
        Cell: (props: any) => {
            return (
                <Text {...textStyles} textAlign={"center"}>
                    {props.value}
                </Text>
            );
        },
    },
    {
        Header: () => (
            <Text {...headerStyles} textAlign={"center"} textTransform={"none"}>
                {TranslationFunction("consultantTableHeaders.role")}
            </Text>
        ),
        accessor: "jobPosition",
        Cell: (props: any) => {
            return (
                <Text {...textStyles} textAlign={"center"}>
                    {props.value}
                </Text>
            );
        },
    },
    {
        Header: () => (
            <Text {...headerStyles} textAlign={"center"} textTransform={"none"}>
                {TranslationFunction("consultantTableHeaders.currentProject")}
            </Text>
        ),
        accessor: "activeRemainingWrkInMonths",
        // TODO: any to be replaced
        Cell: (props: any) => {
            return (
                <Flex flexDirection={"column"} textAlign="center" alignItems={"center"} justifyContent="center">
                    <Progress
                        value={12 - props.value}
                        max={12}
                        size="sm"
                        w={"70%"}
                        mt={2}
                        borderRadius="2xl"
                        colorScheme={"blue"}
                    />
                    <Text
                        color="gray.500"
                        fontWeight="medium"
                        fontFamily="Poppins"
                        pt={1}
                        lineHeight={6}
                        alignItems="center"
                        textAlign={"center"}
                        fontSize="10px"
                    >{`${props.value} Remaining`}</Text>
                </Flex>
            );
        },
    },
    {
        Header: () => (
            <Text {...headerStyles} textAlign={"center"} textTransform={"none"}>
                {TranslationFunction("consultantTableHeaders.profile")}
            </Text>
        ),
        accessor: "consultantId",
        // TODO: any to be replaced
        Cell: (props: any) => {
            return (
                <Flex w="full" alignItems="center" justifyContent="center">
                    <Button
                        fontFamily="Poppins"
                        fontWeight="normal"
                        fontSize="xs"
                        lineHeight={6}
                        borderRadius="lg"
                        alignItems="center"
                        bg="White"
                        color="gray.800"
                        width={20}
                        height={7}
                        colorScheme="BlackAlpha"
                        variant="outline"
                        textAlign={"center"}
                        onClick={() => {
                            handleConsultantCardClick(props.value);
                        }}
                    >
                        View
                    </Button>
                </Flex>
            );
        },
    },
];
