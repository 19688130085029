import { Flex, Progress } from "@chakra-ui/react";
import React from "react";

const CircleLoading = () => {
    return (
        <Flex w="full" justifyContent={"center"} alignItems="center">
            <Progress size="xs" isIndeterminate color={"red.500"} w="50%" />
        </Flex>
    );
};

export default CircleLoading;
