import { Box } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import HomeIcon from "../../../assets/ReactSvgIcons/HomeIcon-component";
import PageHeader from "../../../assets/ReactSvgIcons/PageHeader-component";
import InterviewStatusTable from "../../../components/consultantViewPage-components/InterviewStatusTable/InterviewStatusTable-component";
import AppLayout from "../../../components/global/AppLayout/AppLayout-component";
import Breadcrumbs from "../../../components/global/Breadcrumbs/Breadcrumbs-component";
import Footer from "../../../components/global/Footer/Footer-component";
import NavBar from "../../../components/global/NavBar/NavBar-component";
import SvgIconWrapper from "../../../components/global/SvgIconWrapper/SvgIconWrapper.component";
import { CLIENT_INTERVIEW_REQ_PAGE, CLIENT_JOB_DESCRIPTIONS_PAGE } from "../../../constants/WebAppRoute-constants";
import { useAllJobInterviewList } from "../../../hooks/client-hooks/jobApplMng.hooks";
import { useGetSubscriberId, useGetSubscriptionId } from "../../../lib/authFns";
import { transportInvitationArrayDataSchema } from "../../../lib/dataModifiers";

const InterviewRequestsPage = () => {
    const { t } = useTranslation("allInterviewListPage");
    const _clientId = useGetSubscriberId();

    const getAllInvitatnQry = useAllJobInterviewList(_clientId);

    const breadCrumbsData = [
        {
            _jsxContent: <SvgIconWrapper svgComp={<HomeIcon />} alignItems="center" w={3} h="full" mt={0} />,
            pageId: CLIENT_JOB_DESCRIPTIONS_PAGE.pageId,
            url: CLIENT_JOB_DESCRIPTIONS_PAGE.url,
        },
        {
            // TODO @hari-anitha : Implement translation in below line
            _jsxContent: "All interview Requests",
            pageId: CLIENT_INTERVIEW_REQ_PAGE.pageId,
            url: CLIENT_INTERVIEW_REQ_PAGE.url,
            isCurrentPage: true,
        },
    ];

    return (
        <AppLayout minH={"100vh"} justifyContent="flex-start">
            <NavBar />
            <PageHeader pageText={t("pageTitle")} previousPagePath={"../"} />
            <Breadcrumbs data={breadCrumbsData} />
            <Box px="4vw" mt={12}>
                <InterviewStatusTable data={transportInvitationArrayDataSchema(getAllInvitatnQry?.data || [])} />
            </Box>
            <Box w="full" mt="auto">
                <Footer />
            </Box>
        </AppLayout>
    );
};

export default InterviewRequestsPage;
