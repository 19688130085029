import { useToast } from "@chakra-ui/react";
import { format } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { JobDescriptionFormInf, JobDescriptionPayloadInf } from "../../../@types/jobDescritionForm.types";
import HomeIcon from "../../../assets/ReactSvgIcons/HomeIcon-component";
import PageHeader from "../../../assets/ReactSvgIcons/PageHeader-component";
import AppLayout from "../../../components/global/AppLayout/AppLayout-component";
import Breadcrumbs from "../../../components/global/Breadcrumbs/Breadcrumbs-component";
import Footer from "../../../components/global/Footer/Footer-component";
import NavBar from "../../../components/global/NavBar/NavBar-component";
import SvgIconWrapper from "../../../components/global/SvgIconWrapper/SvgIconWrapper.component";
import JobDescriptionForm from "../../../components/jobDescriptionPage-components/JobDescriptionForm/JobDescriptionForm";
import { JobStatusTypes, jobTypes } from "../../../constants/Enum-constants";
import { CLIENT_JOB_DESCRIPTIONS_PAGE, CLIENT_JOB_ENTRY_FORM_PAGE } from "../../../constants/WebAppRoute-constants";
import { useCreateJobDescription } from "../../../hooks/jobDescriptions.hook";
import { useGetSubscriberId } from "../../../lib/authFns";

const CreateJobDescription = () => {
    const _clientId = useGetSubscriberId();
    const _clientSubscriberId = useGetSubscriberId();
    const userInfo = {
        jobCreatedBy: _clientSubscriberId?.toString() || "0",
        jobUpdatedBy: _clientSubscriberId?.toString() || "0",
        clientId: _clientId?.toString() || "",
    };

    const { t } = useTranslation("jobDescriptionForm");

    const { mutateAsync, isLoading }: any = useCreateJobDescription();

    const toast = useToast();

    const handleFormSubmit = async (formData: JobDescriptionFormInf) => {
        const { jobStartFrom, jobEndAt, jobType, ...rest } = formData;
        const payload: JobDescriptionPayloadInf = {
            ...rest,
            jobStartFrom,
            jobEndAt,
            jobStatus: JobStatusTypes.CREATE,
            jobType: jobType?.value,
            clientDetails: userInfo.clientId,
        };
        /* PAYLOAD SCHEMA
        {
        ✅"jobProjectOverview": "advahdvavd",
        ✅"jobPosition": "adjhadvavd",
        ❌"jobProjectArea": "adjhadjvadj",
        ❌"jobProjectPhase": "adhbajdbjbad",
        ✅"jobMinSalary": "40000",
        ✅"jobMaxSalary": "50000",
        ✅"jobLocation": "bfjbjaf",
        ✅"jobNoofPositions": "2",
        ✅"jobType": "adcagdcgacd",
        ❌"jobMode": "jhvhadvhvad",
        ❌"jobRole": "ajdbjabdjbajdb",
        ✅"jobExpectedExperienceYrs": "adjvavdvadhv",
        ✅"jobExpectedSkills": "adkbajdbabdjba",
        ✅"jobBenefits": "adjhvajdvjavdjv",
        ❌"jobRemarks": "ajdbjabdjbadb",
        ✅"jobStartFrom": "2024-01-10",
        ✅"jobEndAt": "2024-01-24",
        ❌"jobStatus": "Saved",
        ❌"jobCreatedBy": "hc7gsqow5x81zob",
        ❌"jobUpdatedBy": "hc7gsqow5x81zob",
        ⚠️"clientId": "0", // show be "clientDetails"
        }
        */

        // /* Temp comment
        try {
            await mutateAsync(payload);
        } catch (error) {
            toast({
                title: t("createJobDescription.failure"),
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.info("API POST **failed, error ->", error);
        }
        // */
    };

    const breadCrumbsData = [
        {
            _jsxContent: <SvgIconWrapper svgComp={<HomeIcon />} alignItems="center" w={3} h="full" mt={0} />,
            pageId: CLIENT_JOB_DESCRIPTIONS_PAGE.pageId,
            url: CLIENT_JOB_DESCRIPTIONS_PAGE.url,
        },
        {
            _jsxContent: CLIENT_JOB_ENTRY_FORM_PAGE.pageName,
            pageId: CLIENT_JOB_ENTRY_FORM_PAGE.pageId,
            url: CLIENT_JOB_ENTRY_FORM_PAGE.url,
            isCurrentPage: true,
        },
    ];

    return (
        <AppLayout>
            <NavBar />
            <PageHeader
                pageText={t("createJobDescriptionPageTitle")}
                previousPagePath={CLIENT_JOB_DESCRIPTIONS_PAGE.reactRouteWithPathParams}
            />
            <Breadcrumbs data={breadCrumbsData} />
            <JobDescriptionForm
                isLoading={isLoading}
                onFormSubmit={(formData: JobDescriptionFormInf) => handleFormSubmit(formData)}
            />
            <Footer />
        </AppLayout>
    );
};

export default CreateJobDescription;
