import { useMutation } from "react-query";
import { LoginFormPayloadInf } from "./../@types/login.types";
import { CLIENT_LOGIN, CONSULTANT_LOGIN, POCKETBASE_URL } from "./../constants/ApiPaths-constants";
import { post } from "../adapters";
import { UserType } from "../constants/Enum-constants";
import axios from "axios";

/* Contain all the query hooks for user authentication
 * Includes the entire CRUD queries related to user authentication
 */

export const useUserLogin = () => {
    return useMutation(
        (formData: any) => axios.post(`${POCKETBASE_URL}/api/collections/users/auth-with-password`, formData),
        {
            retry: 0,
            retryDelay: 5000,
        },
    );
};
