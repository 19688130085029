import {
    ConsultantCertification,
    ConsultantCertificationPayload,
    ConsultantEducation,
    ConsultantEducationPayload,
    ConsultantLanguage,
    ConsultantLanguagePayload,
    ConsultantProfileFormInf,
    ConsultantProfilePayload,
    ConsultantSkill,
    ConsultantSkillPayload,
    ConsultantWorkExperience,
    ConsultantWorkExperiencePayload,
    DropdownOption,
} from "../../../@types/consultantProfileForm.types";

// This file will have all the consultant profile form helper functions.

const takeValueOfDropdownOption = (option: any) => {
    if (option?.value) {
        return option?.value ? option.value : "";
    }
    return option;
};

const prepareCommaSeparatedValue = (option: any) => {
    if (Array.isArray(option) && option?.length > 0) {
        return option.map(({ value }: any) => value).toString();
    }
    return "";
};

const prepareMultiSelectDropdownData = (data: string | null) => {
    if (data) {
        const selectedDropdownData: DropdownOption[] = data.split(",").map((name) => ({ label: name, value: name }));
        return selectedDropdownData;
    }
    return [];
};

const prepareSingleSelectDropdownData = (data: string | null) => {
    if (data) {
        const selectedDropdownData: DropdownOption = data.split(",").map((name) => ({ label: name, value: name }))[0];
        return selectedDropdownData;
    }
    return "";
};

const prepareEducationFormData = (consultantEducations: ConsultantEducationPayload[]) => {
    return consultantEducations.map((education: ConsultantEducationPayload) => {
        const { educationCourseStartyear, educationCourseEndyear, educationGraduationLevel } = education;
        return {
            ...education,
            educationCourseStartyear: educationCourseStartyear ? new Date(educationCourseStartyear) : null,
            educationCourseEndyear: educationCourseEndyear ? new Date(educationCourseEndyear) : null,
            educationGraduationLevel: prepareSingleSelectDropdownData(educationGraduationLevel),
        };
    });
};

const prepareEducationPayloadData = (consultantEducations: ConsultantEducation[]) => {
    return consultantEducations.map((education: ConsultantEducation) => {
        return {
            ...education,
            educationGraduationLevel: takeValueOfDropdownOption(education.educationGraduationLevel),
        } as ConsultantEducationPayload;
    });
};

const prepareWorkExperienceFormData = (consultantWorkExperiences: ConsultantWorkExperiencePayload[]) => {
    return consultantWorkExperiences.map((experience: ConsultantWorkExperiencePayload) => {
        const { workExperienceFrom, workExperienceTo } = experience;
        return {
            ...experience,
            workExperienceFrom: workExperienceFrom ? new Date(workExperienceFrom) : null,
            workExperienceTo: workExperienceTo ? new Date(workExperienceTo) : null,
        };
    });
};

const prepareWorkExperiencePayloadData = (consultantWorkExperiences: ConsultantWorkExperience[]) => {
    return consultantWorkExperiences.map((experience: ConsultantWorkExperience) => {
        return {
            ...experience,
            workExperienceSalary: Number(experience.workExperienceSalary || 0),
        } as ConsultantWorkExperiencePayload;
    });
};

const prepareCertificationFormData = (consultantCertifications: ConsultantCertificationPayload[]) => {
    return consultantCertifications.map((certificatication: ConsultantCertificationPayload) => {
        return {
            ...certificatication,
        };
    });
};

const prepareCertificationPayloadData = (consultantCertifications: ConsultantCertification[]) => {
    return consultantCertifications.map((certificatication: ConsultantCertification) => {
        return {
            ...certificatication,
        } as ConsultantCertificationPayload;
    });
};

const prepareSkillsFormData = (consultantSkills: ConsultantSkillPayload[]) => {
    return consultantSkills.map((skill: ConsultantSkillPayload) => {
        return {
            ...skill,
            skillLevel: prepareSingleSelectDropdownData(skill.skillLevel),
        };
    });
};

const prepareSkillsPayloadData = (consultantSkills: ConsultantSkill[]) => {
    return consultantSkills.map((skill: ConsultantSkill) => {
        return {
            ...skill,
            skillLevel: takeValueOfDropdownOption(skill.skillLevel),
            skillExperienceYears: Number(skill.skillExperienceYears || 0),
        } as ConsultantSkillPayload;
    });
};

const prepareLanguageFormData = (consultantLanguages: ConsultantLanguagePayload[]) => {
    return consultantLanguages.map((consultantLanguage: ConsultantLanguagePayload) => {
        return {
            ...consultantLanguage,
            language: prepareSingleSelectDropdownData(consultantLanguage.language),
            languageLevel: prepareSingleSelectDropdownData(consultantLanguage.languageLevel),
        };
    });
};

const prepareLanguagePayloadData = (consultantLanguages: ConsultantLanguage[]) => {
    return consultantLanguages.map((consultantLanguage: ConsultantLanguage) => {
        return {
            ...consultantLanguage,
            language: takeValueOfDropdownOption(consultantLanguage.language),
            languageLevel: takeValueOfDropdownOption(consultantLanguage.languageLevel),
        } as ConsultantLanguagePayload;
    });
};

// TODO: @umesh Add the remaining dynamic form fields
export const prepareConsultantProfileFormData = (
    consultantProfileData: ConsultantProfilePayload,
): ConsultantProfileFormInf => {
    const {
        consultantExperienceYears,
        consultantCurrentProjectStart,
        consultantCurrentProjectEnd,
        consultantCountry,
        consultantPrefecture,
        consultantHobbies,
        consultantCurrentStatus,
        consultantEducations,
        consultantWorkExperiences,
        consultantCertifications,
        consultantSkills,
        consultantLanguages,
    } = consultantProfileData;

    const formData: ConsultantProfileFormInf = {
        ...consultantProfileData,
        consultantExperienceYears: Number(consultantExperienceYears),
        consultantCurrentProjectStart: consultantCurrentProjectStart ? new Date(consultantCurrentProjectStart) : null,
        consultantCurrentProjectEnd: consultantCurrentProjectEnd ? new Date(consultantCurrentProjectEnd) : null,
        consultantCountry: prepareSingleSelectDropdownData(consultantCountry),
        consultantPrefecture: prepareSingleSelectDropdownData(consultantPrefecture),
        consultantHobbies: prepareMultiSelectDropdownData(consultantHobbies),
        consultantCurrentStatus: prepareSingleSelectDropdownData(consultantCurrentStatus),
        consultantEducations: prepareEducationFormData(consultantEducations),
        consultantWorkExperiences: prepareWorkExperienceFormData(consultantWorkExperiences || []),
        consultantCertifications: prepareCertificationFormData(consultantCertifications || []),
        consultantSkills: prepareSkillsFormData(consultantSkills || []),
        consultantLanguages: prepareLanguageFormData(consultantLanguages || []),
    };

    return formData;
};

// TODO: @umesh Add the remaining dynamic form fields
export const formDataModifier = (formData: ConsultantProfileFormInf) => {
    return {
        ...formData,
        consultantExperienceYears: Number(formData.consultantExperienceYears),
        consultantCountry: takeValueOfDropdownOption(formData.consultantCountry),
        consultantCurrentStatus: takeValueOfDropdownOption(formData.consultantCurrentStatus),
        consultantHobbies: prepareCommaSeparatedValue(formData.consultantHobbies),
        consultantPrefecture: takeValueOfDropdownOption(formData.consultantPrefecture),
        consultantEducations: prepareEducationPayloadData(formData.consultantEducations || []),
        consultantWorkExperiences: prepareWorkExperiencePayloadData(formData.consultantWorkExperiences || []),
        consultantCertifications: prepareCertificationPayloadData(formData.consultantCertifications || []),
        consultantSkills: prepareSkillsPayloadData(formData.consultantSkills || []),
        consultantLanguages: prepareLanguagePayloadData(formData.consultantLanguages || []),
    };
};
