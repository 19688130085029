import { CalendarIcon } from "@chakra-ui/icons";
import {
    Button,
    FormControl,
    FormLabel,
    GridItem,
    Input,
    SimpleGrid,
    VStack,
    useBreakpointValue,
    Textarea,
    Flex,
    IconButton,
    FormErrorMessage,
    Box,
    Text,
    Spinner,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { JobDescriptionFormInf, JobDescriptionFormPropsInf } from "../../../@types/jobDescritionForm.types";
import { convertDaysToMilliSeconds } from "../../../lib/dateTimeFns";
import SvgIconWrapper from "../../global/SvgIconWrapper/SvgIconWrapper.component";
import "react-datepicker/dist/react-datepicker.css";
import CustomMultiSelectDropdown from "../../global/CustomMultiSelectDropdown.tsx/CustomMultiSelectDropdown-component";

const ONE_DAY_IN_MILLI_SECONDS = convertDaysToMilliSeconds(1);

const JobDescriptionForm = ({ onFormSubmit, isLoading, data }: JobDescriptionFormPropsInf) => {
    const { t } = useTranslation("jobDescriptionForm");
    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<JobDescriptionFormInf>({
        mode: "onBlur",
        defaultValues: {},
    });

    const colSpanType2 = useBreakpointValue({ base: 10, lg: 4 });
    const colSpanType3 = useBreakpointValue({ base: 10, lg: 12 });
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [datePickerIsOpen, openDatePicker] = useState(false);
    const selectStartDate = (d: Date | null) => {
        setStartDate(d);
    };

    const selectEndDate = (d: Date | null) => {
        setEndDate(d);
    };

    const onSubmit = (data: JobDescriptionFormInf) => {
        onFormSubmit(data);
    };

    useEffect(() => {
        if (data) {
            // TODO - Umesh: Remove this date conversion and API has to send this fields as a date string.

            const { jobStartFrom, jobEndAt } = data;
            data.jobStartFrom = new Date(jobStartFrom);
            data.jobEndAt = new Date(jobEndAt);
            reset(data);
        }
    }, [data]);

    // if (isLoading) {
    //     return (
    //         <Flex alignItems={"center"} justifyContent={"center"} h="100vh" my={12}>
    //             <Spinner color="brand.500" thickness="4px" size={{ base: "sm", md: "md", lg: "lg" }} />
    //         </Flex>
    //     );
    // }

    return (
        <VStack w="full" h="full" p={10} spacing={10} alignItems="flex-start" px={"4vw"}>
            <Box width={"full"}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <SimpleGrid
                        templateColumns="repeat(12, 1fr)"
                        columnGap={16}
                        rowGap={12}
                        w="full"
                        maxWidth="container.xl"
                        display={["flex", "flex", "grid"]}
                        flexDirection={"column"}
                    >
                        <GridItem colSpan={colSpanType3} maxWidth={["96", "100%", "100%"]}>
                            <FormControl isInvalid={!!errors?.jobProjectOverview?.message}>
                                <FormLabel fontFamily={"special"} fontWeight={700} marginBottom={4}>
                                    {t("form.field1.label")} :
                                </FormLabel>
                                <Textarea
                                    placeholder={t("form.field1.placeholder")}
                                    size={"lg"}
                                    {...register("jobProjectOverview", {
                                        required: {
                                            value: true,
                                            message: t("form.field1.requiredPromptText"),
                                        },
                                    })}
                                />

                                <FormErrorMessage fontSize={"xs"}>
                                    {errors?.jobProjectOverview?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        {/* TODO:Umesh - This field is yet to be confirmed */}
                        {/* <GridItem colSpan={colSpanType1} colStart={1} maxWidth={["96", "100%", "100%"]}>
                            <Controller
                                control={control}
                                name="languages"
                                rules={{ required: t("form.field2.requiredPromptText") }}
                                render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
                                    <FormControl isInvalid={!!error?.message} id="languages">
                                        <FormLabel>{t("form.field2.label")} :</FormLabel>
                                        <MultiSelect<LanguageTypeOption, true, GroupBase<LanguageTypeOption>>
                                            isMulti
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            options={languageOptions}
                                            placeholder={t("form.field2.placeholder")}
                                            closeMenuOnSelect={false}
                                        />
                                        <FormErrorMessage fontSize={"xs"}>{error && error.message}</FormErrorMessage>
                                    </FormControl>
                                )}
                            />
                        </GridItem> */}
                        <GridItem colSpan={colSpanType2} maxWidth={["96", "100%", "100%"]}>
                            <FormControl isInvalid={!!errors?.jobPosition?.message}>
                                <FormLabel>{t("form.field3.label")} :</FormLabel>
                                <Input
                                    placeholder={t("form.field3.placeholder")}
                                    {...register("jobPosition", {
                                        required: {
                                            value: true,
                                            message: t("form.field3.requiredPromptText"),
                                        },
                                    })}
                                />
                                <FormErrorMessage fontSize={"xs"}>{errors?.jobPosition?.message}</FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        {/* TODO @hari_anitha: 
                         NOT Required for dummy demo
                        <GridItem colSpan={colSpanType2} maxWidth={["96", "100%", "100%"]}>
                            <FormControl isInvalid={!!errors?.jobProjectArea?.message}>
                                <FormLabel>{t("form.field4.label")} :</FormLabel>
                                <Input
                                    placeholder={t("form.field4.placeholder")}
                                    {...register("jobProjectArea", {
                                        required: {
                                            value: true,
                                            message: t("form.field4.requiredPromptText"),
                                        },
                                    })}
                                />
                                <FormErrorMessage fontSize={"xs"}>{errors?.jobProjectArea?.message}</FormErrorMessage>
                            </FormControl>
                        </GridItem> */}
                        {/* TODO @hari_anitha: 
                         NOT Required for dummy demo
                          <GridItem colSpan={colSpanType2} maxWidth={["96", "100%", "100%"]}>
                            <FormControl isInvalid={!!errors?.jobProjectPhase?.message}>
                                <FormLabel>{t("form.field5.label")} :</FormLabel>
                                <Input
                                    placeholder={t("form.field5.placeholder")}
                                    {...register("jobProjectPhase", {
                                        required: {
                                            value: true,
                                            message: t("form.field5.requiredPromptText"),
                                        },
                                    })}
                                />
                                <FormErrorMessage fontSize={"xs"}>{errors?.jobProjectPhase?.message}</FormErrorMessage>
                            </FormControl>
                        </GridItem> */}
                        <GridItem colSpan={colSpanType2} colStart={1} maxWidth={["96", "100%", "100%"]}>
                            <Flex direction={"column"} justifyContent={"flex-start"} position={"relative"}>
                                <Text fontWeight={"medium"} mb={4} align={"left"}>
                                    {t("form.field6.label")} :
                                </Text>
                                <Flex alignItems={"center"}>
                                    <Controller
                                        control={control}
                                        name="jobMinSalary"
                                        rules={{ required: t("form.field6.requiredPromptText") }}
                                        render={({
                                            field: { onChange, onBlur, value, name },
                                            fieldState: { error },
                                        }) => (
                                            <FormControl isInvalid={!!errors?.jobMinSalary?.message}>
                                                {/* <Input
                                                    placeholder={t("form.field6.placeholder")}
                                                    {...register("jobMinSalary", {
                                                        required: {
                                                            value: true,
                                                            message: t("form.field6.requiredPromptText"),
                                                        },
                                                        min: {
                                                            value: 1,
                                                            message: t("form.field6.minPromptText"),
                                                        },
                                                    })}
                                                    type="number"
                                                /> */}
                                                <NumberInput
                                                    name={name}
                                                    defaultValue={20000}
                                                    min={0}
                                                    placeholder={t("form.field6.placeholder")}
                                                    {...register("jobMinSalary", {
                                                        required: {
                                                            value: true,
                                                            message: t("form.field6.requiredPromptText"),
                                                        },
                                                        pattern: {
                                                            value: /^[0-9]*$/,
                                                            message: "Please enter a valid number",
                                                        },
                                                    })}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    step={5000}
                                                >
                                                    <NumberInputField />
                                                    <NumberInputStepper onChange={onChange}>
                                                        <NumberIncrementStepper />
                                                        <NumberDecrementStepper />
                                                    </NumberInputStepper>
                                                </NumberInput>
                                                <FormErrorMessage position={"absolute"} fontSize={"xs"}>
                                                    {errors?.jobMinSalary?.message}
                                                </FormErrorMessage>
                                            </FormControl>
                                        )}
                                    />
                                    <Text mx={2}>-</Text>
                                    <Controller
                                        control={control}
                                        name="jobMaxSalary"
                                        rules={{ required: t("form.field7.requiredPromptText") }}
                                        render={({
                                            field: { onChange, onBlur, value, name },
                                            fieldState: { error },
                                        }) => (
                                            <FormControl isInvalid={!!errors?.jobMaxSalary?.message}>
                                                {/* <Input
                                                    placeholder={t("form.field7.placeholder")}
                                                    {...register("jobMinSalary", {
                                                        required: {
                                                            value: true,
                                                            message: t("form.field7.requiredPromptText"),
                                                        },
                                                        min: {
                                                            value: 1,
                                                            message: t("form.field7.minPromptText"),
                                                        },
                                                    })}
                                                    type="number"
                                                /> */}
                                                <NumberInput
                                                    name={name}
                                                    defaultValue={30000}
                                                    min={0}
                                                    placeholder={t("form.field7.placeholder")}
                                                    {...register("jobMaxSalary", {
                                                        required: {
                                                            value: true,
                                                            message: t("form.field7.requiredPromptText"),
                                                        },
                                                        pattern: {
                                                            value: /^[0-9]*$/,
                                                            message: "Please enter a valid number",
                                                        },
                                                    })}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    step={5000}
                                                >
                                                    <NumberInputField />
                                                    <NumberInputStepper onChange={onChange}>
                                                        <NumberIncrementStepper />
                                                        <NumberDecrementStepper />
                                                    </NumberInputStepper>
                                                </NumberInput>
                                                <FormErrorMessage position={"absolute"} fontSize={"xs"}>
                                                    {errors?.jobMaxSalary?.message}
                                                </FormErrorMessage>
                                            </FormControl>
                                        )}
                                    />
                                    {/*  */}
                                    {/* <FormControl isInvalid={!!errors?.jobMaxSalary?.message}>
                                        <Input
                                            placeholder={t("form.field7.placeholder")}
                                            type="number"
                                            {...register("jobMaxSalary", {
                                                required: {
                                                    value: true,
                                                    message: t("form.field7.requiredPromptText"),
                                                },
                                                min: {
                                                    value: 1,
                                                    message: t("form.field7.minPromptText"),
                                                },
                                            })}
                                        />
                                        <FormErrorMessage position={"absolute"} fontSize={"xs"}>
                                            {errors?.jobMaxSalary?.message}
                                        </FormErrorMessage>
                                    </FormControl> */}
                                    {/*  */}
                                </Flex>
                            </Flex>
                        </GridItem>
                        <GridItem colSpan={colSpanType2} maxWidth={["96", "100%", "100%"]}>
                            <FormControl isInvalid={!!errors?.jobLocation?.message}>
                                <FormLabel>{t("form.field8.label")} :</FormLabel>
                                <Input
                                    placeholder={t("form.field8.placeholder")}
                                    {...register("jobLocation", {
                                        required: {
                                            value: true,
                                            message: t("form.field8.requiredPromptText"),
                                        },
                                    })}
                                />
                                <FormErrorMessage fontSize={"xs"}>{errors?.jobLocation?.message}</FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={colSpanType2} maxWidth={["96", "100%", "100%"]}>
                            <Controller
                                control={control}
                                name="jobNoofPositions"
                                rules={{ required: t("form.field9.requiredPromptText") }}
                                render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
                                    <FormControl isInvalid={!!errors?.jobNoofPositions?.message}>
                                        <FormLabel>{t("form.field9.label")} :</FormLabel>
                                        <NumberInput
                                            name={name}
                                            defaultValue={1}
                                            min={1}
                                            placeholder={t("form.field9.placeholder")}
                                            {...register("jobNoofPositions", {
                                                required: {
                                                    value: true,
                                                    message: t("form.field9.requiredPromptText"),
                                                },
                                                pattern: {
                                                    value: /^[0-9]*$/,
                                                    message: "Please enter a valid number",
                                                },
                                            })}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                        >
                                            <NumberInputField />
                                            <NumberInputStepper onChange={onChange}>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                        <FormErrorMessage fontSize={"xs"}>
                                            {errors?.jobNoofPositions?.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            />
                        </GridItem>
                        <GridItem colSpan={colSpanType2} maxWidth={["96", "100%", "100%"]}>
                            <Flex direction={"column"} justifyContent={"flex-start"} position={"relative"}>
                                <Text fontWeight={"medium"} mb={4} align={"left"}>
                                    {t("form.field10.label")} :
                                </Text>
                                <Flex
                                    alignItems={"center"}
                                    border={"1px solid"}
                                    borderColor={"gray.200"}
                                    borderRadius={"base"}
                                    justifyContent={"space-between"}
                                >
                                    <Controller
                                        control={control}
                                        {...register("jobStartFrom", {
                                            required: t("form.field10.fieldA.requiredPromptText"),
                                        })}
                                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                            <FormControl
                                                isInvalid={!!error?.message}
                                                id="workPeriodStart"
                                                maxWidth={36}
                                            >
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={value}
                                                    onChange={(date: Date | null) => {
                                                        selectStartDate(date);
                                                        onChange(date);
                                                        if (date && error?.message) {
                                                            error.message = undefined;
                                                        }
                                                    }}
                                                    onBlur={onBlur}
                                                    selectsStart
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    onInputClick={() => openDatePicker(true)}
                                                    placeholderText="yyyy-mm-dd"
                                                    className={error?.message ? "error" : ""}
                                                    open={datePickerIsOpen}
                                                    onClickOutside={() => openDatePicker(false)}
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    minDate={new Date()}
                                                    maxDate={
                                                        endDate
                                                            ? new Date(endDate.getTime() - ONE_DAY_IN_MILLI_SECONDS)
                                                            : null
                                                    }
                                                />
                                                <FormErrorMessage position={"absolute"} fontSize={"xs"}>
                                                    {error?.message}
                                                </FormErrorMessage>
                                            </FormControl>
                                        )}
                                    />
                                    <Controller
                                        control={control}
                                        {...register("jobEndAt", {
                                            required: t("form.field10.fieldB.requiredPromptText"),
                                        })}
                                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                            <FormControl isInvalid={!!error?.message} id="workPeriodEnd" maxWidth={36}>
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={value}
                                                    onChange={(date: Date | null) => {
                                                        selectEndDate(date);
                                                        onChange(date);
                                                        if (date && error?.message) {
                                                            error.message = undefined;
                                                        }
                                                    }}
                                                    onBlur={onBlur}
                                                    selectsEnd
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    minDate={
                                                        startDate
                                                            ? new Date(startDate.getTime() + ONE_DAY_IN_MILLI_SECONDS)
                                                            : new Date(new Date().getTime() + ONE_DAY_IN_MILLI_SECONDS)
                                                    }
                                                    placeholderText="yyyy-mm-dd"
                                                    className={error?.message ? "error" : ""}
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                />
                                                <FormErrorMessage position={"absolute"} fontSize={"xs"}>
                                                    {error?.message}
                                                </FormErrorMessage>
                                            </FormControl>
                                        )}
                                    />
                                    <IconButton
                                        icon={<SvgIconWrapper svgComp={<CalendarIcon />} />}
                                        aria-label="filter-jobs"
                                        colorScheme={"brand"}
                                        bg={"brand.800"}
                                        w={9}
                                        h={9}
                                        ml={2}
                                        mr={1}
                                        onClick={() => openDatePicker(true)}
                                    />
                                </Flex>
                            </Flex>
                        </GridItem>
                        <GridItem colSpan={colSpanType2} maxWidth={["96", "100%", "100%"]}>
                            <Controller
                                control={control}
                                name="jobType"
                                rules={{ required: t("form.field11.requiredPromptText") }}
                                render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
                                    <FormControl isInvalid={!!errors?.jobType?.message}>
                                        <FormLabel>{t("form.field11.label")} :</FormLabel>
                                        <CustomMultiSelectDropdown
                                            isMulti={false}
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            options={[
                                                { value: "Part time", label: "Part time" },
                                                { value: "Full time", label: "Full time" },
                                            ]}
                                            placeholder={t("form.field11.placeholder")}
                                            closeMenuOnSelect={true}
                                        />
                                        <FormErrorMessage fontSize={"xs"}>{error && error.message}</FormErrorMessage>
                                    </FormControl>
                                )}
                            />
                            {/* <FormControl isInvalid={!!errors?.jobType?.message}>
                                <FormLabel>{t("form.field11.label")} :</FormLabel>
                                <Input
                                    placeholder={t("form.field11.placeholder")}
                                    {...register("jobType", {
                                        required: {
                                            value: true,
                                            message: t("form.field11.requiredPromptText"),
                                        },
                                    })}
                                />
                                

                                <FormErrorMessage fontSize={"xs"}>{errors?.jobType?.message}</FormErrorMessage>
                            </FormControl> */}
                        </GridItem>
                        {/*  TODO @hari_anitha: 
                         NOT Required for dummy demo
                        <GridItem colSpan={colSpanType2} maxWidth={["96", "100%", "100%"]}>
                            <FormControl isInvalid={!!errors?.jobMode?.message}>
                                <FormLabel>{t("form.field12.label")} :</FormLabel>
                                <Input
                                    placeholder={t("form.field12.placeholder")}
                                    {...register("jobMode", {
                                        required: {
                                            value: true,
                                            message: t("form.field12.requiredPromptText"),
                                        },
                                    })}
                                />
                                <FormErrorMessage fontSize={"xs"}>{errors?.jobMode?.message}</FormErrorMessage>
                            </FormControl>
                        </GridItem> */}
                        {/* 
                         TODO @hari_anitha: 
                         NOT Required for dummy demo
                        <GridItem colSpan={colSpanType3} colStart={1} maxWidth={["96", "100%", "100%"]}>
                            <FormControl isInvalid={!!errors?.jobRole?.message}>
                                <FormLabel>{t("form.field13.label")} :</FormLabel>
                                <Textarea
                                    placeholder={t("form.field13.placeholder")}
                                    {...register("jobRole", {
                                        required: {
                                            value: true,
                                            message: t("form.field13.requiredPromptText"),
                                        },
                                    })}
                                />
                                <FormErrorMessage fontSize={"xs"}>{errors?.jobRole?.message}</FormErrorMessage>
                            </FormControl>
                        </GridItem> */}
                        <GridItem colSpan={colSpanType3} colStart={1} maxWidth={["96", "100%", "100%"]}>
                            <Controller
                                control={control}
                                name="jobExpectedExperienceYrs"
                                rules={{ required: t("form.field14.requiredPromptText") }}
                                render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
                                    <FormControl isInvalid={!!errors?.jobExpectedExperienceYrs?.message}>
                                        <FormLabel>{t("form.field14.label")} :</FormLabel>
                                        {/* <Textarea
                                    placeholder={t("form.field14.placeholder")}
                                    {...register("jobExpectedExperienceYrs", {
                                        required: {
                                            value: true,
                                            message: t("form.field14.requiredPromptText"),
                                        },
                                        pattern: {
                                            value: /^[0-9]*$/,
                                            message: "Please enter a valid number",
                                        },
                                    })}
                                /> */}
                                        <NumberInput
                                            name={name}
                                            defaultValue={0}
                                            min={0}
                                            placeholder={t("form.field14.placeholder")}
                                            {...register("jobExpectedExperienceYrs", {
                                                required: {
                                                    value: true,
                                                    message: t("form.field14.requiredPromptText"),
                                                },
                                                pattern: {
                                                    value: /^[0-9]*$/,
                                                    message: "Please enter a valid number",
                                                },
                                            })}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                        >
                                            <NumberInputField />
                                            <NumberInputStepper onChange={onChange}>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                        <FormErrorMessage fontSize={"xs"}>{error && error?.message}</FormErrorMessage>
                                    </FormControl>
                                )}
                            />
                        </GridItem>
                        <GridItem colSpan={colSpanType3} colStart={1} maxWidth={["96", "100%", "100%"]}>
                            <FormControl isInvalid={!!errors?.jobExpectedSkills?.message}>
                                <FormLabel>{t("form.field15.label")} :</FormLabel>
                                <Textarea
                                    placeholder={t("form.field15.placeholder")}
                                    {...register("jobExpectedSkills", {
                                        required: {
                                            value: true,
                                            message: t("form.field15.requiredPromptText"),
                                        },
                                    })}
                                />
                                <FormErrorMessage fontSize={"xs"}>
                                    {errors?.jobExpectedSkills?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={colSpanType3} colStart={1} maxWidth={["96", "100%", "100%"]}>
                            <FormControl isInvalid={!!errors?.jobBenefits?.message}>
                                <FormLabel>{t("form.field16.label")} :</FormLabel>
                                <Textarea
                                    placeholder={t("form.field16.placeholder")}
                                    {...register("jobBenefits", {
                                        required: {
                                            value: false,
                                            message: t("form.field16.requiredPromptText"),
                                        },
                                    })}
                                />
                                <FormErrorMessage fontSize={"xs"}>{errors?.jobBenefits?.message}</FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        {/* 
                         TODO @hari_anitha: 
                         NOT Required for dummy demo
                        <GridItem colSpan={colSpanType3} colStart={1} maxWidth={["96", "100%", "100%"]}>
                            <FormControl isInvalid={!!errors?.jobRemarks?.message}>
                                <FormLabel>{t("form.field17.label")} :</FormLabel>
                                <Textarea
                                    placeholder={t("form.field17.placeholder")}
                                    {...register("jobRemarks", {
                                        required: {
                                            value: false,
                                            message: t("form.field17.requiredPromptText"),
                                        },
                                    })}
                                />
                                <FormErrorMessage fontSize={"xs"}>{errors?.jobRemarks?.message}</FormErrorMessage>
                            </FormControl>
                        </GridItem> */}
                        <GridItem
                            colSpan={colSpanType3}
                            colStart={1}
                            maxWidth={["96", "100%", "100%"]}
                            textAlign={"right"}
                        >
                            <Button
                                variant={"outline"}
                                textColor={"gray.50"}
                                bg={"brand.800"}
                                colorScheme="brand"
                                alignItems={"center"}
                                justifyContent={"center"}
                                alignContent="center"
                                size="lg"
                                w={80}
                                py={6}
                                mt={12}
                                fontFamily="body"
                                fontWeight={"medium"}
                                fontSize={"sm"}
                                borderWidth={"2px"}
                                borderRadius={"xl"}
                                _hover={{
                                    background: "brand.700",
                                }}
                                type="submit"
                                isLoading={isLoading}
                            >
                                {data ? t("updateBtnText") : t("submitBtnText")}
                            </Button>
                        </GridItem>
                    </SimpleGrid>
                </form>
            </Box>
        </VStack>
    );
};

export default JobDescriptionForm;
