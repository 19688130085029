import React from "react";

export interface LocationThinIconInf {
    width?: string;
    height?: string;
    color?: string;
    stroke?: string;
}

const LocationThinIcon = ({ width = "100%", height = "100%", stroke = "#727272" }: LocationThinIconInf) => {
    return (
        <svg width={width} height={height} viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6 8.6665C7.10457 8.6665 8 7.77107 8 6.6665C8 5.56193 7.10457 4.6665 6 4.6665C4.89543 4.6665 4 5.56193 4 6.6665C4 7.77107 4.89543 8.6665 6 8.6665Z"
                stroke={stroke}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.99935 1.33325C4.58486 1.33325 3.22831 1.89516 2.22811 2.89535C1.22792 3.89554 0.666016 5.2521 0.666016 6.66659C0.666016 7.92792 0.934016 8.75325 1.66602 9.66659L5.99935 14.6666L10.3327 9.66659C11.0647 8.75325 11.3327 7.92792 11.3327 6.66659C11.3327 5.2521 10.7708 3.89554 9.77058 2.89535C8.77039 1.89516 7.41384 1.33325 5.99935 1.33325V1.33325Z"
                stroke={stroke}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default LocationThinIcon;
