import { Box } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "react-use";
import { InvitationsResponse, InvitationTableInfDetailsInf } from "../../@types/InvitationTable.types";
import PageHeader from "../../assets/ReactSvgIcons/PageHeader-component";
import InvitationTable from "../../components/consultantViewPage-components/InvitationsTable/InvitationTable-component";
import AppLayout from "../../components/global/AppLayout/AppLayout-component";
import Footer from "../../components/global/Footer/Footer-component";
import NavBar from "../../components/global/NavBar/NavBar-component";
import { SUBSCRIBER_ID_LOCAL_STORAGE_KEY } from "../../constants/Auth-constants";
import { CONSULTANT_JOB_SEARCH_PAGE } from "../../constants/WebAppRoute-constants";
import { useGetAllInvitationsByConsultant } from "../../hooks/consultant-hooks/invitations.hook";

const getInvitationTableDataFromResponse = (data: Array<any>) => {
    return data.map((item) => {
        const {
            companyName = "-",
            jobInterviewSchedule: interviewDate,
            jobDescription: jobDescriptionId,
            jobRole: role,
            jobInvitationStatus,
            jobInterviewStatus,

            jobProjectArea,
        } = item;

        const tableRowData: InvitationTableInfDetailsInf = {
            companyName: item?.expand?.jobDescription?.expand?.clientDetails?.companyName ?? "-",
            interviewDate,
            jobDescriptionId,
            role: item?.expand?.jobDescription?.jobPosition,
            jobInvitationStatus,
            jobInterviewStatus,
            jobProjectArea,
        };

        return tableRowData;
    });
};

const ConsultantAppliedJob = () => {
    const [subscriberIdLocalState] = useLocalStorage(SUBSCRIBER_ID_LOCAL_STORAGE_KEY);
    const consultantId = subscriberIdLocalState ? subscriberIdLocalState : null;
    const { data } = useGetAllInvitationsByConsultant(String(consultantId));
    const { t } = useTranslation("consultantInvitationTable");

    return (
        <AppLayout minH={"100vh"} justifyContent="flex-start">
            <NavBar />
            <PageHeader pageText={t("pageTitle")} previousPagePath={CONSULTANT_JOB_SEARCH_PAGE.url} />

            <Box px="4vw" mt={12}>
                <InvitationTable data={data ? getInvitationTableDataFromResponse(data) : []} />
            </Box>

            <Box w="full" mt="auto">
                <Footer />
            </Box>
        </AppLayout>
    );
};

export default ConsultantAppliedJob;
