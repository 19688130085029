import React from "react";

const ListViewIcon = ({ color = "#9A9A9A" }: { color?: string }) => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.68677 5.9292H3.10091V7.34334H1.68677V5.9292Z" fill={color} />
            <path
                d="M5.22221 7.34334H22.1919C22.3794 7.34334 22.5593 7.26885 22.6919 7.13624C22.8245 7.00364 22.899 6.8238 22.899 6.63627C22.899 6.44874 22.8245 6.2689 22.6919 6.1363C22.5593 6.00369 22.3794 5.9292 22.1919 5.9292H5.22221C5.03468 5.9292 4.85483 6.00369 4.72223 6.1363C4.58963 6.2689 4.51514 6.44874 4.51514 6.63627C4.51514 6.8238 4.58963 7.00364 4.72223 7.13624C4.85483 7.26885 5.03468 7.34334 5.22221 7.34334Z"
                fill={color}
            />
            <path d="M1.68677 10.1716H3.10091V11.5858H1.68677V10.1716Z" fill={color} />
            <path
                d="M22.1919 10.1716H5.22221C5.03468 10.1716 4.85483 10.2461 4.72223 10.3787C4.58963 10.5113 4.51514 10.6912 4.51514 10.8787C4.51514 11.0662 4.58963 11.2461 4.72223 11.3787C4.85483 11.5113 5.03468 11.5858 5.22221 11.5858H22.1919C22.3794 11.5858 22.5593 11.5113 22.6919 11.3787C22.8245 11.2461 22.899 11.0662 22.899 10.8787C22.899 10.6912 22.8245 10.5113 22.6919 10.3787C22.5593 10.2461 22.3794 10.1716 22.1919 10.1716Z"
                fill={color}
            />
            <path d="M1.68677 14.4141H3.10091V15.8282H1.68677V14.4141Z" fill={color} />
            <path
                d="M22.1919 14.4141H5.22221C5.03468 14.4141 4.85483 14.4886 4.72223 14.6212C4.58963 14.7538 4.51514 14.9336 4.51514 15.1211C4.51514 15.3087 4.58963 15.4885 4.72223 15.6211C4.85483 15.7537 5.03468 15.8282 5.22221 15.8282H22.1919C22.3794 15.8282 22.5593 15.7537 22.6919 15.6211C22.8245 15.4885 22.899 15.3087 22.899 15.1211C22.899 14.9336 22.8245 14.7538 22.6919 14.6212C22.5593 14.4886 22.3794 14.4141 22.1919 14.4141Z"
                fill={color}
            />
            <path d="M1.68677 18.6565H3.10091V20.0706H1.68677V18.6565Z" fill={color} />
            <path
                d="M22.1919 18.6565H5.22221C5.03468 18.6565 4.85483 18.731 4.72223 18.8636C4.58963 18.9962 4.51514 19.176 4.51514 19.3636C4.51514 19.5511 4.58963 19.7309 4.72223 19.8635C4.85483 19.9961 5.03468 20.0706 5.22221 20.0706H22.1919C22.3794 20.0706 22.5593 19.9961 22.6919 19.8635C22.8245 19.7309 22.899 19.5511 22.899 19.3636C22.899 19.176 22.8245 18.9962 22.6919 18.8636C22.5593 18.731 22.3794 18.6565 22.1919 18.6565Z"
                fill={color}
            />
        </svg>
    );
};

export default ListViewIcon;
