//  TODO @hari-anitha :need to use i18n here
import { Box, Button, Flex, Grid, Skeleton, Spinner, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { ConsultantDetailsPropsInf } from "../../../@types/consultant.types";
import { consultantTableInf } from "../../../@types/consultantTable.types";
import { InfToggleWatchListingFn } from "../../../pages/client-pages/individual-job-page/CommonTabArea";
import ConsultantCard from "../ConsultantCard/ConsultantCard-component";
import ConsultantDetails from "../ConsultantDetails/ConsultantDetails-component";

interface SubmitButtonInf {
    label: string;
    onClick: () => void;
    isLoading: boolean;
    // TODO @hari-anitha : Change this any data-type
    btnTheme?: any;
}
interface ConsultantTableWithDetailedViewPropInf extends consultantTableInf {
    selectedCstId: number | null;
    jobDescriptionId: number | string;
    selectedConsultantData: ConsultantDetailsPropsInf | null;
    isReqWaiting: boolean;
    submitAreaTxt: string;
    submitBtnsData: SubmitButtonInf[] | [];
    // eslint-disable-next-line no-unused-vars
    toggleWatchListing: (args: InfToggleWatchListingFn) => void;
}

const ConsultantTableWithDetailedView = ({
    data,
    selectedCstId,
    jobDescriptionId,
    selectedConsultantData,
    handleConsultantCardClick,
    isReqWaiting,
    submitAreaTxt,
    submitBtnsData,
    toggleWatchListing,
}: ConsultantTableWithDetailedViewPropInf) => {
    const [selectedCsltWatchState, setCstlWatchState] = useState(false);

    const setWatchListStateOfConsltInlDetailsView = (currentWatchState: boolean) => {
        setCstlWatchState(currentWatchState);
    };

    const renderJobHiringArea = (isReqWaiting: boolean, message: string, submitBtns: SubmitButtonInf[] | []) => {
        if (isReqWaiting) {
            return (
                <Flex w="full" py={2} justifyContent={"center"} alignItems="center">
                    <Spinner />
                </Flex>
            );
        }
        return (
            <Flex justifyContent={"space-between"} alignItems="center" w="full">
                <Text ml={4} fontSize="sm" fontWeight="medium" color="gray.600">
                    {message}
                </Text>
                <>
                    {submitBtns.map((_btnData) => {
                        return (
                            <Button
                                key={_btnData.label}
                                colorScheme={_btnData?.btnTheme?.colorScheme || "red"}
                                bg={_btnData?.btnTheme?.bg || "red.500"}
                                textColor={_btnData?.btnTheme?.textColor || "red.500"}
                                px={12}
                                py={6}
                                fontSize="xs"
                                minW={"56"}
                                mx={4}
                                onClick={_btnData.onClick}
                            >
                                {_btnData.label}
                            </Button>
                        );
                    })}
                </>
            </Flex>
        );
    };

    return (
        <Flex justifyContent={"space-between"}>
            <Grid
                templateColumns={{
                    base: "repeat(1, 1fr)",
                }}
                gap={{ base: "2rem", md: "2rem", lg: "2rem" }}
                py={6}
                pt={0}
                pr={4}
                w="30%"
                overflowY={"auto"}
                maxH="160vh"
                h="fit-content"
            >
                {data.map((_indData) => {
                    return (
                        <ConsultantCard
                            jobApplicationId={_indData.jobApplicationId}
                            {..._indData}
                            key={_indData.avatarUrl}
                            isSelected={_indData.consultantId === selectedCstId}
                            jobDescriptionId={jobDescriptionId}
                            handleConsultantCardClick={handleConsultantCardClick}
                            setWatchListStateInParent={setWatchListStateOfConsltInlDetailsView}
                            toggleWatchListing={toggleWatchListing}
                        />
                    );
                })}
            </Grid>
            <Box ml={8} w={"70%"}>
                {selectedConsultantData ? (
                    <ConsultantDetails
                        {...selectedConsultantData}
                        wishlisted={selectedCsltWatchState || false}
                        toggleWatchListing={toggleWatchListing}
                    >
                        <Flex
                            justifyContent={"flex-end"}
                            py={4}
                            px={2}
                            borderWidth={"1px"}
                            borderColor="gray.200"
                            borderBottomRadius={"lg"}
                        >
                            {renderJobHiringArea(isReqWaiting, submitAreaTxt, submitBtnsData)}
                        </Flex>
                    </ConsultantDetails>
                ) : (
                    <Skeleton w="full" height="5xl" />
                )}
            </Box>
        </Flex>
    );
};

export default ConsultantTableWithDetailedView;
