import { BoxProps } from "@chakra-ui/react";
import { PropsValue, OptionsOrGroups, GroupBase, Select as MultiSelect } from "chakra-react-select";
import React from "react";
import { MultiSelectOption } from "../../../@types/advancedFormFilter.types";

interface CustomMultiSelectCompPropInf {
    name: string;
    onChange: () => void;
    onBlur: () => void;
    value: PropsValue<MultiSelectOption> | undefined | string;
    options: OptionsOrGroups<MultiSelectOption, GroupBase<MultiSelectOption>> | undefined;
    placeholder: string;
    optionStyles?: BoxProps;
    placeholderStyles?: BoxProps;
    isMulti?: false | true;
    closeMenuOnSelect?: boolean;
}
const CustomMultiSelectDropdown = ({
    name,
    onChange,
    onBlur,
    value,
    options,
    placeholder,
    optionStyles,
    placeholderStyles,
    isMulti = true,
    closeMenuOnSelect = false,
}: CustomMultiSelectCompPropInf) => {
    return (
        <MultiSelect<MultiSelectOption | string, false | true, GroupBase<MultiSelectOption>>
            isMulti={isMulti}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            options={options}
            placeholder={placeholder}
            closeMenuOnSelect={closeMenuOnSelect}
            // size={"lg"}
            chakraStyles={{
                dropdownIndicator: (prev, { selectProps: { menuIsOpen } }) => ({
                    ...prev,
                    "> svg": {
                        transitionDuration: "normal",
                        transform: `rotate(${menuIsOpen ? -180 : 0}deg)`,
                    },
                }),
                placeholder: (provided: any) => ({
                    ...provided,
                    ...placeholderStyles,
                }),
                option: (provided: any) => ({
                    ...provided,
                    ...optionStyles,
                }),
            }}
            menuPosition={"fixed"} //TODO: @umesh remove this option
        />
    );
};

export default CustomMultiSelectDropdown;
