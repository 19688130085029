// TODO @hari-anitha : Use i18n in this file
import { convertDateTo_yyyy_MM_dd, distanceFromNowInMonths, getYearFromDate } from "./dateTimeFns";
import { ConsultantApplicationDataSchema, ConsultantComplResDataInf } from "../@types/consultantDataSchema.types";
import { interviewStatusTableDetailsInf } from "../@types/interviewStatusTable.types";
import { JobInvitationResSchemaInf } from "../@types/jobApplication.types";

export const modifyIndConsultantData = (_data: ConsultantApplicationDataSchema) => {
    return {
        consultantId: _data?.expand?.consultant?.id,
        nickName: _data?.expand?.consultant?.consultantName || "-",
        avatarUrl: _data?.consultantAvatar,
        qualification: "Post Graduation",
        jobPosition: _data?.expand?.consultant?.role,
        location: _data?.expand?.consultant?.address,
        expInYrs: _data?.expand?.consultant?.consultantTotalExperienceInYears
            ? parseInt(_data?.expand?.consultant?.consultantTotalExperienceInYears)
            : 0,
        activeRemainingWrkInMonths: _data?.expand?.consultant?.consultantCurrentProjectEnd
            ? distanceFromNowInMonths(_data?.expand?.consultant?.consultantCurrentProjectEnd)
            : "0 months",
        wishlisted: !!_data?.isActive,
        jobApplicationId: _data?.id,
        jobApplicationStatus: _data?.jobApplicationStatus,
    };
};

export const modifyConsultantArrayData = (data: ConsultantApplicationDataSchema[]) => {
    return data.map((_ind) => {
        return modifyIndConsultantData(_ind);
    });
};

export const consultantDataSchemeTransport = (data: ConsultantComplResDataInf) => {
    return {
        // ...modifyIndConsultantData(data),
        consultantId: data?.id,
        nickName: data?.consultantName || "-",
        avatarUrl: data?.consultantAvatar,
        qualification: "Post Graduation",
        jobPosition: data?.role,
        location: data?.address,
        expInYrs: data?.consultantTotalExperienceInYears ? parseInt(data?.consultantTotalExperienceInYears) : 0,
        activeRemainingWrkInMonths: data?.consultantCurrentProjectEnd
            ? distanceFromNowInMonths(data?.consultantCurrentProjectEnd)
            : "0 months",
        wishlisted: !!data?.isActive,
        jobApplicationId: data?.id,
        jobApplicationStatus: data?.jobApplicationStatus,
        workStatus: data?.consultantCurrentStatus ?? "Seeking",
        about: data?.about,
        currentProjectDetails: [
            `${data?.consultantCurrentRole} in ${data?.consultantCurrentProject} at ${
                data?.consultantCurrentCompany
            } (${convertDateTo_yyyy_MM_dd(data?.consultantCurrentProjectStart)} - ${convertDateTo_yyyy_MM_dd(
                data?.consultantCurrentProjectEnd,
            )})`,
        ],
        education:
            data?.consultantEducations?.map(
                (_ind: any) =>
                    `${_ind?.educationCoursename}  at ${_ind?.educationInstitution} (${getYearFromDate(
                        _ind?.educationCourseStartyear,
                    )} - ${getYearFromDate(_ind?.educationCourseEndyear)})`,
            ) || [],
        certifications:
            data?.consultantCertifications?.map(
                (_ind: any) => `${_ind?.certificationTitle} from ${_ind?.certificationIssuedby}`,
            ) || [],
        experience:
            data?.consultantWorkExperiences?.map(
                (_ind: any) =>
                    `${_ind?.workExperienceRole} at ${_ind?.workExperienceCompany} (${getYearFromDate(
                        _ind?.workExperienceFrom,
                    )} - ${getYearFromDate(_ind?.workExperienceTo)})`,
            ) || [],
        skills: data?.skills ? [data?.skills] : ["Not mentioned"],
        hobbies: data?.hobbies ? [data?.hobbies] : ["Not mentioned"],
        knownLanguages: data?.languages?.map((_ind: any) => _ind) || [],
    };
};

export const transportInvitationIndDataSchema = (data: JobInvitationResSchemaInf): interviewStatusTableDetailsInf => {
    return {
        consultantId: data?.expand?.consultant?.id,
        nickName: data?.expand?.consultant?.consultantName,
        jobPosition: data?.expand?.consultant?.role,
        interviewDate: data?.jobInterviewSchedule,
        status: data?.jobInvitationStatus,
        jobInterviewStatus: data?.jobInterviewStatus,
        jobDescriptionId: data?.jobDescription,
        reschedule: false,
    };
};

export const transportInvitationArrayDataSchema = (
    data: JobInvitationResSchemaInf[],
): interviewStatusTableDetailsInf[] => {
    return data.map((_ind) => {
        return transportInvitationIndDataSchema(_ind);
    });
};
