import { Flex } from "@chakra-ui/react";
import React from "react";
import { BannerProps } from "../../../@types/banner.types";

const Banner = ({ children, flexProps, bgUrl }: BannerProps) => {
    return (
        <Flex
            py={3}
            w="full"
            {...flexProps}
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            bgImage={`url('${bgUrl}')`}
            bgSize="cover"
        >
            {children}
        </Flex>
    );
};

export default Banner;
