import { Progress, useToast } from "@chakra-ui/react";
import { format } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { JobDescriptionFormInf, JobDescriptionPayloadInf } from "../../../@types/jobDescritionForm.types";
import HomeIcon from "../../../assets/ReactSvgIcons/HomeIcon-component";
import PageHeader from "../../../assets/ReactSvgIcons/PageHeader-component";
import AppLayout from "../../../components/global/AppLayout/AppLayout-component";
import Breadcrumbs from "../../../components/global/Breadcrumbs/Breadcrumbs-component";
import Footer from "../../../components/global/Footer/Footer-component";
import NavBar from "../../../components/global/NavBar/NavBar-component";
import SvgIconWrapper from "../../../components/global/SvgIconWrapper/SvgIconWrapper.component";
import JobDescriptionForm from "../../../components/jobDescriptionPage-components/JobDescriptionForm/JobDescriptionForm";
import { JobStatusTypes } from "../../../constants/Enum-constants";
import { useEditJobDescription, useGetJobDescriptionById } from "../../../hooks/jobDescriptions.hook";
import { useGetSubscriberId, useGetSubscriptionId } from "../../../lib/authFns";

const EditJobDescription = () => {
    const _clientId = useGetSubscriberId();
    const _clientSubscriberId = useGetSubscriberId();
    const userInfo = {
        jobCreatedBy: _clientSubscriberId?.toString() || "0",
        jobUpdatedBy: _clientSubscriberId?.toString() || "0",
        clientId: _clientId?.toString() || "0",
    };

    const { t } = useTranslation("jobDescriptionForm");
    const toast = useToast();
    const { jobDescriptionId } = useParams();
    const { isLoading, isSuccess: isGetJobDataSuccess, data } = useGetJobDescriptionById(jobDescriptionId);
    const { mutateAsync, isLoading: isEditLoading } = useEditJobDescription(jobDescriptionId);

    const handleFormSubmit = async (formData: JobDescriptionFormInf) => {
        const { jobStartFrom, jobEndAt, jobType, ...rest } = formData;
        const payload: JobDescriptionPayloadInf = {
            ...formData,
            jobType: jobType?.value,
            clientDetails: userInfo.clientId,
            jobStatus: JobStatusTypes.UPDATE,
            ...userInfo,
        };

        // /*
        try {
            await mutateAsync(payload);
        } catch (error) {
            toast({
                title: t("editJobDescription.failure"),
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            console.info("API POST **failed, error ->", error);
        }
        // */
    };

    const breadCrumbsData = [
        {
            _jsxContent: <SvgIconWrapper svgComp={<HomeIcon />} alignItems="center" w={3} h="full" mt={0} />,
            pageId: "home",
            url: "/",
        },
        {
            _jsxContent: "Edit Job Description",
            pageId: "editJobDescription",
            url: `/edit-job-description/${jobDescriptionId}`,
            isCurrentPage: true,
        },
    ];

    return (
        <AppLayout>
            <NavBar />
            <PageHeader pageText={t("editJobDescriptionPageTitle")} previousPagePath={"../"} />
            <Breadcrumbs data={breadCrumbsData} />

            {isLoading ? <Progress size="xs" isIndeterminate /> : null}
            {isGetJobDataSuccess ? (
                <JobDescriptionForm
                    isLoading={isEditLoading}
                    onFormSubmit={(formData: JobDescriptionFormInf) => handleFormSubmit(formData)}
                    data={{ ...data, jobType: { value: data?.jobType, label: data?.jobType } }}
                />
            ) : null}

            <Footer />
        </AppLayout>
    );
};

export default EditJobDescription;
