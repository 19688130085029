import React from "react";

const MessageRequestIcon = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.3056 0.655518C13.6188 0.655518 13.8748 0.895633 13.8958 1.19953L13.8971 1.23947L13.8974 7.09721L13.8884 7.09165C15.1559 7.87827 16 9.28266 16 10.8843C16 13.3476 14.0031 15.3445 11.5398 15.3445C9.0765 15.3445 7.07967 13.3476 7.07967 10.8843V10.8751L4.33686 10.8749L0.946128 13.386C0.580033 13.6571 0.066869 13.4312 0.00599919 13.0039L0.00160954 12.9632L0 12.9188V1.23963C0 0.930595 0.243187 0.677602 0.5509 0.656962L0.59143 0.655645L13.3056 0.655518ZM11.5398 7.55684C9.70214 7.55684 8.21231 9.04667 8.21231 10.8843C8.21231 12.722 9.70214 14.2117 11.5398 14.2117C13.3775 14.2117 14.8672 12.722 14.8672 10.8843C14.8672 9.04667 13.3774 7.55684 11.5398 7.55684ZM11.4716 12.1927C11.7251 12.1927 11.9305 12.3981 11.9305 12.6516C11.9305 12.905 11.7251 13.1104 11.4716 13.1104C11.2182 13.1104 11.0128 12.905 11.0128 12.6516C11.0128 12.3981 11.2182 12.1927 11.4716 12.1927ZM11.5372 8.43824C11.7867 8.43824 11.9914 8.62977 12.0121 8.87384L12.0139 8.91496V11.2748C12.0139 11.5381 11.8004 11.7516 11.5372 11.7516C11.2877 11.7516 11.083 11.56 11.0622 11.316L11.0604 11.2748V8.91497C11.0604 8.65174 11.2739 8.43824 11.5372 8.43824ZM12.7143 1.82347H1.18279V11.7507L3.78483 9.82363C3.86004 9.76789 3.9471 9.73101 4.03885 9.71536L4.08509 9.70936L4.13966 9.70687L7.23687 9.70658L7.23482 9.71389C7.74886 7.81831 9.48157 6.424 11.5399 6.424C11.9497 6.424 12.3466 6.47931 12.7235 6.58276L12.7146 6.58027L12.7143 1.82347ZM8.65273 5.63748C8.9423 5.63748 9.17715 5.87232 9.17715 6.16189C9.17715 6.4383 8.96323 6.66481 8.6918 6.68485L8.65273 6.68631H4.71961C4.43004 6.68631 4.19519 6.45147 4.19519 6.1619C4.19519 5.88549 4.40911 5.65898 4.68054 5.63894L4.71961 5.63748H8.65273Z"
                fill="#525252"
            />
        </svg>
    );
};

export default MessageRequestIcon;
