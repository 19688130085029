import { useQuery } from "react-query";
import { get } from "../adapters";
import { CONSULTANT_GET_ALL, CONSULTANT_IND_DATA, POCKETBASE_URL } from "../constants/ApiPaths-constants";

export const useGetConsultantById = (consultantId: number) => {
    return useQuery(
        ["getJobDescription", consultantId],
        async () => {
            const res = await get({
                url: `
            ${POCKETBASE_URL}/api/collections/consultants/records/${consultantId}`,
            })
                .then((res: any) => {
                    return res.data;
                })
                .catch((err: any) => {
                    throw new Error(`error occured => ${JSON.stringify(err)}`);
                });
            return res;
        },
        {
            refetchOnWindowFocus: true,
            enabled: !!consultantId,
        },
    );
};

export const useGetAllConsultants = () => {
    // TODO @hari-anitha : Need to change this to infinite query to support infinite data fetch
    return useQuery(
        ["allConsultant"],
        async () => {
            const res = await get({ url: `${POCKETBASE_URL}/api/collections/consultants/records?perPage=1000` })
                .then((response: any) => {
                    if (response?.data?.items) {
                        return response?.data?.items.map((_item) => ({
                            ..._item,
                            expand: { consultant: _item },
                        })); /* this is used for a tricky thing since -
                        // in the Ind. Job details page (src/pages/client-pages/individual-job-page/CommonTabArea.tsx) -
                        // UI component we have 2 tabs Application (jobApplication DB coll.) and Matching Profile (consultant collection) -
                        both DB coll have a different schema but we need to use same data transformer (modifyIndConsultantData -> src/lib/dataModifiers.ts#L7) -
                        So we are using this trick for solving it.
                        */
                    }
                    return [];
                })
                .catch((err: any) => {
                    throw new Error(`error occured => ${JSON.stringify(err)}`);
                });
            return res;
        },
        {
            refetchOnWindowFocus: true,
        },
    );
};
