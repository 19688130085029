import advancedFilterForm from "./advancedFilterForm.json";
import allInterviewListPage from "./client-pages/all-interview-list-page.json";
import consultantSearchPage from "./client-pages/consultant-search-page.json";
import common from "./common.json";
import consultantViewJobListing from "./consultant-pages/jobListing.json";
import consultantListing from "./consultantListing.json";
import consultantProfileForm from "./consultantProfileForm.json";
import consultantTable from "./consultantTable.json";
import footer from "./footer.json";
import interviewStatusTable from "./interviewStatusTable.json";
import consultantInvitationTable from "./invitationTable.json";
import jobDescriptionForm from "./jobDescriptionForm.json";
import jobList from "./joblist.json";
import loginForm from "./loginForm.json";
import matchingConsultantsPage from "./matchingConsultantsPage.json";
import navbar from "./navbar.json";
import searchWithLocation from "./searchWithLocation.json";

const en = {
    allInterviewListPage,
    common,
    jobList,
    consultantInvitationTable,
    footer,
    jobDescriptionForm,
    consultantListing,
    consultantSearchPage,
    consultantTable,
    interviewStatusTable,
    navbar,
    matchingConsultantsPage,
    advancedFilterForm,
    searchWithLocation,
    loginForm,
    consultantViewJobListing,
    consultantProfileForm,
};

export default en;
