import { Flex, Heading, IconButton } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { To, useNavigate } from "react-router-dom";
import ArrowBackButton from "./ArrowBackButton-component";
import SvgIconWrapper from "../../components/global/SvgIconWrapper/SvgIconWrapper.component";

interface HeaderPropsInf {
    pageText: string;
    previousPagePath?: To | null;
    children?: ReactNode;
    isBackBtnHidden?: boolean | null
}

const PageHeader = (props: HeaderPropsInf) => {
    const { pageText, previousPagePath = null, children = null, isBackBtnHidden=null} = props;
    const navigate = useNavigate();

    const handleBackButtonClick = (_previousPagePath: To | null) => {
        if (_previousPagePath === null) {
            return null;
        } else if (typeof _previousPagePath === "string") {
            navigate(_previousPagePath);
        } else {
            navigate(-1);
        }
    };
    return (
        <Flex px={"4vw"} mt={6} alignItems="center">
            {!isBackBtnHidden && <IconButton
                onClick={() => handleBackButtonClick(previousPagePath)}
                icon={<SvgIconWrapper svgComp={<ArrowBackButton />} w={5} />}
                size="md"
                aria-label="back-button"
                isRound
                variant={"outline"}
                boxShadow="md"
                mt={-1}
                isDisabled={!previousPagePath}
            />}
            <Heading as="h1" fontSize="2xl" fontFamily="heading" fontWeight={"semibold"} pl={isBackBtnHidden ? 0 : 8}>
                {pageText}
            </Heading>
            {children}
        </Flex>
    );
};

export default PageHeader;
