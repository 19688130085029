import React from "react";

export interface MenuPropsInf {
    color? : string
}

const HandBurgerMenuIcon = ({color = "#333333"} : MenuPropsInf ) => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 34 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="-3.05176e-05" y="6.10352e-05" width="34" height="3" fill={color} />
            <rect x="-3.05176e-05" y="9.00006" width="34" height="3" fill={color} />
            <rect x="-3.05176e-05" y="18.0001" width="34" height="3" fill={color} />
        </svg>
    );
};

export default HandBurgerMenuIcon;
