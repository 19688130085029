import { Center, Flex, Spinner, useToast } from "@chakra-ui/react";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LoginFormInf, LoginPayloadInf } from "../../../@types/login.types";
import AuthBanner from "../../../components/auth/AuthBanner/AuthBanner-component";
import LoginForm from "../../../components/auth/LoginForm/LoginForm-component";
import { UserType } from "../../../constants/Enum-constants";
import {
    CLIENT_JOB_DESCRIPTIONS_PAGE,
    CONSULTANT_CREATE_PROFILE_PAGE,
    CONSULTANT_JOB_SEARCH_PAGE,
} from "../../../constants/WebAppRoute-constants";
import { useUserLogin } from "../../../hooks/userAuthentication.hook";
import {
    useLocalSAuthToken,
    useLocalStConsultantIdHook,
    useLocalStSubscriberIdHook,
    useLocalStSubscriptionIdHook,
    useLocalStUserRecord,
    useLocalSttLoggedInUserTypeHook,
} from "../../../lib/authFns";
import { DEMO_CLIENT_ACCOUNT, DEMO_CONSULTANT_ACCOUNT } from "../../../constants/ApiPaths-constants";

const Login = () => {
    const { t } = useTranslation("loginForm");
    const toast = useToast();
    const navigate = useNavigate();
    const [isInitialRenderDone, setInitialRenderState] = React.useState(false);

    const [userTypeLocalState, setUserType] = useLocalSttLoggedInUserTypeHook();
    // eslint-disable-next-line no-unused-vars
    const [subscriberIdLocalState, setSubscriberId] = useLocalStSubscriberIdHook();
    // eslint-disable-next-line no-unused-vars
    const [subscriptionIdLocalState, setSubscriptionId] = useLocalStSubscriptionIdHook();
    // eslint-disable-next-line no-unused-vars
    const [consultantIdLocalState, setConsultantId] = useLocalStConsultantIdHook();
    const [userRecorrdLocalState, setUserRecordLocalState] = useLocalStUserRecord();
    const [authTokenLocalState, setAuthTokenLocalState] = useLocalSAuthToken();

    /* Handling already logged in case  */
    React.useEffect(() => {
        switch (userTypeLocalState) {
            case UserType.CLIENT:
                navigate(CLIENT_JOB_DESCRIPTIONS_PAGE.url, { replace: true });
                break;
            case UserType.CONSULTANT:
                navigate(CONSULTANT_JOB_SEARCH_PAGE.url, { replace: true });
                break;
            case UserType.NON_PROFILED_CONSULTANT:
                navigate(CONSULTANT_CREATE_PROFILE_PAGE.url, { replace: true });
                break;
            default:
                break;
        }
        if (userTypeLocalState === UserType.CLIENT) {
            navigate(CLIENT_JOB_DESCRIPTIONS_PAGE.url, { replace: true });
        }
        if (userTypeLocalState === UserType.CONSULTANT) {
            navigate(CONSULTANT_JOB_SEARCH_PAGE.url, { replace: true });
        }
        setInitialRenderState(true);
    }, []);

    const { mutateAsync, isLoading } = useUserLogin();

    const onSubmit: SubmitHandler<LoginFormInf> = async (formData) => {
        try {
            const { subscriberEmail, subscriberPwd } = formData;
            // const userType = process.env.REACT_APP_USER;
            const loginData = {
                identity: subscriberEmail,
                password: subscriberPwd,
            };
            await mutateAsync(loginData, {
                onSuccess: (response: any) => {
                    const _resData = response?.data;

                    const { record } = _resData;

                    setUserRecordLocalState(record);
                    setAuthTokenLocalState(_resData?.token);

                    // check usertype
                    // if userType is client
                    if (record?.userType === "CLIENT") {
                        setUserType(UserType.CLIENT);
                        setSubscriberId(record?.clientData || null);
                        navigate(CLIENT_JOB_DESCRIPTIONS_PAGE.url, { replace: true });
                        // if userType is consultant
                    } else if (record?.userType === "CONSULTANT") {
                        setUserType(UserType.CONSULTANT);
                        setSubscriberId(record?.consultantData || null);
                        navigate(CONSULTANT_JOB_SEARCH_PAGE.url, { replace: true });
                    } else {
                        toast({
                            title: t("userLogin.incorrectCredential"),
                            status: "error",
                            duration: 3000,
                            isClosable: true,
                        });
                    }
                },
                onError: () => {
                    toast({
                        title: t("userLogin.failure"),
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                },
            });
        } catch (error) {
            toast({
                title: t("userLogin.failure"),
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    // eslint-disable-next-line no-unused-vars
    const demoOnSubmit = (formData: any) => {
        const { subscriberEmail } = formData;
        if (subscriberEmail.toLowerCase() === "client@gmail.com") {
            setUserType(UserType.CLIENT);
            navigate(CLIENT_JOB_DESCRIPTIONS_PAGE.url, { replace: true });
        } else if (subscriberEmail.toLowerCase() === "consultant@gmail.com") {
            setUserType(UserType.CONSULTANT);
            navigate(CONSULTANT_JOB_SEARCH_PAGE.url, { replace: true });
        }
    };
    if (!isInitialRenderDone) {
        return (
            <Flex alignItems={"center"} justifyContent={"center"} h="100vh" my={12}>
                <Spinner color="brand.500" thickness="4px" size={{ base: "sm", md: "md", lg: "lg" }} />
            </Flex>
        );
    }
    return (
        <Flex width="full" mx="auto">
            <AuthBanner
                handleDemoAcntSelectn={(option: { userType: "CLIENT" | "CONSULTANT" }) => {
                    if (option.userType === "CLIENT") {
                        onSubmit({
                            subscriberEmail: DEMO_CLIENT_ACCOUNT.DEMO_CLIENT_ACCOUNT_ID,
                            subscriberPwd: DEMO_CLIENT_ACCOUNT.DEMO_CLIENT_ACCOUNT_PASS,
                        });
                    } else if (option.userType === "CONSULTANT") {
                        onSubmit({
                            subscriberEmail: DEMO_CONSULTANT_ACCOUNT.DEMO_CONSULTANT_ACCOUNT_ID,
                            subscriberPwd: DEMO_CONSULTANT_ACCOUNT.DEMO_CONSULTANT_ACCOUNT_PASS,
                        });
                    }
                }}
            />
            <Center flex="1">
                <LoginForm isLoading={isLoading} onFormSubmit={onSubmit} />
            </Center>
        </Flex>
    );
};
export default Login;
