import React from "react";

const ExperienceIcon = () => {
    return (
        <svg width="100%%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.4444 3.11111C12.4444 2.40387 12.1635 1.72559 11.6634 1.22549C11.1633 0.725394 10.485 0.444443 9.77778 0.444443H6.22222C5.51498 0.444443 4.8367 0.725394 4.3366 1.22549C3.83651 1.72559 3.55556 2.40387 3.55556 3.11111H2.66667C1.95942 3.11111 1.28115 3.39206 0.781049 3.89216C0.280952 4.39225 0 5.07053 0 5.77778V12.8889C0 13.5961 0.280952 14.2744 0.781049 14.7745C1.28115 15.2746 1.95942 15.5556 2.66667 15.5556H13.3333C14.0406 15.5556 14.7189 15.2746 15.219 14.7745C15.719 14.2744 16 13.5961 16 12.8889V5.77778C16 5.07053 15.719 4.39225 15.219 3.89216C14.7189 3.39206 14.0406 3.11111 13.3333 3.11111H12.4444ZM9.77778 2.22222H6.22222C5.98647 2.22222 5.76038 2.31587 5.59368 2.48257C5.42698 2.64927 5.33333 2.87536 5.33333 3.11111H10.6667C10.6667 2.87536 10.573 2.64927 10.4063 2.48257C10.2396 2.31587 10.0135 2.22222 9.77778 2.22222ZM2.66667 4.88889H13.3333C13.5691 4.88889 13.7952 4.98254 13.9619 5.14924C14.1286 5.31594 14.2222 5.54203 14.2222 5.77778V12.8889C14.2222 13.1246 14.1286 13.3507 13.9619 13.5174C13.7952 13.6841 13.5691 13.7778 13.3333 13.7778H2.66667C2.43092 13.7778 2.20483 13.6841 2.03813 13.5174C1.87143 13.3507 1.77778 13.1246 1.77778 12.8889V5.77778C1.77778 5.54203 1.87143 5.31594 2.03813 5.14924C2.20483 4.98254 2.43092 4.88889 2.66667 4.88889Z"
                fill="#4D4D4D"
            />
        </svg>
    );
};

export default ExperienceIcon;
