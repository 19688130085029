import { Flex, FlexProps } from "@chakra-ui/react";
import React, { ReactElement } from "react";

interface SvgIconProps extends FlexProps {
    svgComp: ReactElement;
    h?: FlexProps["h"];
    w?: FlexProps["w"];
    mt?: FlexProps["mt"];
}

const SvgIconWrapper = (props: SvgIconProps) => {
    const { svgComp, w = "4rem", h = "1rem", mt = 0, ...otherProps } = props;
    return (
        <Flex {...otherProps} w={w} h={h} mt={mt} justifyContent={"center"} alignItems="center">
            {svgComp}
        </Flex>
    );
};

export default SvgIconWrapper;
