import React from "react";

const SaveIcon = () => {
    return (
        <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.11557e-07 1.74996V13.5622C-5.28282e-05 13.6382 0.0187371 13.7128 0.0545205 13.7789C0.0903039 13.8449 0.141847 13.9 0.204076 13.9388C0.266306 13.9775 0.337076 13.9986 0.409421 13.9999C0.481766 14.0013 0.553191 13.9828 0.616667 13.9463L5 11.4351L9.38333 13.9463C9.44681 13.9828 9.51823 14.0013 9.59058 13.9999C9.66292 13.9986 9.73369 13.9775 9.79592 13.9388C9.85815 13.9 9.9097 13.8449 9.94548 13.7789C9.98126 13.7128 10.0001 13.6382 10 13.5622V1.74996C10 1.28584 9.8244 0.840733 9.51184 0.512551C9.19928 0.18437 8.77536 0 8.33333 0L1.66667 0C1.22464 0 0.800716 0.18437 0.488155 0.512551C0.175595 0.840733 1.11557e-07 1.28584 1.11557e-07 1.74996Z"
                fill="#B8B8B8"
            />
        </svg>
    );
};

export default SaveIcon;
