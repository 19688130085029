import React from "react";

const HomeIcon = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.6132 0.425755C7.84009 0.246998 8.15991 0.246998 8.3868 0.425755L14.309 5.09172C15.0611 5.68427 15.5 6.5889 15.5 7.54638V15.2917C15.5 15.867 15.0336 16.3334 14.4583 16.3334H10.2917C9.71637 16.3334 9.25 15.867 9.25 15.2917V11.125C9.25 11.01 9.15673 10.9167 9.04167 10.9167H6.95833C6.84327 10.9167 6.75 11.01 6.75 11.125V15.2917C6.75 15.867 6.28363 16.3334 5.70833 16.3334H1.54167C0.96637 16.3334 0.5 15.867 0.5 15.2917V7.54638C0.5 6.5889 0.938936 5.68427 1.69102 5.09172L7.6132 0.425755ZM8 1.71237L2.46461 6.07358C2.01336 6.42911 1.75 6.97189 1.75 7.54638V15.0834H5.5V11.125C5.5 10.3196 6.15292 9.66669 6.95833 9.66669H9.04167C9.84708 9.66669 10.5 10.3196 10.5 11.125V15.0834H14.25V7.54638C14.25 6.97189 13.9866 6.42911 13.5354 6.07358L8 1.71237Z"
                fill="#212121"
            />
        </svg>
    );
};

export default HomeIcon;
