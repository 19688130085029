import React from "react";

const UnorderedListIcon = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="6" cy="6" r="6" fill="#284E31" />
            <circle cx="6" cy="6" r="4" fill="#F7F7F7" />
        </svg>
    );
};

export default UnorderedListIcon;
