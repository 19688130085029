// This a helper file only
import { AxiosResponse } from "axios";
import { QueryClient, UseMutationResult } from "react-query";
import { useSearchParams } from "react-router-dom";
import { JobInvitationReqBodySchema } from "../../../@types/apiRelated.types";
import { jobApplicationStatuses, jobInterviewStatuses, jobInvitationStatuses } from "../../../constants/Enum-constants";
import { ACTIVE_TAB, CONSULTANT_ID, JOB_APPLN_ID, TABLE_VIEW } from "../../../constants/UrlParams";
import { RespondJobApplHookInf } from "../../../hooks/client-hooks/jobApplMng.hooks";
import { UpdateJobInvitationMtnInf } from "../../../hooks/consultant-hooks/invitations.hook";
import { InfToggleWatchListHook } from "../../../hooks/jobDescriptions.hook";
import { getAllUrlSearchParams } from "../../../lib/routingUtilityFns";

export const useGetAllSearchParams = (searchParamsKeys: string[]) => {
    const [searchParams] = useSearchParams();
    return getAllUrlSearchParams(searchParams, searchParamsKeys);
};

export const useGetActiveTab = () => {
    const [searchParams] = useSearchParams();
    return searchParams.get(ACTIVE_TAB);
};

export const useGetActiveTableViewType = () => {
    const [searchParams] = useSearchParams();
    return searchParams.get(TABLE_VIEW);
};

export const useGetSelectedConsultantId = () => {
    const [searchParams] = useSearchParams();
    const _id = searchParams.get(CONSULTANT_ID);
    return _id ? _id : null;
};

export const useGetSelectedJobApplnId = () => {
    const [searchParams] = useSearchParams();
    const _id = searchParams.get(JOB_APPLN_ID);
    return _id ? parseInt(_id) : null;
};

export const useTabSwitchHandler = (_tabKey: string, searchParamsKeys: string[]) => {
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    setSearchParams({ ...useGetAllSearchParams(searchParamsKeys), [ACTIVE_TAB]: _tabKey });
};

export const useTableViewChangeHandler = (_tabKey: string, searchParamsKeys: string[]) => {
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    setSearchParams({ ...useGetAllSearchParams(searchParamsKeys), [TABLE_VIEW]: _tabKey });
};

interface InterviewStateCompInf {
    jobApplicationStatus: string | null;
    jobInvitationStatus: string | null;
    jobInterviewStatus: string | null;
    isReqWaiting: boolean;
    onSendInviteBtnClick: () => void;
    // eslint-disable-next-line no-unused-vars
    updateInviteHandler: (newInvitationStatus: string, newInterviewStatus: string) => void;
    // eslint-disable-next-line no-unused-vars
    updateJobAppnStatusHandler: (newJobApplicationStatus: string) => void;
}

export const getUIParamsBasedOnJobApplnStatus = ({
    jobApplicationStatus,
    jobInvitationStatus,
    jobInterviewStatus,
    isReqWaiting,
    onSendInviteBtnClick,
    updateInviteHandler,
    updateJobAppnStatusHandler,
}: InterviewStateCompInf) => {
    const btnStyles = {
        positiveBtn: {
            bg: "blue.500",
            colorScheme: "blue",
            textColor: "blue.50",
        },
        negativeBtn: {
            bg: "red.500",
            colorScheme: "red",
            textColor: "red.50",
        },
        neutralBtn: {
            bg: "gray.900",
            colorScheme: "gray",
            textColor: "gray.50",
        },
    };
    if (!jobApplicationStatus && !jobInvitationStatus && !jobInterviewStatus) {
        return {
            message: "",
            isReqWaiting,
            submitBtns: [
                {
                    label: "Send Invite",
                    onClick: onSendInviteBtnClick,
                    isLoading: isReqWaiting,
                    btnTheme: btnStyles.neutralBtn,
                },
            ],
        };
    } else if (jobApplicationStatus === jobApplicationStatuses.APPLIED && !jobInvitationStatus && !jobInterviewStatus) {
        return {
            message: "Consultant has applied for this job. Please take action",
            isReqWaiting,
            submitBtns: [
                {
                    label: "Reject",
                    onClick: () => updateJobAppnStatusHandler(jobApplicationStatuses.REJECTED),
                    isLoading: isReqWaiting,
                    btnTheme: btnStyles.negativeBtn,
                },
                {
                    label: "Accept",
                    onClick: () => updateJobAppnStatusHandler(jobApplicationStatuses.ACCEPTED),
                    isLoading: isReqWaiting,
                    btnTheme: btnStyles.positiveBtn,
                },
            ],
        };
    } else if (
        jobApplicationStatus === jobApplicationStatuses.REJECTED &&
        !jobInvitationStatus &&
        !jobInterviewStatus
    ) {
        return {
            message: "You have already rejected this consultant for the job.",
            isReqWaiting,
            submitBtns: [],
        };
    } else if (
        jobApplicationStatus === jobApplicationStatuses.ACCEPTED &&
        !jobInvitationStatus &&
        !jobInterviewStatus
    ) {
        return {
            message:
                "You have already accepted this consultant application. Please continue by sending an interview invite.",
            isReqWaiting,
            submitBtns: [
                {
                    label: "Send Invite",
                    onClick: onSendInviteBtnClick,
                    isLoading: isReqWaiting,
                    btnTheme: btnStyles.neutralBtn,
                },
            ],
        };
    } else if (
        [jobApplicationStatuses.ACCEPTED, ""].includes(jobApplicationStatus) &&
        jobInvitationStatus === jobInvitationStatuses.DENIED &&
        !jobInterviewStatus
    ) {
        return {
            message: "Consultant has denied your interview invite.",
            isReqWaiting,
            submitBtns: [],
        };
    } else if (
        [jobApplicationStatuses.ACCEPTED, ""].includes(jobApplicationStatus) &&
        jobInvitationStatus === jobInvitationStatuses.ACCEPTED &&
        !jobInterviewStatus
    ) {
        return {
            message:
                "Your invite for an interview was accepted by this consultant. Please update your final decision here after the interview.",
            isReqWaiting,
            submitBtns: [
                {
                    label: "Reject",
                    onClick: () => updateInviteHandler(jobInvitationStatuses.ACCEPTED, jobInterviewStatuses.REJECTED),
                    isLoading: isReqWaiting,
                    btnTheme: btnStyles.negativeBtn,
                },
                {
                    label: "Select for job",
                    onClick: () => updateInviteHandler(jobInvitationStatuses.ACCEPTED, jobInterviewStatuses.SELECTED),
                    isLoading: isReqWaiting,
                    btnTheme: btnStyles.positiveBtn,
                },
            ],
        };
    } else if (
        [jobApplicationStatuses.ACCEPTED, ""].includes(jobApplicationStatus) &&
        jobInvitationStatus === jobInvitationStatuses.NEW &&
        !jobInterviewStatus
    ) {
        return {
            message:
                "You have already sent the interview request to this consultant. Please wait until she or he responds to your request.",
            isReqWaiting,
            submitBtns: [],
        };
    } else if (
        [jobApplicationStatuses.ACCEPTED, ""].includes(jobApplicationStatus) &&
        jobInvitationStatus === jobInvitationStatuses.ACCEPTED &&
        jobInterviewStatus === jobInterviewStatuses.REJECTED
    ) {
        return {
            message: "You have rejected this consultant after the interview.",
            isReqWaiting,
            submitBtns: [],
        };
    } else if (
        [jobApplicationStatuses.ACCEPTED, ""].includes(jobApplicationStatus) &&
        jobInvitationStatus === jobInvitationStatuses.ACCEPTED &&
        jobInterviewStatus === jobInterviewStatuses.SELECTED
    ) {
        return {
            message: "You have selected this consultant for the job after the interview.",
            isReqWaiting,
            submitBtns: [],
        };
    } else if (jobApplicationStatus === "ERROR" && jobInvitationStatus === "ERROR" && jobInterviewStatus === "ERROR") {
        return {
            message: "Something went wrong. Please contact the IT-MAP Care Team",
            isReqWaiting,
            submitBtns: [],
        };
    } else {
        return {
            message: "Unknown",
            isReqWaiting,
            submitBtns: [],
        };
    }
};

interface HandleJobInterviewReqPropInf {
    jobInvitationMessage: string;
    jobInterviewSchedule: Date | string;
    jobInterviewLink: string;
    jobInvitationStatus: string | null;
    jobInterviewStatus: string | null;
    jobInterviewRemarks: string;
}

interface HandleJobInterviewReqInf {
    newInvitationState: string;
    jobDescriptionId: number | null;
    consultantId: number | null;
    userId: string | null;
    jobInterviewId?: number;
    createInviteMtn: UseMutationResult<AxiosResponse<any, any>, unknown, JobInvitationReqBodySchema, unknown>;
    updateInvitationMtn: UseMutationResult<AxiosResponse<any, any>, unknown, UpdateJobInvitationMtnInf, unknown>;
    _reqBody: HandleJobInterviewReqPropInf;
    queryClient: QueryClient;
}

export const handleJobInterviewReq = async (props: HandleJobInterviewReqInf) => {
    const {
        newInvitationState,
        jobDescriptionId,
        consultantId,
        userId,
        jobInterviewId,
        createInviteMtn,
        updateInvitationMtn,
        _reqBody,
        queryClient,
    } = props;
    let mtnHookToBeUsed: any = createInviteMtn;

    interface ReqBodyInf extends JobInvitationReqBodySchema {
        consultantId: number;
        userId: string;
        jobInterviewId?: number;
    }

    if (!!userId && !!consultantId && !!jobDescriptionId) {
        let reqBody: ReqBodyInf = { ..._reqBody, jobDescription: jobDescriptionId, consultant: consultantId };
        mtnHookToBeUsed = createInviteMtn;
        if (newInvitationState !== jobInvitationStatuses.NEW) {
            reqBody = {
                ..._reqBody,
                jobDescription: jobDescriptionId,
                consultant: consultantId,
                jobApplicationId: jobInterviewId,
            };
            mtnHookToBeUsed = updateInvitationMtn;
        }
        try {
            await mtnHookToBeUsed.mutateAsync(reqBody);
            await queryClient.invalidateQueries([
                "check_job_appln_status",
                "jobDescId",
                jobDescriptionId,
                "consultantId",
                consultantId,
            ]);
            console.info("req was successful");
        } catch (error) {
            console.info("some error in req", JSON.stringify(error));
        }
    } else {
        console.info("req sending was blocked because of invalid values in: ", {
            userId,
            consultantId,
            jobDescriptionId,
        });
    }
};

interface HandleJobApplReplyFnInf {
    jobDescriptionId: number | null;
    jobApplicationId: number | null;
    consultantId: number | null;
    newJobApplicationStatus: string;
    clientSubscriberId: number | null;
    jobApplnReplyMtnHook: UseMutationResult<AxiosResponse<any, any>, unknown, RespondJobApplHookInf, unknown>;
    queryClient: QueryClient;
}

export const handleJobApplReply = async (args: HandleJobApplReplyFnInf) => {
    const {
        jobDescriptionId,
        jobApplicationId,
        consultantId,
        newJobApplicationStatus,
        clientSubscriberId,
        jobApplnReplyMtnHook,
        queryClient,
    } = args;

    const _reqBody = {
        jobDescriptionId: jobDescriptionId,
        consultantId: consultantId,
        jobApplicationStatus: newJobApplicationStatus,
        userId: clientSubscriberId,
    };
    try {
        await jobApplnReplyMtnHook.mutateAsync({
            jobApplnId: jobApplicationId || 0,
            reqBody: _reqBody,
        });
        await queryClient.invalidateQueries([
            "check_job_appln_status",
            "jobDescId",
            jobDescriptionId,
            "consultantId",
            consultantId,
        ]);

        console.info("req was successful");
    } catch (error) {
        console.info("some error in req", JSON.stringify(error));
    }
};

export interface InfHandleWatchListToggle {
    jobDescriptionId: number | null;
    consultantId: number;
    clientSubscriberId: number | null;
    isWatchListedCurrently: boolean;
    watchListToggleMtn: UseMutationResult<AxiosResponse<any, any>, unknown, InfToggleWatchListHook, unknown>;
    queryClient: QueryClient;
    onSuccesAction: () => void;
    onFailureAction: () => void;
}
export const handleWatchListToggle = async (args: InfHandleWatchListToggle) => {
    const {
        jobDescriptionId,
        consultantId,
        clientSubscriberId,
        isWatchListedCurrently,
        watchListToggleMtn,
        queryClient,
        onSuccesAction,
        onFailureAction,
    } = args;
    // TODO @hari-anitha : Remove this  !isWatchListedCurrently when the API supports full toggle
    if (!!jobDescriptionId && !!clientSubscriberId) {
        const _reqBody = {
            jobDescriptionId: jobDescriptionId,
            consultantId: consultantId,
            isActive: !isWatchListedCurrently,
            userId: clientSubscriberId,
        };
        try {
            await watchListToggleMtn.mutateAsync({
                ..._reqBody,
            });
            await queryClient.invalidateQueries(["GET", "JOB_WATCH_LIST_PROFILES", "jobId=", `${jobDescriptionId}`], {
                refetchActive: true,
                refetchInactive: true,
            });
            await queryClient.invalidateQueries(["jobAppList", `${jobDescriptionId}`], {
                refetchActive: true,
                refetchInactive: true,
            });
            await queryClient.invalidateQueries(["GET", "JOB_MATCHING_PROFILES", "jobId=", `${jobDescriptionId}`], {
                refetchActive: true,
                refetchInactive: true,
            });
            onSuccesAction();
            console.info("req was successful");
        } catch (error) {
            console.info("some error in req", JSON.stringify(error));
            onFailureAction();
        }
    }
    onFailureAction();
};
