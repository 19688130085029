import React from "react";

const CopyIcon = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.33325 2.66666V10.6667C4.33325 11.0203 4.47373 11.3594 4.72378 11.6095C4.97383 11.8595 5.31296 12 5.66659 12H10.9999C11.3535 12 11.6927 11.8595 11.9427 11.6095C12.1928 11.3594 12.3333 11.0203 12.3333 10.6667V4.82799C12.3332 4.65037 12.2977 4.47454 12.2288 4.31082C12.1599 4.14711 12.0589 3.99882 11.9319 3.87466L9.72192 1.71333C9.47283 1.46977 9.1383 1.33337 8.78992 1.33333H5.66659C5.31296 1.33333 4.97383 1.4738 4.72378 1.72385C4.47373 1.9739 4.33325 2.31304 4.33325 2.66666V2.66666Z"
                stroke="#2B6CB0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.66663 12V13.3333C9.66663 13.6869 9.52615 14.0261 9.2761 14.2761C9.02605 14.5262 8.68691 14.6667 8.33329 14.6667H2.99996C2.64634 14.6667 2.3072 14.5262 2.05715 14.2761C1.8071 14.0261 1.66663 13.6869 1.66663 13.3333V5.99999C1.66663 5.64637 1.8071 5.30723 2.05715 5.05718C2.3072 4.80713 2.64634 4.66666 2.99996 4.66666H4.33329"
                stroke="#2B6CB0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default CopyIcon;
