export const jobTypes = {
    FULLTIME: "Fulltime",
    PART_TIME: "Part-time",
};

export const JobStatusTypes = {
    CREATE: "Saved",
    UPDATE: "Saved",
    SUBMIT: "Submitted",
    APPROVED: "Approved",
    REJECTED: "Rejected",
};

export const tableViewTypes = {
    LIST: "list",
    CARD: "card",
};

export const UserType = {
    CLIENT: "CLIENT",
    CONSULTANT: "CONSULTANT",
    NON_PROFILED_CONSULTANT: "NON_PROFILED_CONSULTANT",
};

export const jobInvitationStatuses = {
    NEW: "NEW",
    ACCEPTED: "ACCEPTED",
    DENIED: "DENIED",
    SELECTED: "SELECTED",
    REJECTED: "REJECTED",
};

// Consultant view area

export const jobApplicationStatuses = {
    APPLIED: "APPLIED",
    ACCEPTED: "ACCEPTED",
    REJECTED: "REJECTED",
};

export const jobInterviewStatuses = {
    SELECTED: "SELECTED",
    REJECTED: "REJECTED",
};
