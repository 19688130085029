import { Grid } from "@chakra-ui/react";
import React from "react";
import { consultantTableInf } from "../../../@types/consultantTable.types";
import ConsultantCard from "../ConsultantCard/ConsultantCard-component";

const ConsultantTableCardView = ({
    data,
    jobDescriptionId,
    handleConsultantCardClick,
    toggleWatchListing,
    ...otherStyleProps
}: consultantTableInf) => {
    return (
        <Grid
            templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
                lg: "repeat(4, 1fr)",
                "2xl": "repeat(4, 1fr)",
            }}
            gap={{ base: "2rem", md: "2rem", lg: "2rem" }}
            py={6}
            w="full"
            h="auto"
            {...otherStyleProps}
        >
            {data.map((_indData) => {
                return (
                    <ConsultantCard
                        {..._indData}
                        key={_indData.avatarUrl}
                        jobDescriptionId={jobDescriptionId}
                        handleConsultantCardClick={handleConsultantCardClick}
                        toggleWatchListing={toggleWatchListing}
                    />
                );
            })}
        </Grid>
    );
};

export default ConsultantTableCardView;
