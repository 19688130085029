import { useMutation, useQuery } from "react-query";
import { JobInvitationReqBodySchema } from "../../@types/apiRelated.types";
import { get, patch, post, put } from "../../adapters";
import {
    CONSULTANT_INVITATIONS,
    CLIENT_CREATE_JOB_INVITE,
    GENERAL_UPDATE_JOB_INVITE,
    CLIENT_GET_JOB_INVITATION_DETAILS,
    POCKETBASE_URL,
} from "../../constants/ApiPaths-constants";

export const useGetAllInvitationsByConsultant = (consultantId: string) => {
    return useQuery(
        `getInvitationsByConsultant/${consultantId}`,
        async () => {
            const res = await get({
                url: `${POCKETBASE_URL}/api/collections/jobApplications/records?expand=jobDescription,jobDescription.clientDetails&filter=(consultant='${consultantId}')`,
            })
                .then((response: any) => {
                    return response?.data?.items;
                })
                .catch((err: any) => {
                    throw new Error(`ERROR:: While fetching invitations by consultant :- ${JSON.stringify(err)}`);
                });
            return res;
        },
        {
            refetchOnWindowFocus: true,
            enabled: !!consultantId,
        },
    );
};

export const useCreateJobInvitationFromClient = () => {
    return useMutation((args: JobInvitationReqBodySchema) =>
        post({ url: `${POCKETBASE_URL}/api/collections/jobApplications/records`, requestBody: { ...args } }),
    );
};

export interface UpdateJobInvitationMtnInf extends JobInvitationReqBodySchema {
    jobInterviewId: number;
}

export const useUpdateJobInvitationFromClient = () => {
    return useMutation((args: UpdateJobInvitationMtnInf) => {
        return patch({
            url: `${POCKETBASE_URL}/api/collections/jobApplications/records/${args.jobApplicationId}`,
            requestBody: { ...args },
        });
    });
};

export const useUpdateJobInvitation = () => {
    return useMutation((args: UpdateJobInvitationMtnInf) =>
        patch({
            url: `${POCKETBASE_URL}/api/collections/jobApplications/records/${args.id}`,
            requestBody: { jobInvitationStatus: args.jobInvitationStatus },
        }),
    );
};

export const useGetJobInviteData = (jobDescriptionId: number | null, consultantId: number | null) => {
    return useQuery(
        [`getJobInvitationDetails`, `jobDescId=${jobDescriptionId}`, `consultantId=${consultantId}`],
        async () => {
            const res = await get({
                url: `${POCKETBASE_URL}/api/collections/jobApplications/records?perPage=1&filter=${encodeURIComponent(
                    `(jobDescription='${jobDescriptionId}' && consultant='${consultantId}')`,
                )}`,
            })
                .then((response: any) => {
                    if (response?.data?.items.length > 0) return response?.data?.items[0];
                    return {};
                })
                .catch((err: any) => {
                    throw new Error(`ERROR:: While fetching invitations by consultant :- ${JSON.stringify(err)}`);
                });
            return res;
        },
        {
            refetchOnWindowFocus: true,
            // enabled: !!Number(consultantId) && !!Number(jobDescriptionId),
        },
    );
};
