import React from "react";
import { QueryRendererInf } from "../../../@types/queryRenderer.types";

const QueryRenderer = (props: QueryRendererInf) => {
    const { queryResponse, errorElement, loadingElement, jsx } = props;
    // eslint-disable-next-line no-unused-vars
    const { data, isLoading, error, refetch } = queryResponse;
    if (error) {
        return errorElement || <div>Error</div>;
    }
    if (isLoading) {
        return loadingElement || <div>Loading</div>;
    }
    return jsx(queryResponse);
};

export default QueryRenderer;
