import { SearchIcon } from "@chakra-ui/icons";
import {
    Box,
    Flex,
    Text,
    IconButton,
    useColorModeValue,
    useBreakpointValue,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "react-use";
import BrandLogo from "../../../assets/ReactSvgIcons/BrandLogo-component";
import ExperienceIcon from "../../../assets/ReactSvgIcons/ExperienceIcon-component";
import HandBurgerMenuIcon from "../../../assets/ReactSvgIcons/HandBurgerMenu-component";
import LogoutIcon from "../../../assets/ReactSvgIcons/LogoutIcon-component";
import MessageRequestIcon from "../../../assets/ReactSvgIcons/MessageRequestIcon-component";
import NotificationIcon from "../../../assets/ReactSvgIcons/NotificationIcon-component";
import SettingsIcon from "../../../assets/ReactSvgIcons/SettingsIcon-component";
import UsersIcon from "../../../assets/ReactSvgIcons/UsersIcon-component";
import { USER_TYPE_LOCAL_STORAGE_KEY } from "../../../constants/Auth-constants";
import { UserType } from "../../../constants/Enum-constants";
import {
    CLIENT_CONSULTANT_SEARCH_PAGE,
    CLIENT_JOB_DESCRIPTIONS_PAGE,
    CONSULTANT_INTERVIEW_APPLNS_PAGE,
    CONSULTANT_JOB_SEARCH_PAGE,
    CLIENT_INTERVIEW_REQ_PAGE,
    CONSULTANT_CREATE_PROFILE_PAGE,
    CONSULTANT_EDIT_PROFILE_PAGE,
    CONSULTANT_PROFILE_VIEW_PAGE,
    CLIENT_LOGIN_PAGE,
    CONSULTANT_LOGIN_PAGE,
    CLIENT_BASE_PATH,
} from "../../../constants/WebAppRoute-constants";
import { clearAllLocalStorage } from "../../../lib/storageFns";
import SvgIconWrapper from "../SvgIconWrapper/SvgIconWrapper.component";

interface NavItem {
    label: string;
    icon?: ReactElement;
    subLabel?: string;
    children?: Array<NavItem>;
    href?: string;
    onClick?: () => void;
}

export default function NavBar() {
    const { t } = useTranslation(["common", "navbar"]);

    const navigate = useNavigate();

    const [userTypeLocalState] = useLocalStorage(USER_TYPE_LOCAL_STORAGE_KEY);
    const translationNameSpaceNavbar = { ns: "navbar" };
    const CONSULTANT_NAV_MENU_ITEMS = [
        {
            label: t("consultantUserMenuItems.item1.title", translationNameSpaceNavbar),
            icon: <SearchIcon />,
            href: "#",
            onClick: () => {
                navigate(CONSULTANT_JOB_SEARCH_PAGE.url);
            },
        },
        {
            label: t("consultantUserMenuItems.item2.title", translationNameSpaceNavbar),
            icon: <SvgIconWrapper svgComp={<MessageRequestIcon />} w={4} />,
            href: "#",
            onClick: () => {
                navigate(CONSULTANT_INTERVIEW_APPLNS_PAGE.url);
            },
        },
        {
            label: t("consultantUserMenuItems.item3.title", translationNameSpaceNavbar),
            icon: <SvgIconWrapper svgComp={<UsersIcon />} w={4} />,
            href: "#",
            /* Commenting for dummy-demo purpose 2024-01-12
             onClick: () => navigate(CONSULTANT_EDIT_PROFILE_PAGE.url),
            */
        },
        {
            label: t("consultantUserMenuItems.item4.title", translationNameSpaceNavbar),
            icon: <SvgIconWrapper svgComp={<UsersIcon />} w={4} />,
            href: "#",
            onClick: () => navigate(CONSULTANT_PROFILE_VIEW_PAGE.url),
        },
        {
            label: t("consultantUserMenuItems.item5.title", translationNameSpaceNavbar),
            icon: <SvgIconWrapper svgComp={<LogoutIcon />} w={4} />,
            href: "#",
            onClick: () => {
                clearAllLocalStorage();
                navigate(CONSULTANT_LOGIN_PAGE.url, { replace: true });
            },
        },
    ];

    const NON_PROFILED_CONSULTANT_NAV_MENU_ITEMS: Array<NavItem> = [
        {
            label: t("nonProfiledConsultantUserMenuItems.item1.title", translationNameSpaceNavbar),
            icon: <SvgIconWrapper svgComp={<LogoutIcon />} w={4} />,
            href: "#",
            onClick: () => {
                clearAllLocalStorage();
                navigate("/", { replace: true });
            },
        },
    ];

    const CLIENT_NAV_MENU_ITEMS: Array<NavItem> = [
        {
            label: t("menuItems.item1.title", translationNameSpaceNavbar),
            icon: <SearchIcon />,
            href: "#",
            onClick: () => {
                navigate(CLIENT_CONSULTANT_SEARCH_PAGE.url);
            },
        },
        {
            label: t("menuItems.item2.title", translationNameSpaceNavbar),
            icon: <SvgIconWrapper svgComp={<ExperienceIcon />} w={4} />,
            href: "#",
            onClick: () => {
                navigate(CLIENT_JOB_DESCRIPTIONS_PAGE.url);
            },
        },
        {
            label: t("menuItems.item3.title", translationNameSpaceNavbar),
            icon: <SvgIconWrapper svgComp={<MessageRequestIcon />} w={4} />,
            href: "#",
            onClick: () => {
                navigate(CLIENT_INTERVIEW_REQ_PAGE.url);
            },
        },
        {
            label: t("menuItems.item4.title", translationNameSpaceNavbar),
            icon: <SvgIconWrapper svgComp={<UsersIcon />} w={4} />,
            href: "#",
        },
        {
            label: t("menuItems.item5.title", translationNameSpaceNavbar),
            icon: <SvgIconWrapper svgComp={<SettingsIcon />} w={4} />,
            href: "#",
        },
        {
            label: t("menuItems.item6.title", translationNameSpaceNavbar),
            icon: <SvgIconWrapper svgComp={<LogoutIcon />} w={4} />,
            href: "#",
            onClick: () => {
                clearAllLocalStorage();
                navigate(CLIENT_LOGIN_PAGE.url, { replace: true });
            },
        },
    ];
    const NAV_ITEMS = (() => {
        switch (userTypeLocalState) {
            case UserType.CLIENT:
                return CLIENT_NAV_MENU_ITEMS;
            case UserType.CONSULTANT:
                return CONSULTANT_NAV_MENU_ITEMS;
            case UserType.NON_PROFILED_CONSULTANT:
                return NON_PROFILED_CONSULTANT_NAV_MENU_ITEMS;
            default:
                return [];
        }
    })();

    const handleHomeBtnClick = () => {
        if (userTypeLocalState === UserType.NON_PROFILED_CONSULTANT) {
            navigate(CONSULTANT_CREATE_PROFILE_PAGE.url);
        } else if (userTypeLocalState === UserType.CLIENT) {
            navigate(CLIENT_BASE_PATH);
        } else if (userTypeLocalState === UserType.CONSULTANT) {
            navigate(CONSULTANT_JOB_SEARCH_PAGE.url);
        }
    };

    userTypeLocalState === UserType.CLIENT ? CLIENT_NAV_MENU_ITEMS : CONSULTANT_NAV_MENU_ITEMS;
    return (
        <Box px={"4vw"} py={2}>
            <Flex
                color={useColorModeValue("gray.600", "white")}
                backgroundBlendMode="inherit"
                minH={"60px"}
                borderBottom={1}
                borderStyle={"none"}
                borderColor={useColorModeValue("gray.200", "gray.900")}
                align={"center"}
            >
                <Flex flex={{ base: 1 }} justify={{ base: "start" }} alignItems={{ base: "center" }}>
                    <IconButton
                        icon={<BrandLogo />}
                        variant={"unstyled"}
                        aria-label={"Logo icon"}
                        w={10}
                        h={10}
                        onClick={handleHomeBtnClick}
                    />
                    <Text
                        textAlign={useBreakpointValue({ base: "center", md: "left" })}
                        fontFamily={`brand`}
                        fontSize="xl"
                        ml={4}
                        fontWeight={"normal"}
                        color={useColorModeValue("gray.800", "white")}
                        h="full"
                        textColor={"gray.700"}
                    >
                        {t("brandName", { ns: "common" })}
                    </Text>
                </Flex>

                <Flex
                    flex={{ base: 1, md: "auto" }}
                    display={{ base: "flex" }}
                    justifyContent={"flex-end"}
                    alignItems="center"
                >
                    <IconButton
                        icon={<SvgIconWrapper w={7} h={7} svgComp={<NotificationIcon />} />}
                        isRound
                        variant={"ghost"}
                        boxShadow="md"
                        size="lg"
                        aria-label={"Toggle Navigation"}
                        mr={8}
                    />
                    <Menu>
                        {({ isOpen }) => (
                            <>
                                <MenuButton
                                    isActive={isOpen}
                                    as={IconButton}
                                    icon={<SvgIconWrapper svgComp={<HandBurgerMenuIcon />} />}
                                    variant="ghost"
                                    p={2}
                                />
                                <MenuList mt={2}>
                                    {NAV_ITEMS.map((_navItem) => (
                                        <MenuItem
                                            key={_navItem.label}
                                            py={3}
                                            _hover={{ bg: "brand.100" }}
                                            onClick={_navItem.onClick}
                                        >
                                            {_navItem.icon && <SvgIconWrapper svgComp={_navItem.icon} w={4} mr={2} />}
                                            <Text fontWeight={"semibold"} fontSize="xs">
                                                {_navItem.label}
                                            </Text>
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </>
                        )}
                    </Menu>
                </Flex>
            </Flex>
        </Box>
    );
}
