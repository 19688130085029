import React, { ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useLocalStorage } from "react-use";
import ScrollToTop from "./components/global/ScrollToTop/ScrollToTop-component";
import { USER_TYPE_LOCAL_STORAGE_KEY } from "./constants/Auth-constants";
import { UserType } from "./constants/Enum-constants";
import {
    CLIENT_CONSULTANT_SEARCH_PAGE,
    CLIENT_INTERVIEW_REQ_PAGE,
    CLIENT_JOB_DESCRIPTIONS_PAGE,
    CLIENT_JOB_DETAILS_PAGE,
    CONSULTANT_INTERVIEW_APPLNS_PAGE,
    CONSULTANT_JOB_SEARCH_PAGE,
    CONSULTANT_CREATE_PROFILE_PAGE,
    CONSULTANT_EDIT_PROFILE_PAGE,
    CONSULTANT_PROFILE_VIEW_PAGE,
    CLIENT_LOGIN_PAGE,
    CONSULTANT_LOGIN_PAGE,
} from "./constants/WebAppRoute-constants";
import Login from "./pages/authentication/login/Login-component";
import ConsultantSearchPage from "./pages/client-pages/consultant-search/ConsultantSearchPage";
import CreateJobDescription from "./pages/client-pages/create-job-description/CreateJobDescription";
import EditJobDescription from "./pages/client-pages/edit-job-description/EditJobDescription";
import IndividualJobDetailsPage from "./pages/client-pages/individual-job-page/IndividualJobDetailsPage";
import InterviewRequestsPage from "./pages/client-pages/interview-requests-page/InterviewRequestsPage";
import JobDescriptionList from "./pages/client-pages/job-description-list/JobDescriptionList";
import ConsultantProfile from "./pages/consultant-pages/consultant-profile-view-page/ConsultantProfile-component";
import ConsultantAppliedJob from "./pages/consultant-pages/ConsultantAppliedJob-component";
import CreateConsultantProfile from "./pages/consultant-pages/create-consultant-profile-page/CreateConsultantProfile-component";
import EditConsultantProfile from "./pages/consultant-pages/edit-consultant-profile-page/EditConsultantProfile-component";
import ConsultantJobSearchList from "./pages/consultant-pages/job-list-page/ConsultantJobSearchList";
import PageNotFound from "./pages/PageNotFound-page";
import "./styles/App.css";

function App() {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchInterval: 30000,
                refetchOnWindowFocus: true,
            },
        },
    });

    return (
        <div className="App">
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <ScrollToTop />
                    <Routes>
                        <Route
                            path={CLIENT_JOB_DESCRIPTIONS_PAGE.reactRouteWithPathParams}
                            element={
                                <ProtectedRoute accessibleUserType={UserType.CLIENT} element={<JobDescriptionList />} />
                            }
                        />
                        <Route path="/" element={<Login />} />
                        <Route path={CLIENT_LOGIN_PAGE.reactRouteWithPathParams} element={<Login />} />
                        <Route path={CONSULTANT_LOGIN_PAGE.reactRouteWithPathParams} element={<Login />} />
                        <Route
                            path="/create-job-description"
                            element={
                                <ProtectedRoute
                                    accessibleUserType={UserType.CLIENT}
                                    element={<CreateJobDescription />}
                                />
                            }
                        />
                        <Route
                            path="/edit-job-description/:jobDescriptionId"
                            element={
                                <ProtectedRoute accessibleUserType={UserType.CLIENT} element={<EditJobDescription />} />
                            }
                        />
                        <Route
                            path={CLIENT_INTERVIEW_REQ_PAGE.reactRouteWithPathParams}
                            element={
                                <ProtectedRoute
                                    accessibleUserType={UserType.CLIENT}
                                    element={<InterviewRequestsPage />}
                                />
                            }
                        />
                        <Route
                            path={CLIENT_CONSULTANT_SEARCH_PAGE.reactRouteWithPathParams}
                            element={
                                <ProtectedRoute
                                    accessibleUserType={UserType.CLIENT}
                                    element={<ConsultantSearchPage />}
                                />
                            }
                        />
                        <Route
                            path={CLIENT_JOB_DETAILS_PAGE.reactRouteWithPathParams}
                            element={
                                <ProtectedRoute
                                    accessibleUserType={UserType.CLIENT}
                                    element={<IndividualJobDetailsPage />}
                                />
                            }
                        />
                        <Route
                            path={CONSULTANT_JOB_SEARCH_PAGE.reactRouteWithPathParams}
                            element={
                                <ProtectedRoute
                                    accessibleUserType={UserType.CONSULTANT}
                                    element={<ConsultantJobSearchList />}
                                />
                            }
                        />
                        <Route
                            path={CONSULTANT_INTERVIEW_APPLNS_PAGE.reactRouteWithPathParams}
                            element={
                                <ProtectedRoute
                                    accessibleUserType={UserType.CONSULTANT}
                                    element={<ConsultantAppliedJob />}
                                />
                            }
                        />
                        <Route
                            path={CONSULTANT_PROFILE_VIEW_PAGE.reactRouteWithPathParams}
                            element={
                                <ProtectedRoute
                                    accessibleUserType={UserType.CONSULTANT}
                                    element={<ConsultantProfile />}
                                />
                            }
                        />
                        <Route
                            path={CONSULTANT_CREATE_PROFILE_PAGE.reactRouteWithPathParams}
                            element={
                                <ProtectedRoute
                                    accessibleUserType={UserType.NON_PROFILED_CONSULTANT}
                                    element={<CreateConsultantProfile />}
                                />
                            }
                        />
                        <Route
                            path={CONSULTANT_EDIT_PROFILE_PAGE.reactRouteWithPathParams}
                            element={
                                <ProtectedRoute
                                    accessibleUserType={UserType.CONSULTANT}
                                    element={<EditConsultantProfile />}
                                />
                            }
                        />
                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                </BrowserRouter>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </div>
    );
}

/**
 * Doc of reference: https://namespaceit.com/blog/error-privateroute-is-not-a-route-component-all-component-children-of-routes-must-be-a-route-or-reactfragment
 */
interface ProtectedRoutePropInf {
    accessibleUserType: string | null;
    element: ReactNode;
}
const ProtectedRoute = ({ accessibleUserType, element }: ProtectedRoutePropInf) => {
    const [userTypeLocalState] = useLocalStorage(USER_TYPE_LOCAL_STORAGE_KEY);

    return accessibleUserType === userTypeLocalState ? <>{element}</> : <PageNotFound />;
};

export default App;
