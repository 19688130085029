import React from "react";

export interface SearchIconInf {
    width?: string;
    height?: string;
    color?: string;
    stroke?: string;
}

const SearchIcon = ({ width = "15", height = "15", stroke = "#E1E1E1" }: SearchIconInf) => {
    return (
        <svg width={width} height={height} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.9997 13.9999L11.009 11.0039L13.9997 13.9999ZM12.6663 6.99992C12.6663 8.50281 12.0693 9.94415 11.0066 11.0069C9.94391 12.0696 8.50257 12.6666 6.99967 12.6666C5.49678 12.6666 4.05544 12.0696 2.99274 11.0069C1.93003 9.94415 1.33301 8.50281 1.33301 6.99992C1.33301 5.49703 1.93003 4.05569 2.99274 2.99298C4.05544 1.93027 5.49678 1.33325 6.99967 1.33325C8.50257 1.33325 9.94391 1.93027 11.0066 2.99298C12.0693 4.05569 12.6663 5.49703 12.6663 6.99992V6.99992Z"
                stroke={stroke}
                strokeLinecap="round"
            />
        </svg>
    );
};

export default SearchIcon;
