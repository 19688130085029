// TODO @hari-anitha : Need to use i18n translation here.
import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
    const navigate = useNavigate();
    const handleHomeBtnClick = () => {
        navigate(`/`);
    };
    return (
        <Flex textAlign="center" minH={"100vh"} justifyContent="center" alignItems={"center"} flexDirection="column">
            <Heading
                display="inline-block"
                as="h2"
                size="2xl"
                bgGradient="linear(to-r, brand.400, brand.600)"
                backgroundClip="text"
            >
                404
            </Heading>
            <Text fontSize="18px" mt={3} mb={2}>
                Page Not Found
            </Text>
            <Text color={"gray.500"} mb={6}>
                The page you are looking for does not seem to exist
            </Text>
            <Flex>
                <Button colorScheme={"blue"} onClick={handleHomeBtnClick}>
                    Go to Home
                </Button>
            </Flex>
        </Flex>
    );
};

export default PageNotFound;
