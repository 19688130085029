import React from "react";
import { Button, Flex, Icon, Text } from "@chakra-ui/react";
import { FaChevronRight } from "react-icons/fa6";

interface IDemoAccountBtn {
    mainText: string;
    subText: string;
    onClick: CallableFunction;
}
const DemoAccountBtn = (props: IDemoAccountBtn) => {
    const { mainText, subText, onClick } = props;
    return (
        <Button
            py={8}
            variant={"outline"}
            w="full"
            colorScheme="gray"
            rightIcon={<Icon as={FaChevronRight} />}
            justifyContent={"space-between"}
            display={"flex"}
            color="white"
            _hover={{ color: "gray.800", bg: "white" }}
            onClick={onClick}
        >
            <Flex direction={"column"} alignItems={"start"} gap={1}>
                <Text>{mainText}</Text>
                <Text fontSize={"xx-small"} fontWeight={"normal"}>
                    {subText}
                </Text>
            </Flex>
        </Button>
    );
};

export default DemoAccountBtn;
