import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Input,
    Stack,
    Text,
    VStack,
} from "@chakra-ui/react";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LoginFormInf, LoginFormPropsInf } from "../../../@types/login.types";

const LoginForm = ({ onFormSubmit, isLoading = false }: LoginFormPropsInf) => {
    const { t } = useTranslation("loginForm");

    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting },
    } = useForm<LoginFormInf>({
        mode: "onBlur",
        defaultValues: {},
    });

    const onSubmit: SubmitHandler<LoginFormInf> = async (formData) => {
        onFormSubmit(formData);
    };

    return (
        <HStack minH={"100vh"} alignItems={"center"} justifyContent={"space-between"} wrap={"wrap"}>
            <VStack spacing={8} m={4} width={{ base: "full" }}>
                <Box
                    borderWidth={1}
                    borderColor={"gray.200"}
                    borderRadius={"2xl"}
                    width={{ base: "full", md: "lg" }}
                    p={12}
                    m={4}
                    minH={"full"}
                >
                    <HStack justify="center" mb={10}>
                        <Text fontWeight={"bold"} fontSize={"xl"}>
                            {t("title")}
                        </Text>
                    </HStack>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing="6">
                            <FormControl id="subscriberEmail" isInvalid={!!errors.subscriberEmail}>
                                <FormLabel>{t("form.field1.label")}</FormLabel>
                                <Input
                                    {...register("subscriberEmail", {
                                        required: {
                                            value: true,
                                            message: t("form.field1.requiredPromptText"),
                                        },
                                    })}
                                    type={"email"}
                                    placeholder={t("form.field1.placeholder")}
                                />
                                <FormErrorMessage>{errors.subscriberEmail?.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl id="subscriberPwd" isInvalid={!!errors.subscriberPwd}>
                                <FormLabel>{t("form.field3.label")}</FormLabel>
                                <Input
                                    {...register("subscriberPwd", {
                                        required: {
                                            value: true,
                                            message: t("form.field3.requiredPromptText"),
                                        },
                                    })}
                                    type={"password"}
                                    placeholder={t("form.field3.placeholder")}
                                />
                                <FormErrorMessage>{errors.subscriberPwd?.message}</FormErrorMessage>
                            </FormControl>

                            {/* TODO: @Umesh: Naviage to reset password page */}
                            {/* <HStack justify="flex-end">
                                <Button variant="link" colorScheme="brand" color={"brand.800"} size="sm">
                                    {t("form.forgotPassword")}
                                </Button>
                            </HStack> */}
                            <Button
                                colorScheme="brand"
                                size="lg"
                                fontSize="md"
                                isLoading={isLoading || isSubmitting}
                                bg={"brand.800"}
                                _hover={{
                                    background: "brand.700",
                                }}
                                type="submit"
                                mt={"20 !important"}
                            >
                                {t("form.submitBtnText")}
                            </Button>
                        </Stack>
                    </form>
                </Box>
                {/* TODO: @Umesh: Naviage to signup page */}
                <HStack spacing="1" justify="center">
                    <Text color="muted">{t("newAccount.title1")}</Text>
                    <Button variant="link" colorScheme="brand" color={"brand.800"}>
                        {t("newAccount.title2")}
                    </Button>
                </HStack>
            </VStack>
        </HStack>
    );
};
export default LoginForm;
