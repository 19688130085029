import { CalendarIcon, AddIcon, DeleteIcon } from "@chakra-ui/icons";
import {
    Button,
    FormControl,
    FormLabel,
    GridItem,
    Input,
    SimpleGrid,
    VStack,
    useBreakpointValue,
    Textarea,
    Flex,
    IconButton,
    FormErrorMessage,
    Box,
    Text,
    Spinner,
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    InputGroup,
    InputRightElement,
    forwardRef,
    Checkbox,
    HStack,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { prepareConsultantProfileFormData } from "./ConsultantProfile.helper";
import { ConsultantProfileFormInf, ConsultantProfileFormPropsInf } from "../../../@types/consultantProfileForm.types";
import {
    consultantCurrentStatusOption,
    consutantGraduationLevelOption,
    countryNameOption,
    hobbiesOption,
    languageOption,
    prefectureOption,
    skillLevelOption,
} from "../../../constants/ConsultantDropdown-options";
import CustomMultiSelectDropdown from "../../global/CustomMultiSelectDropdown.tsx/CustomMultiSelectDropdown-component";
import SvgIconWrapper from "../../global/SvgIconWrapper/SvgIconWrapper.component";
import "react-datepicker/dist/react-datepicker.css";

const ConsultantProfileForm = ({ onFormSubmit, isLoading, data }: ConsultantProfileFormPropsInf) => {
    const { t } = useTranslation("consultantProfileForm");
    const {
        control,
        register,
        handleSubmit,
        reset,
        getValues,
        formState: { errors },
    } = useForm<ConsultantProfileFormInf>({
        mode: "onBlur",
        defaultValues: {
            // TODO: @umesh Remove below three fields default values once the file upload feature is added
            consultantAvatar: "",
            consultantProfilePic: "",
            consultantResume: "",
            consultantEducations: [],
            consultantWorkExperiences: [],
            consultantCertifications: [],
            consultantSkills: [],
            consultantLanguages: [],
        },
    });

    const {
        fields: educationFields,
        append: educationAppend,
        remove: educationRemove,
        // @ts-ignore
    } = useFieldArray({ control, name: "consultantEducations" });
    const {
        fields: workExperienceFields,
        append: workExperienceAppend,
        remove: workExperienceRemove,
    } = useFieldArray({ control, name: "consultantWorkExperiences" });
    const {
        fields: certificationFields,
        append: certificationAppend,
        remove: certificationRemove,
    } = useFieldArray({ control, name: "consultantCertifications" });
    const {
        fields: skillFields,
        append: skillAppend,
        remove: skillRemove,
    } = useFieldArray({ control, name: "consultantSkills" });
    const {
        fields: languageFields,
        append: languageAppend,
        remove: languageRemove,
    } = useFieldArray({ control, name: "consultantLanguages" });

    const colSpanType2 = useBreakpointValue({ base: 10, lg: 4 });
    const colSpanType3 = useBreakpointValue({ base: 10, lg: 12 });

    const onSubmit = (data: ConsultantProfileFormInf) => {
        onFormSubmit(data);
    };

    useEffect(() => {
        if (data) {
            // TODO - Umesh: Update user deatils for dynamic form fields
            reset(prepareConsultantProfileFormData(data));
        }
    }, [data, reset]);

    const CustomDatePickerField = forwardRef(({ value, onClick, onBlur, onChange, placeholder }) => {
        return (
            <InputGroup h={10} border={"1px solid"} borderColor={"gray.200"} borderRadius={"base"}>
                <Input
                    onClick={onClick}
                    value={value}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    bg="white"
                    borderStyle="none"
                    onChange={onChange}
                />
                <InputRightElement onClick={onClick} cursor="pointer">
                    <IconButton
                        icon={<SvgIconWrapper svgComp={<CalendarIcon />} />}
                        aria-label="date-picker"
                        colorScheme={"brand"}
                        bg={"brand.800"}
                        w={8}
                        h={8}
                        mr={2}
                    />
                </InputRightElement>
            </InputGroup>
        );
    });

    if (isLoading) {
        return (
            <Flex alignItems={"center"} justifyContent={"center"} h="100vh" my={12}>
                <Spinner color="brand.500" thickness="4px" size={{ base: "sm", md: "md", lg: "lg" }} />
            </Flex>
        );
    }

    return (
        <VStack w="full" h="full" p={10} spacing={10} alignItems="flex-start" px={"4vw"}>
            <Box width={"full"}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <SimpleGrid
                        templateColumns="repeat(12, 1fr)"
                        columnGap={16}
                        rowGap={12}
                        w="full"
                        // maxWidth="container.xl"
                        display={["flex", "flex", "grid"]}
                        flexDirection={"column"}
                    >
                        <GridItem colSpan={colSpanType2} maxWidth={["96", "100%", "100%"]}>
                            <FormControl isInvalid={!!errors?.consultantNickName?.message}>
                                <FormLabel fontFamily={"special"} fontWeight={700} marginBottom={4}>
                                    {t("form.field1.label")} :
                                </FormLabel>
                                <Input
                                    placeholder={t("form.field1.placeholder")}
                                    {...register("consultantNickName", {
                                        required: {
                                            value: true,
                                            message: t("form.field1.requiredPromptText"),
                                        },
                                    })}
                                />

                                <FormErrorMessage fontSize={"xs"}>
                                    {errors?.consultantNickName?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>

                        <GridItem colSpan={colSpanType2} maxWidth={["96", "100%", "100%"]}>
                            <FormControl isInvalid={!!errors?.consultantFirstName?.message}>
                                <FormLabel fontFamily={"special"} fontWeight={700} marginBottom={4}>
                                    {t("form.field2.label")} :
                                </FormLabel>
                                <Input
                                    placeholder={t("form.field2.placeholder")}
                                    {...register("consultantFirstName", {
                                        required: {
                                            value: true,
                                            message: t("form.field2.requiredPromptText"),
                                        },
                                    })}
                                />

                                <FormErrorMessage fontSize={"xs"}>
                                    {errors?.consultantFirstName?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>

                        <GridItem colSpan={colSpanType2} maxWidth={["96", "100%", "100%"]}>
                            <FormControl isInvalid={!!errors?.consultantLastName?.message}>
                                <FormLabel fontFamily={"special"} fontWeight={700} marginBottom={4}>
                                    {t("form.field3.label")} :
                                </FormLabel>
                                <Input
                                    placeholder={t("form.field3.placeholder")}
                                    {...register("consultantLastName", {
                                        required: {
                                            value: true,
                                            message: t("form.field3.requiredPromptText"),
                                        },
                                    })}
                                />
                                <FormErrorMessage fontSize={"xs"}>
                                    {errors?.consultantLastName?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>

                        {/* TODO: @umesh: Make this field as a file uploader */}
                        {/* <GridItem colSpan={colSpanType2} maxWidth={["96", "100%", "100%"]}>
                            <FormControl isInvalid={!!errors?.consultantAvatar?.message}>
                                <FormLabel fontFamily={"special"} fontWeight={700} marginBottom={4}>
                                    {t("form.field4.label")} :
                                </FormLabel>
                                <Input
                                    placeholder={t("form.field4.placeholder")}
                                    {...register("consultantAvatar", {
                                        required: {
                                            value: true,
                                            message: t("form.field4.requiredPromptText"),
                                        },
                                    })}
                                />

                                <FormErrorMessage fontSize={"xs"}>
                                    {errors?.consultantAvatar?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem> */}

                        {/* TODO: @umesh: Make this field as a file uploader */}
                        {/* <GridItem colSpan={colSpanType2} maxWidth={["96", "100%", "100%"]}>
                            <FormControl isInvalid={!!errors?.consultantProfilePic?.message}>
                                <FormLabel fontFamily={"special"} fontWeight={700} marginBottom={4}>
                                    {t("form.field4.label")} :
                                </FormLabel>
                                <Input
                                    placeholder={t("form.field4.placeholder")}
                                    {...register("consultantProfilePic", {
                                        required: {
                                            value: true,
                                            message: t("form.field4.requiredPromptText"),
                                        },
                                    })}
                                />

                                <FormErrorMessage fontSize={"xs"}>
                                    {errors?.consultantProfilePic?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem> */}
                        <GridItem colSpan={colSpanType2} maxWidth={["96", "100%", "100%"]}>
                            <Controller
                                control={control}
                                name="consultantCountry"
                                rules={{ required: t("form.field5.requiredPromptText") }}
                                render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
                                    <FormControl isInvalid={!!error?.message} id="consultantCountry">
                                        <FormLabel fontFamily={"special"} fontWeight={700}>
                                            {t("form.field5.label")} :
                                        </FormLabel>
                                        <CustomMultiSelectDropdown
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            options={countryNameOption}
                                            placeholder={t("form.field5.placeholder")}
                                            isMulti={false}
                                        />
                                        <FormErrorMessage fontSize={"xs"}>{error && error.message}</FormErrorMessage>
                                    </FormControl>
                                )}
                            />
                        </GridItem>
                        <GridItem colSpan={colSpanType2} maxWidth={["96", "100%", "100%"]}>
                            <Controller
                                control={control}
                                {...register("consultantPrefecture", {
                                    required: {
                                        value: false,
                                        message: t("form.field6.requiredPromptText"),
                                    },
                                })}
                                render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
                                    <FormControl isInvalid={!!error?.message} id="languages">
                                        <FormLabel fontFamily={"special"} fontWeight={700}>
                                            {t("form.field6.label")} :
                                        </FormLabel>
                                        <CustomMultiSelectDropdown
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            options={prefectureOption}
                                            placeholder={t("form.field6.placeholder")}
                                            isMulti={false}
                                        />
                                        <FormErrorMessage fontSize={"xs"}>{error && error.message}</FormErrorMessage>
                                    </FormControl>
                                )}
                            />
                        </GridItem>
                        <GridItem colSpan={colSpanType2} maxWidth={["96", "100%", "100%"]}>
                            <Controller
                                control={control}
                                {...register("consultantExperienceYears", {
                                    required: {
                                        value: false,
                                        message: t("form.field7.requiredPromptText"),
                                    },
                                })}
                                render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                                    <FormControl isInvalid={!!error?.message} id="consultantExperienceYears">
                                        <FormLabel fontFamily={"special"} fontWeight={700}>
                                            {t("form.field7.label")} :
                                        </FormLabel>
                                        <NumberInput min={0} max={100} {...rest}>
                                            <NumberInputField placeholder={t("form.field7.placeholder")} ref={ref} />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                        <FormErrorMessage fontSize={"xs"}>{error && error?.message}</FormErrorMessage>
                                    </FormControl>
                                )}
                            />
                        </GridItem>
                        <GridItem colSpan={colSpanType2} maxWidth={["96", "100%", "100%"]}>
                            <FormControl isInvalid={!!errors?.consultantCurrentCompany?.message}>
                                <FormLabel>{t("form.field8.label")} :</FormLabel>
                                <Input
                                    placeholder={t("form.field8.placeholder")}
                                    {...register("consultantCurrentCompany", {
                                        required: {
                                            value: false,
                                            message: t("form.field8.requiredPromptText"),
                                        },
                                    })}
                                />
                                <FormErrorMessage fontSize={"xs"}>
                                    {errors?.consultantCurrentCompany?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={colSpanType2} maxWidth={["96", "100%", "100%"]}>
                            <FormControl isInvalid={!!errors?.consultantCurrentRole?.message}>
                                <FormLabel>{t("form.field9.label")} :</FormLabel>
                                <Input
                                    placeholder={t("form.field9.placeholder")}
                                    {...register("consultantCurrentRole", {
                                        required: {
                                            value: false,
                                            message: t("form.field9.requiredPromptText"),
                                        },
                                    })}
                                />
                                <FormErrorMessage fontSize={"xs"}>
                                    {errors?.consultantCurrentRole?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={colSpanType2} maxWidth={["96", "100%", "100%"]}>
                            <FormControl isInvalid={!!errors?.consultantCurrentProject?.message}>
                                <FormLabel>{t("form.field10.label")} :</FormLabel>
                                <Input
                                    placeholder={t("form.field10.placeholder")}
                                    {...register("consultantCurrentProject", {
                                        required: {
                                            value: false,
                                            message: t("form.field10.requiredPromptText"),
                                        },
                                    })}
                                />
                                <FormErrorMessage fontSize={"xs"}>
                                    {errors?.consultantCurrentProject?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>

                        <GridItem colSpan={colSpanType2} maxWidth={["96", "100%", "100%"]}>
                            <Controller
                                {...register("consultantCurrentProjectStart", {
                                    required: { value: false, message: t("form.field11.requiredPromptText") },
                                })}
                                control={control}
                                render={({ field: { value, onChange, onBlur, ref, name }, fieldState: { error } }) => (
                                    <FormControl isInvalid={!!error?.message}>
                                        <FormLabel>{t("form.field11.label")} :</FormLabel>
                                        <DatePicker
                                            name={name}
                                            ref={ref}
                                            dateFormat="yyyy-MM-dd"
                                            selected={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selectsStart
                                            placeholderText={t("form.field11.placeholder")}
                                            autoComplete="off"
                                            customInput={<CustomDatePickerField />}
                                            startDate={getValues("consultantCurrentProjectStart") || null}
                                            endDate={getValues("consultantCurrentProjectEnd") || null}
                                            maxDate={getValues("consultantCurrentProjectEnd") || null}
                                            className={error?.message ? "error" : ""}
                                            showYearDropdown
                                            scrollableYearDropdown
                                        />
                                        <FormErrorMessage fontSize={"xs"} mt={4} ml={2}>
                                            {error?.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            />
                        </GridItem>

                        <GridItem colSpan={colSpanType2} maxWidth={["96", "100%", "100%"]}>
                            <Controller
                                {...register("consultantCurrentProjectEnd", {
                                    required: { value: false, message: t("form.field12.requiredPromptText") },
                                })}
                                control={control}
                                render={({ field: { onChange, onBlur, value, ref, name }, fieldState: { error } }) => (
                                    <FormControl isInvalid={!!error?.message}>
                                        <FormLabel>{t("form.field12.label")} :</FormLabel>
                                        <DatePicker
                                            name={name}
                                            ref={ref}
                                            dateFormat="yyyy-MM-dd"
                                            selected={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selectsEnd
                                            placeholderText={t("form.field12.placeholder")}
                                            autoComplete="off"
                                            customInput={<CustomDatePickerField />}
                                            startDate={getValues("consultantCurrentProjectStart") || null}
                                            endDate={getValues("consultantCurrentProjectEnd") || null}
                                            minDate={getValues("consultantCurrentProjectStart") || null}
                                            showYearDropdown
                                            scrollableYearDropdown
                                        />
                                        <FormErrorMessage fontSize={"xs"} mt={4} ml={2}>
                                            {error?.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            />
                        </GridItem>

                        <GridItem colSpan={colSpanType2} maxWidth={["96", "100%", "100%"]}>
                            <Controller
                                control={control}
                                rules={{ required: t("form.field13.requiredPromptText") }}
                                {...register("consultantCurrentStatus", {
                                    required: { value: false, message: t("form.field13.requiredPromptText") },
                                })}
                                render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
                                    <FormControl isInvalid={!!error?.message} id="consultantCurrentStatus">
                                        <FormLabel fontFamily={"special"} fontWeight={700}>
                                            {t("form.field13.label")} :
                                        </FormLabel>
                                        <CustomMultiSelectDropdown
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            options={consultantCurrentStatusOption}
                                            placeholder={t("form.field13.placeholder")}
                                            isMulti={false}
                                        />
                                        <FormErrorMessage fontSize={"xs"}>{error && error.message}</FormErrorMessage>
                                    </FormControl>
                                )}
                            />
                        </GridItem>
                        <GridItem colSpan={colSpanType2} maxWidth={["96", "100%", "100%"]}>
                            <Controller
                                control={control}
                                {...register("consultantHobbies", {
                                    required: { value: false, message: t("form.field14.requiredPromptText") },
                                })}
                                render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
                                    <FormControl isInvalid={!!error?.message} id="consultantHobbies">
                                        <FormLabel fontFamily={"special"} fontWeight={700}>
                                            {t("form.field14.label")} :
                                        </FormLabel>
                                        <CustomMultiSelectDropdown
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            options={hobbiesOption}
                                            placeholder={t("form.field14.placeholder")}
                                        />
                                        <FormErrorMessage fontSize={"xs"}>{error && error.message}</FormErrorMessage>
                                    </FormControl>
                                )}
                            />
                        </GridItem>

                        {/* TODO: @umesh: Make this field as a file uploader */}
                        {/* <GridItem colSpan={colSpanType2} maxWidth={["96", "100%", "100%"]}>
                            <FormControl isInvalid={!!errors?.consultantResume?.message}>
                                <FormLabel fontFamily={"special"} fontWeight={700} marginBottom={4}>
                                    {t("form.field15.label")} :
                                </FormLabel>
                                <Input
                                    placeholder={t("form.field15.placeholder")}
                                    size={"lg"}
                                    {...register("consultantResume", {
                                        required: {
                                            value: false,
                                            message: t("form.field15.requiredPromptText"),
                                        },
                                    })}
                                />
                                <FormErrorMessage fontSize={"xs"}>{errors?.consultantResume?.message}</FormErrorMessage>
                            </FormControl>
                        </GridItem> */}

                        {/* Consultant education related info*/}
                        <GridItem colSpan={colSpanType3} colStart={1}>
                            <Box borderColor={"gray.200"} borderRadius="xl" borderWidth={1} p={4}>
                                <Flex align={"center"} justifyContent={"space-between"} mb={4}>
                                    <Text fontWeight={"semibold"} fontSize={"lg"}>
                                        {t("educationInfo")}
                                    </Text>
                                    <Button
                                        leftIcon={<AddIcon />}
                                        onClick={() => {
                                            educationAppend({});
                                        }}
                                    >
                                        {t("addEducation")}
                                    </Button>
                                </Flex>
                                <Accordion allowMultiple>
                                    {educationFields.map((item, index) => {
                                        return (
                                            <>
                                                <AccordionItem>
                                                    <h2>
                                                        <AccordionButton>
                                                            <Box flex="1" textAlign="left">
                                                                {t("educationTitle")} {index + 1}
                                                            </Box>
                                                            <IconButton
                                                                // colorScheme=""
                                                                h={7}
                                                                w={7}
                                                                mr={4}
                                                                bg={"white"}
                                                                aria-label="Remove education"
                                                                icon={<DeleteIcon />}
                                                                onClick={(event) => {
                                                                    event.preventDefault();
                                                                    educationRemove(index);
                                                                }}
                                                            />

                                                            <AccordionIcon />
                                                        </AccordionButton>
                                                    </h2>
                                                    <AccordionPanel pb={24}>
                                                        <SimpleGrid
                                                            key={item.id}
                                                            templateColumns="repeat(12, 1fr)"
                                                            columnGap={16}
                                                            rowGap={12}
                                                            w="full"
                                                            // maxWidth="container.xl"
                                                            display={["flex", "flex", "grid"]}
                                                            flexDirection={"column"}
                                                        >
                                                            <GridItem
                                                                colSpan={colSpanType2}
                                                                maxWidth={["96", "100%", "100%"]}
                                                            >
                                                                <FormControl
                                                                    isInvalid={
                                                                        !!errors?.consultantEducations?.[index]
                                                                            ?.educationCoursename?.message
                                                                    }
                                                                >
                                                                    <FormLabel>{t("form.field16.label")} :</FormLabel>
                                                                    <Input
                                                                        placeholder={t("form.field16.placeholder")}
                                                                        {...register(
                                                                            `consultantEducations.${index}.educationCoursename` as const,
                                                                            {
                                                                                required: {
                                                                                    value: true,
                                                                                    message: t(
                                                                                        "form.field16.requiredPromptText",
                                                                                    ),
                                                                                },
                                                                            },
                                                                        )}
                                                                    />
                                                                    <FormErrorMessage fontSize={"xs"}>
                                                                        {
                                                                            errors?.consultantEducations?.[index]
                                                                                ?.educationCoursename?.message
                                                                        }
                                                                    </FormErrorMessage>
                                                                </FormControl>
                                                            </GridItem>
                                                            <GridItem
                                                                colSpan={colSpanType2}
                                                                maxWidth={["96", "100%", "100%"]}
                                                            >
                                                                <Controller
                                                                    {...register(
                                                                        `consultantEducations.${index}.educationCourseStartyear` as const,
                                                                        {
                                                                            required: t(
                                                                                "form.field17.requiredPromptText",
                                                                            ),
                                                                        },
                                                                    )}
                                                                    control={control}
                                                                    render={({
                                                                        field: { value, onChange, onBlur, ref, name },
                                                                        fieldState: { error },
                                                                    }) => (
                                                                        <FormControl isInvalid={!!error?.message}>
                                                                            <FormLabel>
                                                                                {t("form.field17.label")} :
                                                                            </FormLabel>
                                                                            <DatePicker
                                                                                name={name}
                                                                                ref={ref}
                                                                                dateFormat="yyyy-MM-dd"
                                                                                selected={value}
                                                                                onChange={onChange}
                                                                                onBlur={onBlur}
                                                                                selectsStart
                                                                                placeholderText={t(
                                                                                    "form.field11.placeholder",
                                                                                )}
                                                                                autoComplete="off"
                                                                                customInput={<CustomDatePickerField />}
                                                                                startDate={
                                                                                    getValues(
                                                                                        `consultantEducations.${index}.educationCourseStartyear`,
                                                                                    ) || null
                                                                                }
                                                                                endDate={
                                                                                    getValues(
                                                                                        `consultantEducations.${index}.educationCourseEndyear`,
                                                                                    ) || null
                                                                                }
                                                                                maxDate={
                                                                                    getValues(
                                                                                        `consultantEducations.${index}.educationCourseEndyear`,
                                                                                    ) || null
                                                                                }
                                                                                className={
                                                                                    error?.message ? "error" : ""
                                                                                }
                                                                                showYearDropdown
                                                                                scrollableYearDropdown
                                                                            />
                                                                            <FormErrorMessage
                                                                                fontSize={"xs"}
                                                                                mt={4}
                                                                                ml={2}
                                                                            >
                                                                                {error?.message}
                                                                            </FormErrorMessage>
                                                                        </FormControl>
                                                                    )}
                                                                />
                                                            </GridItem>

                                                            <GridItem
                                                                colSpan={colSpanType2}
                                                                maxWidth={["96", "100%", "100%"]}
                                                            >
                                                                <Controller
                                                                    {...register(
                                                                        `consultantEducations.${index}.educationCourseEndyear` as const,
                                                                        {
                                                                            required: t(
                                                                                "form.field17.requiredPromptText",
                                                                            ),
                                                                        },
                                                                    )}
                                                                    control={control}
                                                                    render={({
                                                                        field: { onChange, onBlur, value, ref, name },
                                                                        fieldState: { error },
                                                                    }) => (
                                                                        <FormControl isInvalid={!!error?.message}>
                                                                            <FormLabel>
                                                                                {t("form.field18.label")} :
                                                                            </FormLabel>
                                                                            <DatePicker
                                                                                name={name}
                                                                                ref={ref}
                                                                                dateFormat="yyyy-MM-dd"
                                                                                selected={value}
                                                                                onChange={onChange}
                                                                                onBlur={onBlur}
                                                                                selectsEnd
                                                                                placeholderText={t(
                                                                                    "form.field12.placeholder",
                                                                                )}
                                                                                autoComplete="off"
                                                                                customInput={<CustomDatePickerField />}
                                                                                startDate={
                                                                                    getValues(
                                                                                        `consultantEducations.${index}.educationCourseStartyear`,
                                                                                    ) || null
                                                                                }
                                                                                endDate={getValues(
                                                                                    `consultantEducations.${index}.educationCourseEndyear`,
                                                                                )}
                                                                                minDate={
                                                                                    getValues(
                                                                                        `consultantEducations.${index}.educationCourseStartyear`,
                                                                                    ) || null
                                                                                }
                                                                                showYearDropdown
                                                                                scrollableYearDropdown
                                                                            />
                                                                            <FormErrorMessage
                                                                                fontSize={"xs"}
                                                                                mt={4}
                                                                                ml={2}
                                                                            >
                                                                                {error?.message}
                                                                            </FormErrorMessage>
                                                                        </FormControl>
                                                                    )}
                                                                />
                                                            </GridItem>

                                                            <GridItem
                                                                colSpan={colSpanType2}
                                                                maxWidth={["96", "100%", "100%"]}
                                                                key={item.id}
                                                            >
                                                                <FormControl
                                                                    isInvalid={
                                                                        !!errors?.consultantEducations?.[index]
                                                                            ?.educationInstitution?.message
                                                                    }
                                                                >
                                                                    <FormLabel>{t("form.field19.label")} :</FormLabel>
                                                                    <Input
                                                                        placeholder={t("form.field19.placeholder")}
                                                                        {...register(
                                                                            `consultantEducations.${index}.educationInstitution` as const,
                                                                            {
                                                                                required: {
                                                                                    value: true,
                                                                                    message: t(
                                                                                        "form.field19.requiredPromptText",
                                                                                    ),
                                                                                },
                                                                            },
                                                                        )}
                                                                    />
                                                                    <FormErrorMessage fontSize={"xs"}>
                                                                        {
                                                                            errors?.consultantEducations?.[index]
                                                                                ?.educationInstitution?.message
                                                                        }
                                                                    </FormErrorMessage>
                                                                </FormControl>
                                                            </GridItem>
                                                            <GridItem
                                                                colSpan={colSpanType2}
                                                                maxWidth={["96", "100%", "100%"]}
                                                            >
                                                                <Controller
                                                                    control={control}
                                                                    name={
                                                                        `consultantEducations.${index}.educationGraduationLevel` as const
                                                                    }
                                                                    rules={{
                                                                        required: t("form.field20.requiredPromptText"),
                                                                    }}
                                                                    render={({
                                                                        field: { onChange, onBlur, value, name },
                                                                        fieldState: { error },
                                                                    }) => (
                                                                        <FormControl
                                                                            isInvalid={!!error?.message}
                                                                            id="languages"
                                                                        >
                                                                            <FormLabel
                                                                                fontFamily={"special"}
                                                                                fontWeight={700}
                                                                            >
                                                                                {t("form.field20.label")} :
                                                                            </FormLabel>
                                                                            <CustomMultiSelectDropdown
                                                                                name={name}
                                                                                onChange={onChange}
                                                                                onBlur={onBlur}
                                                                                value={value}
                                                                                options={consutantGraduationLevelOption}
                                                                                placeholder={t(
                                                                                    "form.field20.placeholder",
                                                                                )}
                                                                                isMulti={false}
                                                                            />
                                                                            <FormErrorMessage fontSize={"xs"}>
                                                                                {error && error.message}
                                                                            </FormErrorMessage>
                                                                        </FormControl>
                                                                    )}
                                                                />
                                                            </GridItem>
                                                            <GridItem
                                                                colSpan={colSpanType2}
                                                                maxWidth={["96", "100%", "100%"]}
                                                                key={item.id}
                                                            >
                                                                <FormControl
                                                                    isInvalid={
                                                                        !!errors?.consultantEducations?.[index]
                                                                            ?.educationRemarks?.message
                                                                    }
                                                                >
                                                                    <FormLabel>{t("form.field21.label")} :</FormLabel>
                                                                    <Input
                                                                        placeholder={t("form.field21.placeholder")}
                                                                        {...register(
                                                                            `consultantEducations.${index}.educationRemarks` as const,
                                                                            {
                                                                                required: {
                                                                                    value: true,
                                                                                    message: t(
                                                                                        "form.field21.requiredPromptText",
                                                                                    ),
                                                                                },
                                                                            },
                                                                        )}
                                                                    />
                                                                    <FormErrorMessage fontSize={"xs"}>
                                                                        {
                                                                            errors?.consultantEducations?.[index]
                                                                                ?.educationRemarks?.message
                                                                        }
                                                                    </FormErrorMessage>
                                                                </FormControl>
                                                            </GridItem>
                                                        </SimpleGrid>
                                                    </AccordionPanel>
                                                </AccordionItem>
                                            </>
                                        );
                                    })}
                                </Accordion>
                            </Box>
                        </GridItem>

                        {/* Consultant work experience related info*/}
                        <GridItem colSpan={colSpanType3} colStart={1}>
                            <Box borderColor={"gray.200"} borderRadius="xl" borderWidth={1} p={4}>
                                <Flex align={"center"} justifyContent={"space-between"} mb={4}>
                                    <Text fontWeight={"semibold"} fontSize={"lg"}>
                                        {t("workExpInfo")}
                                    </Text>
                                    <Button
                                        leftIcon={<AddIcon />}
                                        onClick={() => {
                                            workExperienceAppend({});
                                        }}
                                    >
                                        {t("addWorkExperience")}
                                    </Button>
                                </Flex>
                                <Accordion allowMultiple>
                                    {workExperienceFields.map((item, index) => {
                                        return (
                                            <>
                                                <AccordionItem>
                                                    <h2>
                                                        <AccordionButton>
                                                            <Box flex="1" textAlign="left">
                                                                {t("workExpTitle")} {index + 1}
                                                            </Box>
                                                            <IconButton
                                                                // colorScheme=""
                                                                h={7}
                                                                w={7}
                                                                mr={4}
                                                                bg={"white"}
                                                                aria-label="Remove work experience"
                                                                icon={<DeleteIcon />}
                                                                onClick={(event) => {
                                                                    event.preventDefault();
                                                                    workExperienceRemove(index);
                                                                }}
                                                            />

                                                            <AccordionIcon />
                                                        </AccordionButton>
                                                    </h2>
                                                    <AccordionPanel pb={24}>
                                                        <SimpleGrid
                                                            key={item.id}
                                                            templateColumns="repeat(12, 1fr)"
                                                            columnGap={16}
                                                            rowGap={12}
                                                            w="full"
                                                            // maxWidth="container.xl"
                                                            display={["flex", "flex", "grid"]}
                                                            flexDirection={"column"}
                                                        >
                                                            <GridItem
                                                                colSpan={colSpanType2}
                                                                maxWidth={["96", "100%", "100%"]}
                                                            >
                                                                <FormControl
                                                                    isInvalid={
                                                                        !!errors?.consultantWorkExperiences?.[index]
                                                                            ?.workExperienceCompany?.message
                                                                    }
                                                                >
                                                                    <FormLabel>{t("form.field22.label")} :</FormLabel>
                                                                    <Input
                                                                        placeholder={t("form.field22.placeholder")}
                                                                        {...register(
                                                                            `consultantWorkExperiences.${index}.workExperienceCompany` as const,
                                                                            {
                                                                                required: {
                                                                                    value: false,
                                                                                    message: t(
                                                                                        "form.field22.requiredPromptText",
                                                                                    ),
                                                                                },
                                                                            },
                                                                        )}
                                                                    />
                                                                    <FormErrorMessage fontSize={"xs"}>
                                                                        {
                                                                            errors?.consultantWorkExperiences?.[index]
                                                                                ?.workExperienceCompany?.message
                                                                        }
                                                                    </FormErrorMessage>
                                                                </FormControl>
                                                            </GridItem>
                                                            <GridItem
                                                                colSpan={colSpanType2}
                                                                maxWidth={["96", "100%", "100%"]}
                                                            >
                                                                <FormControl
                                                                    isInvalid={
                                                                        !!errors?.consultantWorkExperiences?.[index]
                                                                            ?.workExperienceRole?.message
                                                                    }
                                                                >
                                                                    <FormLabel>{t("form.field23.label")} :</FormLabel>
                                                                    <Input
                                                                        placeholder={t("form.field23.placeholder")}
                                                                        {...register(
                                                                            `consultantWorkExperiences.${index}.workExperienceRole` as const,
                                                                            {
                                                                                required: {
                                                                                    value: false,
                                                                                    message: t(
                                                                                        "form.field23.requiredPromptText",
                                                                                    ),
                                                                                },
                                                                            },
                                                                        )}
                                                                    />
                                                                    <FormErrorMessage fontSize={"xs"}>
                                                                        {
                                                                            errors?.consultantWorkExperiences?.[index]
                                                                                ?.workExperienceRole?.message
                                                                        }
                                                                    </FormErrorMessage>
                                                                </FormControl>
                                                            </GridItem>
                                                            <GridItem
                                                                colSpan={colSpanType2}
                                                                maxWidth={["96", "100%", "100%"]}
                                                            >
                                                                <Controller
                                                                    {...register(
                                                                        `consultantWorkExperiences.${index}.workExperienceFrom` as const,
                                                                        {
                                                                            required: {
                                                                                value: false,
                                                                                message: t(
                                                                                    "form.field24.requiredPromptText",
                                                                                ),
                                                                            },
                                                                        },
                                                                    )}
                                                                    control={control}
                                                                    render={({
                                                                        field: { value, onChange, onBlur, ref, name },
                                                                        fieldState: { error },
                                                                    }) => (
                                                                        <FormControl isInvalid={!!error?.message}>
                                                                            <FormLabel>
                                                                                {t("form.field24.label")} :
                                                                            </FormLabel>
                                                                            <DatePicker
                                                                                name={name}
                                                                                ref={ref}
                                                                                dateFormat="yyyy-MM-dd"
                                                                                selected={value}
                                                                                onChange={onChange}
                                                                                onBlur={onBlur}
                                                                                selectsStart
                                                                                placeholderText={t(
                                                                                    "form.field24.placeholder",
                                                                                )}
                                                                                autoComplete="off"
                                                                                customInput={<CustomDatePickerField />}
                                                                                startDate={
                                                                                    getValues(
                                                                                        `consultantWorkExperiences.${index}.workExperienceFrom`,
                                                                                    ) || null
                                                                                }
                                                                                endDate={
                                                                                    getValues(
                                                                                        `consultantWorkExperiences.${index}.workExperienceTo`,
                                                                                    ) || null
                                                                                }
                                                                                maxDate={
                                                                                    getValues(
                                                                                        `consultantWorkExperiences.${index}.workExperienceTo`,
                                                                                    ) || null
                                                                                }
                                                                                className={
                                                                                    error?.message ? "error" : ""
                                                                                }
                                                                                showYearDropdown
                                                                                scrollableYearDropdown
                                                                            />
                                                                            <FormErrorMessage
                                                                                fontSize={"xs"}
                                                                                mt={4}
                                                                                ml={2}
                                                                            >
                                                                                {error?.message}
                                                                            </FormErrorMessage>
                                                                        </FormControl>
                                                                    )}
                                                                />
                                                            </GridItem>

                                                            <GridItem
                                                                colSpan={colSpanType2}
                                                                maxWidth={["96", "100%", "100%"]}
                                                            >
                                                                <Controller
                                                                    {...register(
                                                                        `consultantWorkExperiences.${index}.workExperienceTo` as const,
                                                                        {
                                                                            required: {
                                                                                value: false,
                                                                                message: t(
                                                                                    "form.field25.requiredPromptText",
                                                                                ),
                                                                            },
                                                                        },
                                                                    )}
                                                                    control={control}
                                                                    render={({
                                                                        field: { onChange, onBlur, value, ref, name },
                                                                        fieldState: { error },
                                                                    }) => (
                                                                        <FormControl isInvalid={!!error?.message}>
                                                                            <FormLabel>
                                                                                {t("form.field25.label")} :
                                                                            </FormLabel>
                                                                            <DatePicker
                                                                                name={name}
                                                                                ref={ref}
                                                                                dateFormat="yyyy-MM-dd"
                                                                                selected={value}
                                                                                onChange={onChange}
                                                                                onBlur={onBlur}
                                                                                selectsEnd
                                                                                placeholderText={t(
                                                                                    "form.field25.placeholder",
                                                                                )}
                                                                                autoComplete="off"
                                                                                customInput={<CustomDatePickerField />}
                                                                                startDate={
                                                                                    getValues(
                                                                                        `consultantWorkExperiences.${index}.workExperienceFrom`,
                                                                                    ) || null
                                                                                }
                                                                                endDate={getValues(
                                                                                    `consultantWorkExperiences.${index}.workExperienceTo`,
                                                                                )}
                                                                                minDate={
                                                                                    getValues(
                                                                                        `consultantWorkExperiences.${index}.workExperienceFrom`,
                                                                                    ) || null
                                                                                }
                                                                                showYearDropdown
                                                                                scrollableYearDropdown
                                                                            />
                                                                            <FormErrorMessage
                                                                                fontSize={"xs"}
                                                                                mt={4}
                                                                                ml={2}
                                                                            >
                                                                                {error?.message}
                                                                            </FormErrorMessage>
                                                                        </FormControl>
                                                                    )}
                                                                />
                                                            </GridItem>

                                                            <GridItem
                                                                colSpan={colSpanType2}
                                                                maxWidth={["96", "100%", "100%"]}
                                                                key={item.id}
                                                            >
                                                                <FormControl
                                                                    isInvalid={
                                                                        !!errors?.consultantWorkExperiences?.[index]
                                                                            ?.workExperienceSalary?.message
                                                                    }
                                                                >
                                                                    <FormLabel>{t("form.field27.label")} :</FormLabel>
                                                                    <NumberInput min={1}>
                                                                        <NumberInputField
                                                                            placeholder={t("form.field27.placeholder")}
                                                                            {...register(
                                                                                `consultantWorkExperiences.${index}.workExperienceSalary` as const,
                                                                                {
                                                                                    required: {
                                                                                        value: false,
                                                                                        message: t(
                                                                                            "form.field27.requiredPromptText",
                                                                                        ),
                                                                                    },
                                                                                },
                                                                            )}
                                                                        />
                                                                        <NumberInputStepper>
                                                                            <NumberIncrementStepper />
                                                                            <NumberDecrementStepper />
                                                                        </NumberInputStepper>
                                                                    </NumberInput>
                                                                    <FormErrorMessage fontSize={"xs"}>
                                                                        {
                                                                            errors?.consultantWorkExperiences?.[index]
                                                                                ?.workExperienceSalary?.message
                                                                        }
                                                                    </FormErrorMessage>
                                                                </FormControl>
                                                            </GridItem>

                                                            <GridItem
                                                                colSpan={colSpanType3}
                                                                colStart={1}
                                                                maxWidth={["96", "100%", "100%"]}
                                                                key={item.id}
                                                            >
                                                                <FormControl
                                                                    isInvalid={
                                                                        !!errors?.consultantWorkExperiences?.[index]
                                                                            ?.workExperienceSkills?.message
                                                                    }
                                                                >
                                                                    <FormLabel>{t("form.field26.label")} :</FormLabel>
                                                                    <Textarea
                                                                        placeholder={t("form.field26.placeholder")}
                                                                        {...register(
                                                                            `consultantWorkExperiences.${index}.workExperienceSkills` as const,
                                                                            {
                                                                                required: {
                                                                                    value: false,
                                                                                    message: t(
                                                                                        "form.field26.requiredPromptText",
                                                                                    ),
                                                                                },
                                                                            },
                                                                        )}
                                                                    />
                                                                    <FormErrorMessage fontSize={"xs"}>
                                                                        {
                                                                            errors?.consultantWorkExperiences?.[index]
                                                                                ?.workExperienceSkills?.message
                                                                        }
                                                                    </FormErrorMessage>
                                                                </FormControl>
                                                            </GridItem>

                                                            <GridItem
                                                                colSpan={colSpanType3}
                                                                colStart={1}
                                                                maxWidth={["96", "100%", "100%"]}
                                                                key={item.id}
                                                            >
                                                                <FormControl
                                                                    isInvalid={
                                                                        !!errors?.consultantWorkExperiences?.[index]
                                                                            ?.workExperienceRemarks?.message
                                                                    }
                                                                >
                                                                    <FormLabel>{t("form.field28.label")} :</FormLabel>
                                                                    <Textarea
                                                                        placeholder={t("form.field28.placeholder")}
                                                                        {...register(
                                                                            `consultantWorkExperiences.${index}.workExperienceRemarks` as const,
                                                                            {
                                                                                required: {
                                                                                    value: false,
                                                                                    message: t(
                                                                                        "form.field28.requiredPromptText",
                                                                                    ),
                                                                                },
                                                                            },
                                                                        )}
                                                                    />
                                                                    <FormErrorMessage fontSize={"xs"}>
                                                                        {
                                                                            errors?.consultantWorkExperiences?.[index]
                                                                                ?.workExperienceRemarks?.message
                                                                        }
                                                                    </FormErrorMessage>
                                                                </FormControl>
                                                            </GridItem>
                                                        </SimpleGrid>
                                                    </AccordionPanel>
                                                </AccordionItem>
                                            </>
                                        );
                                    })}
                                </Accordion>
                            </Box>
                        </GridItem>

                        {/* Consultant certification related info*/}
                        <GridItem colSpan={colSpanType3} colStart={1}>
                            <Box borderColor={"gray.200"} borderRadius="xl" borderWidth={1} p={4}>
                                <Flex align={"center"} justifyContent={"space-between"} mb={4}>
                                    <Text fontWeight={"semibold"} fontSize={"lg"}>
                                        {t("certificationInf")}
                                    </Text>
                                    <Button
                                        leftIcon={<AddIcon />}
                                        onClick={() => {
                                            certificationAppend({ certificationDocument: "" });
                                        }}
                                    >
                                        {t("addCertification")}
                                    </Button>
                                </Flex>
                                <Accordion allowMultiple>
                                    {certificationFields.map((item, index) => {
                                        return (
                                            <>
                                                <AccordionItem>
                                                    <h2>
                                                        <AccordionButton>
                                                            <Box flex="1" textAlign="left">
                                                                {t("certificationTitle")} {index + 1}
                                                            </Box>
                                                            <IconButton
                                                                // colorScheme=""
                                                                h={7}
                                                                w={7}
                                                                mr={4}
                                                                bg={"white"}
                                                                aria-label="Remove certification"
                                                                icon={<DeleteIcon />}
                                                                onClick={(event) => {
                                                                    event.preventDefault();
                                                                    certificationRemove(index);
                                                                }}
                                                            />

                                                            <AccordionIcon />
                                                        </AccordionButton>
                                                    </h2>
                                                    <AccordionPanel pb={24}>
                                                        <SimpleGrid
                                                            key={item.id}
                                                            templateColumns="repeat(12, 1fr)"
                                                            columnGap={16}
                                                            rowGap={12}
                                                            w="full"
                                                            // maxWidth="container.xl"
                                                            display={["flex", "flex", "grid"]}
                                                            flexDirection={"column"}
                                                        >
                                                            <GridItem
                                                                colSpan={colSpanType2}
                                                                maxWidth={["96", "100%", "100%"]}
                                                            >
                                                                <FormControl
                                                                    isInvalid={
                                                                        !!errors?.consultantCertifications?.[index]
                                                                            ?.certificationTitle?.message
                                                                    }
                                                                >
                                                                    <FormLabel>{t("form.field29.label")} :</FormLabel>
                                                                    <Input
                                                                        placeholder={t("form.field29.placeholder")}
                                                                        {...register(
                                                                            `consultantCertifications.${index}.certificationTitle` as const,
                                                                            {
                                                                                required: {
                                                                                    value: false,
                                                                                    message: t(
                                                                                        "form.field29.requiredPromptText",
                                                                                    ),
                                                                                },
                                                                            },
                                                                        )}
                                                                    />
                                                                    <FormErrorMessage fontSize={"xs"}>
                                                                        {
                                                                            errors?.consultantCertifications?.[index]
                                                                                ?.certificationTitle?.message
                                                                        }
                                                                    </FormErrorMessage>
                                                                </FormControl>
                                                            </GridItem>
                                                            <GridItem
                                                                colSpan={colSpanType2}
                                                                maxWidth={["96", "100%", "100%"]}
                                                            >
                                                                <FormControl
                                                                    isInvalid={
                                                                        !!errors?.consultantCertifications?.[index]
                                                                            ?.certificationIssuedby?.message
                                                                    }
                                                                >
                                                                    <FormLabel>{t("form.field30.label")} :</FormLabel>
                                                                    <Input
                                                                        placeholder={t("form.field30.placeholder")}
                                                                        {...register(
                                                                            `consultantCertifications.${index}.certificationIssuedby` as const,
                                                                            {
                                                                                required: {
                                                                                    value: false,
                                                                                    message: t(
                                                                                        "form.field30.requiredPromptText",
                                                                                    ),
                                                                                },
                                                                            },
                                                                        )}
                                                                    />
                                                                    <FormErrorMessage fontSize={"xs"}>
                                                                        {
                                                                            errors?.consultantCertifications?.[index]
                                                                                ?.certificationIssuedby?.message
                                                                        }
                                                                    </FormErrorMessage>
                                                                </FormControl>
                                                            </GridItem>

                                                            {/* TODO: @umesh Replace this with file upload */}
                                                            {/* <GridItem
                                                                colSpan={colSpanType2}
                                                                maxWidth={["96", "100%", "100%"]}
                                                            >
                                                                <FormControl
                                                                    isInvalid={
                                                                        !!errors?.consultantCertifications?.[index]
                                                                            ?.certificationDocument?.message
                                                                    }
                                                                >
                                                                    <FormLabel>{t("form.field31.label")} :</FormLabel>
                                                                    <Input
                                                                        placeholder={t("form.field31.placeholder")}
                                                                        {...register(
                                                                            `consultantCertifications.${index}.certificationDocument` as const,
                                                                            {
                                                                                required: {
                                                                                    value: false,
                                                                                    message: t(
                                                                                        "form.field31.requiredPromptText",
                                                                                    ),
                                                                                },
                                                                            },
                                                                        )}
                                                                    />
                                                                    <FormErrorMessage fontSize={"xs"}>
                                                                        {
                                                                            errors?.consultantCertifications?.[index]
                                                                                ?.certificationDocument?.message
                                                                        }
                                                                    </FormErrorMessage>
                                                                </FormControl>
                                                            </GridItem> */}

                                                            <GridItem
                                                                colSpan={colSpanType3}
                                                                colStart={1}
                                                                maxWidth={["96", "100%", "100%"]}
                                                                key={item.id}
                                                            >
                                                                <FormControl
                                                                    isInvalid={
                                                                        !!errors?.consultantCertifications?.[index]
                                                                            ?.certificationRemarks?.message
                                                                    }
                                                                >
                                                                    <FormLabel>{t("form.field32.label")} :</FormLabel>
                                                                    <Textarea
                                                                        placeholder={t("form.field32.placeholder")}
                                                                        {...register(
                                                                            `consultantCertifications.${index}.certificationRemarks` as const,
                                                                            {
                                                                                required: {
                                                                                    value: false,
                                                                                    message: t(
                                                                                        "form.field32.requiredPromptText",
                                                                                    ),
                                                                                },
                                                                            },
                                                                        )}
                                                                    />
                                                                    <FormErrorMessage fontSize={"xs"}>
                                                                        {
                                                                            errors?.consultantCertifications?.[index]
                                                                                ?.certificationRemarks?.message
                                                                        }
                                                                    </FormErrorMessage>
                                                                </FormControl>
                                                            </GridItem>
                                                        </SimpleGrid>
                                                    </AccordionPanel>
                                                </AccordionItem>
                                            </>
                                        );
                                    })}
                                </Accordion>
                            </Box>
                        </GridItem>

                        {/* Consultant skills related info*/}
                        <GridItem colSpan={colSpanType3} colStart={1}>
                            <Box borderColor={"gray.200"} borderRadius="xl" borderWidth={1} p={4}>
                                <Flex align={"center"} justifyContent={"space-between"} mb={4}>
                                    <Text fontWeight={"semibold"} fontSize={"lg"}>
                                        {t("skillsInf")}
                                    </Text>
                                    <Button
                                        leftIcon={<AddIcon />}
                                        onClick={() => {
                                            skillAppend({});
                                        }}
                                    >
                                        {t("addSiklls")}
                                    </Button>
                                </Flex>
                                <Accordion allowMultiple>
                                    {skillFields.map((item, index) => {
                                        return (
                                            <>
                                                <AccordionItem>
                                                    <h2>
                                                        <AccordionButton>
                                                            <Box flex="1" textAlign="left">
                                                                {t("skillsTitle")} {index + 1}
                                                            </Box>
                                                            <IconButton
                                                                // colorScheme=""
                                                                h={7}
                                                                w={7}
                                                                mr={4}
                                                                bg={"white"}
                                                                aria-label="Remove certification"
                                                                icon={<DeleteIcon />}
                                                                onClick={(event) => {
                                                                    event.preventDefault();
                                                                    skillRemove(index);
                                                                }}
                                                            />

                                                            <AccordionIcon />
                                                        </AccordionButton>
                                                    </h2>
                                                    <AccordionPanel pb={24}>
                                                        <SimpleGrid
                                                            key={item.id}
                                                            templateColumns="repeat(12, 1fr)"
                                                            columnGap={16}
                                                            rowGap={12}
                                                            w="full"
                                                            // maxWidth="container.xl"
                                                            display={["flex", "flex", "grid"]}
                                                            flexDirection={"column"}
                                                        >
                                                            <GridItem
                                                                colSpan={colSpanType2}
                                                                maxWidth={["96", "100%", "100%"]}
                                                            >
                                                                <FormControl
                                                                    isInvalid={
                                                                        !!errors?.consultantSkills?.[index]?.skillName
                                                                            ?.message
                                                                    }
                                                                >
                                                                    <FormLabel>{t("form.field33.label")} :</FormLabel>
                                                                    <Input
                                                                        placeholder={t("form.field33.placeholder")}
                                                                        {...register(
                                                                            `consultantSkills.${index}.skillName` as const,
                                                                            {
                                                                                required: {
                                                                                    value: false,
                                                                                    message: t(
                                                                                        "form.field33.requiredPromptText",
                                                                                    ),
                                                                                },
                                                                            },
                                                                        )}
                                                                    />
                                                                    <FormErrorMessage fontSize={"xs"}>
                                                                        {
                                                                            errors?.consultantSkills?.[index]?.skillName
                                                                                ?.message
                                                                        }
                                                                    </FormErrorMessage>
                                                                </FormControl>
                                                            </GridItem>
                                                            <GridItem
                                                                colSpan={colSpanType2}
                                                                maxWidth={["96", "100%", "100%"]}
                                                            >
                                                                <FormControl
                                                                    isInvalid={
                                                                        !!errors?.consultantSkills?.[index]
                                                                            ?.skillExperienceYears?.message
                                                                    }
                                                                >
                                                                    <FormLabel>{t("form.field34.label")} :</FormLabel>
                                                                    <NumberInput min={0}>
                                                                        <NumberInputField
                                                                            placeholder={t("form.field34.placeholder")}
                                                                            {...register(
                                                                                `consultantSkills.${index}.skillExperienceYears` as const,
                                                                                {
                                                                                    required: {
                                                                                        value: false,
                                                                                        message: t(
                                                                                            "form.field34.requiredPromptText",
                                                                                        ),
                                                                                    },
                                                                                },
                                                                            )}
                                                                        />
                                                                        <NumberInputStepper>
                                                                            <NumberIncrementStepper />
                                                                            <NumberDecrementStepper />
                                                                        </NumberInputStepper>
                                                                    </NumberInput>
                                                                    <FormErrorMessage fontSize={"xs"}>
                                                                        {
                                                                            errors?.consultantSkills?.[index]
                                                                                ?.skillExperienceYears?.message
                                                                        }
                                                                    </FormErrorMessage>
                                                                </FormControl>
                                                            </GridItem>

                                                            {/* TODO: @umesh Confirm this field dropdown or input field */}
                                                            <GridItem
                                                                colSpan={colSpanType2}
                                                                maxWidth={["96", "100%", "100%"]}
                                                            >
                                                                <Controller
                                                                    control={control}
                                                                    {...register(
                                                                        `consultantSkills.${index}.skillLevel` as const,
                                                                        {
                                                                            required: {
                                                                                value: false,
                                                                                message: t(
                                                                                    "form.field35.requiredPromptText",
                                                                                ),
                                                                            },
                                                                        },
                                                                    )}
                                                                    render={({
                                                                        field: { onChange, onBlur, value, name },
                                                                        fieldState: { error },
                                                                    }) => (
                                                                        <FormControl
                                                                            isInvalid={!!error?.message}
                                                                            id="skillLevel"
                                                                        >
                                                                            <FormLabel
                                                                                fontFamily={"special"}
                                                                                fontWeight={700}
                                                                            >
                                                                                {t("form.field35.label")} :
                                                                            </FormLabel>
                                                                            <CustomMultiSelectDropdown
                                                                                name={name}
                                                                                onChange={onChange}
                                                                                onBlur={onBlur}
                                                                                value={value}
                                                                                options={skillLevelOption}
                                                                                placeholder={t(
                                                                                    "form.field35.placeholder",
                                                                                )}
                                                                                isMulti={false}
                                                                            />
                                                                            <FormErrorMessage fontSize={"xs"}>
                                                                                {error && error.message}
                                                                            </FormErrorMessage>
                                                                        </FormControl>
                                                                    )}
                                                                />
                                                            </GridItem>

                                                            <GridItem
                                                                colSpan={colSpanType3}
                                                                colStart={1}
                                                                maxWidth={["96", "100%", "100%"]}
                                                                key={item.id}
                                                            >
                                                                <FormControl
                                                                    isInvalid={
                                                                        !!errors?.consultantSkills?.[index]
                                                                            ?.skillRemarks?.message
                                                                    }
                                                                >
                                                                    <FormLabel>{t("form.field36.label")} :</FormLabel>
                                                                    <Textarea
                                                                        placeholder={t("form.field36.placeholder")}
                                                                        {...register(
                                                                            `consultantSkills.${index}.skillRemarks` as const,
                                                                            {
                                                                                required: {
                                                                                    value: false,
                                                                                    message: t(
                                                                                        "form.field36.requiredPromptText",
                                                                                    ),
                                                                                },
                                                                            },
                                                                        )}
                                                                    />
                                                                    <FormErrorMessage fontSize={"xs"}>
                                                                        {
                                                                            errors?.consultantSkills?.[index]
                                                                                ?.skillRemarks?.message
                                                                        }
                                                                    </FormErrorMessage>
                                                                </FormControl>
                                                            </GridItem>
                                                        </SimpleGrid>
                                                    </AccordionPanel>
                                                </AccordionItem>
                                            </>
                                        );
                                    })}
                                </Accordion>
                            </Box>
                        </GridItem>

                        {/* Consultant language related info*/}
                        <GridItem colSpan={colSpanType3} colStart={1}>
                            <Box borderColor={"gray.200"} borderRadius="xl" borderWidth={1} p={4}>
                                <Flex align={"center"} justifyContent={"space-between"} mb={4}>
                                    <Text fontWeight={"semibold"} fontSize={"lg"}>
                                        {t("languageInf")}
                                    </Text>
                                    <Button
                                        leftIcon={<AddIcon />}
                                        onClick={() => {
                                            languageAppend({ languageCertificationDocument: "" });
                                        }}
                                    >
                                        {t("addLanguage")}
                                    </Button>
                                </Flex>
                                <Accordion allowMultiple>
                                    {languageFields.map((item, index) => {
                                        return (
                                            <>
                                                <AccordionItem>
                                                    <h2>
                                                        <AccordionButton>
                                                            <Box flex="1" textAlign="left">
                                                                {t("languageTitle")} {index + 1}
                                                            </Box>
                                                            <IconButton
                                                                // colorScheme=""
                                                                h={7}
                                                                w={7}
                                                                mr={4}
                                                                bg={"white"}
                                                                aria-label="Remove education"
                                                                icon={<DeleteIcon />}
                                                                onClick={(event) => {
                                                                    event.preventDefault();
                                                                    languageRemove(index);
                                                                }}
                                                            />

                                                            <AccordionIcon />
                                                        </AccordionButton>
                                                    </h2>
                                                    <AccordionPanel pb={24}>
                                                        <SimpleGrid
                                                            key={item.id}
                                                            templateColumns="repeat(12, 1fr)"
                                                            columnGap={16}
                                                            rowGap={12}
                                                            w="full"
                                                            // maxWidth="container.xl"
                                                            display={["flex", "flex", "grid"]}
                                                            flexDirection={"column"}
                                                        >
                                                            <GridItem
                                                                colSpan={colSpanType2}
                                                                maxWidth={["96", "100%", "100%"]}
                                                            >
                                                                <Controller
                                                                    control={control}
                                                                    {...register(
                                                                        `consultantLanguages.${index}.language` as const,
                                                                        {
                                                                            required: {
                                                                                value: false,
                                                                                message: t(
                                                                                    "form.field37.requiredPromptText",
                                                                                ),
                                                                            },
                                                                        },
                                                                    )}
                                                                    render={({
                                                                        field: { onChange, onBlur, value, name },
                                                                        fieldState: { error },
                                                                    }) => (
                                                                        <FormControl
                                                                            isInvalid={!!error?.message}
                                                                            id="language"
                                                                        >
                                                                            <FormLabel>
                                                                                {t("form.field37.label")} :
                                                                            </FormLabel>
                                                                            <CustomMultiSelectDropdown
                                                                                name={name}
                                                                                onChange={onChange}
                                                                                onBlur={onBlur}
                                                                                value={value}
                                                                                options={languageOption}
                                                                                placeholder={t(
                                                                                    "form.field37.placeholder",
                                                                                )}
                                                                                isMulti={false}
                                                                            />
                                                                            <FormErrorMessage fontSize={"xs"}>
                                                                                {error && error.message}
                                                                            </FormErrorMessage>
                                                                        </FormControl>
                                                                    )}
                                                                />
                                                            </GridItem>

                                                            <GridItem
                                                                colSpan={colSpanType2}
                                                                maxWidth={["96", "100%", "100%"]}
                                                            >
                                                                <Controller
                                                                    control={control}
                                                                    {...register(
                                                                        `consultantLanguages.${index}.languageRead` as const,
                                                                        {
                                                                            required: {
                                                                                value: false,
                                                                                message: t(
                                                                                    "form.field38.requiredPromptText",
                                                                                ),
                                                                            },
                                                                        },
                                                                    )}
                                                                    render={({
                                                                        field: { onChange, value, name },
                                                                        fieldState: { error },
                                                                    }) => (
                                                                        <FormControl
                                                                            id="languageRead"
                                                                            isInvalid={!!error?.message}
                                                                        >
                                                                            <FormLabel>
                                                                                {t("form.field38.label")} :
                                                                            </FormLabel>
                                                                            <HStack my={4}>
                                                                                <Checkbox
                                                                                    onChange={onChange}
                                                                                    name={name}
                                                                                    isChecked={!!value}
                                                                                    size="lg"
                                                                                >
                                                                                    <Text>
                                                                                        {t("form.field38.label")}
                                                                                    </Text>
                                                                                </Checkbox>
                                                                            </HStack>
                                                                            <FormErrorMessage fontSize={"xs"}>
                                                                                {error && error.message}
                                                                            </FormErrorMessage>
                                                                        </FormControl>
                                                                    )}
                                                                />
                                                            </GridItem>

                                                            <GridItem
                                                                colSpan={colSpanType2}
                                                                maxWidth={["96", "100%", "100%"]}
                                                            >
                                                                <Controller
                                                                    control={control}
                                                                    {...register(
                                                                        `consultantLanguages.${index}.languageWrite` as const,
                                                                        {
                                                                            required: {
                                                                                value: false,
                                                                                message: t(
                                                                                    "form.field39.requiredPromptText",
                                                                                ),
                                                                            },
                                                                        },
                                                                    )}
                                                                    render={({
                                                                        field: { onChange, value, name },
                                                                        fieldState: { error },
                                                                    }) => (
                                                                        <FormControl
                                                                            id="languageWrite"
                                                                            isInvalid={!!error?.message}
                                                                        >
                                                                            <FormLabel>
                                                                                {t("form.field39.label")} :
                                                                            </FormLabel>
                                                                            <HStack my={4}>
                                                                                <Checkbox
                                                                                    onChange={onChange}
                                                                                    name={name}
                                                                                    isChecked={!!value}
                                                                                    size="lg"
                                                                                >
                                                                                    <Text>
                                                                                        {t("form.field39.label")}
                                                                                    </Text>
                                                                                </Checkbox>
                                                                            </HStack>
                                                                            <FormErrorMessage fontSize={"xs"}>
                                                                                {error && error.message}
                                                                            </FormErrorMessage>
                                                                        </FormControl>
                                                                    )}
                                                                />
                                                            </GridItem>
                                                            <GridItem
                                                                colSpan={colSpanType2}
                                                                maxWidth={["96", "100%", "100%"]}
                                                            >
                                                                <Controller
                                                                    control={control}
                                                                    {...register(
                                                                        `consultantLanguages.${index}.languageSpeak` as const,
                                                                        {
                                                                            required: {
                                                                                value: false,
                                                                                message: t(
                                                                                    "form.field40.requiredPromptText",
                                                                                ),
                                                                            },
                                                                        },
                                                                    )}
                                                                    render={({
                                                                        field: { onChange, value, name },
                                                                        fieldState: { error },
                                                                    }) => (
                                                                        <FormControl
                                                                            id="languageSpeak"
                                                                            isInvalid={!!error?.message}
                                                                        >
                                                                            <FormLabel>
                                                                                {t("form.field40.label")} :
                                                                            </FormLabel>
                                                                            <HStack my={4}>
                                                                                <Checkbox
                                                                                    onChange={onChange}
                                                                                    name={name}
                                                                                    isChecked={!!value}
                                                                                    size="lg"
                                                                                >
                                                                                    <Text>
                                                                                        {t("form.field40.label")}
                                                                                    </Text>
                                                                                </Checkbox>
                                                                            </HStack>
                                                                            <FormErrorMessage fontSize={"xs"}>
                                                                                {error && error.message}
                                                                            </FormErrorMessage>
                                                                        </FormControl>
                                                                    )}
                                                                />
                                                            </GridItem>

                                                            {/* TODO: @umesh make this field as a single select drop down */}
                                                            <GridItem
                                                                colSpan={colSpanType2}
                                                                maxWidth={["96", "100%", "100%"]}
                                                                key={item.id}
                                                            >
                                                                <Controller
                                                                    control={control}
                                                                    {...register(
                                                                        `consultantLanguages.${index}.languageLevel` as const,
                                                                        {
                                                                            required: {
                                                                                value: false,
                                                                                message: t(
                                                                                    "form.field41.requiredPromptText",
                                                                                ),
                                                                            },
                                                                        },
                                                                    )}
                                                                    render={({
                                                                        field: { onChange, onBlur, value, name },
                                                                        fieldState: { error },
                                                                    }) => (
                                                                        <FormControl
                                                                            isInvalid={!!error?.message}
                                                                            id="languageLevel"
                                                                        >
                                                                            <FormLabel
                                                                                fontFamily={"special"}
                                                                                fontWeight={700}
                                                                            >
                                                                                {t("form.field41.label")} :
                                                                            </FormLabel>
                                                                            <CustomMultiSelectDropdown
                                                                                name={name}
                                                                                onChange={onChange}
                                                                                onBlur={onBlur}
                                                                                value={value}
                                                                                options={skillLevelOption}
                                                                                placeholder={t(
                                                                                    "form.field41.placeholder",
                                                                                )}
                                                                                isMulti={false}
                                                                            />
                                                                            <FormErrorMessage fontSize={"xs"}>
                                                                                {error && error.message}
                                                                            </FormErrorMessage>
                                                                        </FormControl>
                                                                    )}
                                                                />
                                                            </GridItem>

                                                            <GridItem
                                                                colSpan={colSpanType2}
                                                                maxWidth={["96", "100%", "100%"]}
                                                                key={item.id}
                                                            >
                                                                <FormControl
                                                                    isInvalid={
                                                                        !!errors?.consultantLanguages?.[index]
                                                                            ?.languageCertification?.message
                                                                    }
                                                                >
                                                                    <FormLabel>{t("form.field42.label")} :</FormLabel>
                                                                    <Input
                                                                        placeholder={t("form.field42.placeholder")}
                                                                        {...register(
                                                                            `consultantLanguages.${index}.languageCertification` as const,
                                                                            {
                                                                                required: {
                                                                                    value: false,
                                                                                    message: t(
                                                                                        "form.field42.requiredPromptText",
                                                                                    ),
                                                                                },
                                                                            },
                                                                        )}
                                                                    />
                                                                    <FormErrorMessage fontSize={"xs"}>
                                                                        {
                                                                            errors?.consultantLanguages?.[index]
                                                                                ?.languageCertification?.message
                                                                        }
                                                                    </FormErrorMessage>
                                                                </FormControl>
                                                            </GridItem>

                                                            {/* TODO: @umesh Replace this field with file upload */}
                                                            {/* <GridItem
                                                                colSpan={colSpanType2}
                                                                maxWidth={["96", "100%", "100%"]}
                                                                key={item.id}
                                                            >
                                                                <FormControl
                                                                    isInvalid={
                                                                        !!errors?.consultantLanguages?.[index]
                                                                            ?.languageCertificationDocument?.message
                                                                    }
                                                                >
                                                                    <FormLabel>{t("form.field43.label")} :</FormLabel>
                                                                    <Input
                                                                        placeholder={t("form.field43.placeholder")}
                                                                        {...register(
                                                                            `consultantLanguages.${index}.languageCertificationDocument` as const,
                                                                            {
                                                                                required: {
                                                                                    value: false,
                                                                                    message: t(
                                                                                        "form.field43.requiredPromptText",
                                                                                    ),
                                                                                },
                                                                            },
                                                                        )}
                                                                    />
                                                                    <FormErrorMessage fontSize={"xs"}>
                                                                        {
                                                                            errors?.consultantLanguages?.[index]
                                                                                ?.languageCertificationDocument?.message
                                                                        }
                                                                    </FormErrorMessage>
                                                                </FormControl>
                                                            </GridItem> */}

                                                            <GridItem
                                                                colSpan={colSpanType3}
                                                                colStart={1}
                                                                maxWidth={["96", "100%", "100%"]}
                                                                key={item.id}
                                                            >
                                                                <FormControl
                                                                    isInvalid={
                                                                        !!errors?.consultantLanguages?.[index]
                                                                            ?.languageRemarks?.message
                                                                    }
                                                                >
                                                                    <FormLabel>{t("form.field44.label")} :</FormLabel>
                                                                    <Textarea
                                                                        placeholder={t("form.field44.placeholder")}
                                                                        {...register(
                                                                            `consultantLanguages.${index}.languageRemarks` as const,
                                                                            {
                                                                                required: {
                                                                                    value: false,
                                                                                    message: t(
                                                                                        "form.field44.requiredPromptText",
                                                                                    ),
                                                                                },
                                                                            },
                                                                        )}
                                                                    />
                                                                    <FormErrorMessage fontSize={"xs"}>
                                                                        {
                                                                            errors?.consultantLanguages?.[index]
                                                                                ?.languageRemarks?.message
                                                                        }
                                                                    </FormErrorMessage>
                                                                </FormControl>
                                                            </GridItem>
                                                        </SimpleGrid>
                                                    </AccordionPanel>
                                                </AccordionItem>
                                            </>
                                        );
                                    })}
                                </Accordion>
                            </Box>
                        </GridItem>

                        <GridItem
                            colSpan={colSpanType3}
                            colStart={1}
                            maxWidth={["96", "100%", "100%"]}
                            textAlign={"center"}
                        >
                            <Button
                                variant={"outline"}
                                textColor={"gray.50"}
                                bg={"brand.800"}
                                colorScheme="brand"
                                alignItems={"center"}
                                justifyContent={"center"}
                                alignContent="center"
                                size="lg"
                                w={80}
                                py={6}
                                mt={12}
                                fontFamily="body"
                                fontWeight={"medium"}
                                fontSize={"sm"}
                                borderWidth={"2px"}
                                borderRadius={"xl"}
                                _hover={{
                                    background: "brand.700",
                                }}
                                type="submit"
                            >
                                {data ? t("updateBtnText") : t("submitBtnText")}
                            </Button>
                        </GridItem>
                    </SimpleGrid>
                </form>
            </Box>
        </VStack>
    );
};

export default ConsultantProfileForm;
