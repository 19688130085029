import React from "react";

const ArrowBackButton = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.2084 7.00001C21.2084 6.80662 21.1316 6.62115 20.9948 6.48441C20.8581 6.34766 20.6726 6.27084 20.4792 6.27084H3.28108L7.87046 1.68292C7.93825 1.61513 7.99203 1.53464 8.02872 1.44606C8.06541 1.35749 8.0843 1.26255 8.0843 1.16667C8.0843 1.0708 8.06541 0.975858 8.02872 0.88728C7.99203 0.798701 7.93825 0.718217 7.87046 0.650422C7.80266 0.582627 7.72218 0.528849 7.6336 0.492159C7.54502 0.455469 7.45009 0.436584 7.35421 0.436584C7.25833 0.436584 7.16339 0.455469 7.07482 0.492159C6.98624 0.528849 6.90575 0.582627 6.83796 0.650422L1.00463 6.48376C0.936721 6.55149 0.882846 6.63195 0.846086 6.72054C0.809327 6.80913 0.790405 6.90409 0.790405 7.00001C0.790405 7.09592 0.809327 7.19088 0.846086 7.27947C0.882846 7.36806 0.936721 7.44852 1.00463 7.51626L6.83796 13.3496C6.90575 13.4174 6.98624 13.4712 7.07482 13.5079C7.16339 13.5445 7.25833 13.5634 7.35421 13.5634C7.45009 13.5634 7.54502 13.5445 7.6336 13.5079C7.72218 13.4712 7.80266 13.4174 7.87046 13.3496C7.93825 13.2818 7.99203 13.2013 8.02872 13.1127C8.06541 13.0242 8.0843 12.9292 8.0843 12.8333C8.0843 12.7375 8.06541 12.6425 8.02872 12.5539C7.99203 12.4654 7.93825 12.3849 7.87046 12.3171L3.28108 7.72917H20.4792C20.6726 7.72917 20.8581 7.65235 20.9948 7.5156C21.1316 7.37886 21.2084 7.19339 21.2084 7.00001Z"
                fill="#303030"
            />
        </svg>
    );
};

export default ArrowBackButton;
