import { Box, Button, chakra, Flex, Grid, Heading, IconButton, Spinner, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { JobDataInf } from "../../../@types/job.types";
import BannerBg from "../../../assets/Images/BannerBg.svg";
import FilterIcon from "../../../assets/ReactSvgIcons/FilterIcon-component";
import PageHeader from "../../../assets/ReactSvgIcons/PageHeader-component";
import UploadIcon from "../../../assets/ReactSvgIcons/UploadIcon-component";
import AppLayout from "../../../components/global/AppLayout/AppLayout-component";
import Footer from "../../../components/global/Footer/Footer-component";
import NavBar from "../../../components/global/NavBar/NavBar-component";
import SvgIconWrapper from "../../../components/global/SvgIconWrapper/SvgIconWrapper.component";
import JobListCard from "../../../components/jobListPage-components/JobListCard/JobListCard";
import { CLIENT_JOB_DETAILS_PAGE } from "../../../constants/WebAppRoute-constants";
import { useJobDescriptionsByClientId } from "../../../hooks/jobDescriptions.hook";
import { useGetSubscriptionId } from "../../../lib/authFns";

const JobDescriptionList = () => {
    const _clientId = useGetSubscriptionId();

    const navigate = useNavigate();

    const { t } = useTranslation("jobList");

    const queryResponse = useJobDescriptionsByClientId(_clientId);

    // TODO :@hari-anitha Need to handling the ui for error scenarios
    const { data, fetchNextPage, hasNextPage, isFetching } = queryResponse;

    return (
        <AppLayout minH={"100vh"}>
            <NavBar />
            <PageHeader pageText={t("pageTitle")} previousPagePath={null} isBackBtnHidden={true} />
            <Box
                bg="brand.800"
                mt={10}
                borderRadius={"2xl"}
                mx={"4vw"}
                style={{ backgroundImage: `url(${BannerBg})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}
                py={12}
                textAlign={"center"}
            >
                <Heading as="h2" color="white" fontFamily={`'Raleway', sans-serif`} fontWeight="bold" fontSize={"2xl"}>
                    Add new <chakra.span color="brand.200">Job</chakra.span>
                </Heading>
                <Button
                    variant={"outline"}
                    textColor={"gray.50"}
                    colorScheme="whiteAlpha"
                    alignItems={"center"}
                    justifyContent={"center"}
                    alignContent="center"
                    size="lg"
                    w={64}
                    py={6}
                    mt={12}
                    fontFamily="body"
                    fontWeight={"medium"}
                    fontSize={"sm"}
                    borderWidth={"2px"}
                    borderRadius={"xl"}
                    onClick={() => navigate("/create-job-description")}
                    leftIcon={<SvgIconWrapper svgComp={<UploadIcon />} w={6} mt={0} />}
                >
                    Add Job
                </Button>
            </Box>
            <Flex justifyContent={"space-between"} alignItems="center" px={"4vw"} mt={12}>
                <Heading
                    as="h2"
                    color="brand.900"
                    mx="auto"
                    w="full"
                    pl={12}
                    fontWeight="normal"
                    fontSize={"2xl"}
                    fontFamily={"body"}
                    textAlign="center"
                >
                    {" "}
                    {t("table.header.title1")}{" "}
                    <chakra.span color={"brand.900"} fontWeight={"bold"}>
                        {t("table.header.title2")}
                    </chakra.span>
                </Heading>
                <IconButton
                    icon={<SvgIconWrapper svgComp={<FilterIcon />} />}
                    aria-label="filter-jobs"
                    colorScheme={"blue"}
                    bg={"brand.900"}
                    isRound
                    w={12}
                    h={12}
                />
            </Flex>

            {data?.pages?.map(
                (group, i) =>
                    group?.items?.length > 0 && (
                        <Grid
                            templateColumns={{
                                base: "repeat(1, 1fr)",
                                md: "repeat(2, 1fr)",
                                lg: "repeat(3, 1fr)",
                                "2xl": "repeat(4, 1fr)",
                            }}
                            gap={{ base: "2rem", md: "2rem", lg: "2rem" }}
                            px={"4vw"}
                            my={8}
                            w="full"
                            key={i}
                        >
                            {group?.items?.map((inddata: JobDataInf) => {
                                return (
                                    <JobListCard
                                        key={inddata.id}
                                        {...inddata}
                                        onClickRedirectionLink={`${CLIENT_JOB_DETAILS_PAGE.url}/${inddata?.id}`}
                                    />
                                );
                            })}
                        </Grid>
                    ),
            )}
            {isFetching && (
                <Flex alignItems={"center"} justifyContent={"center"} h="full" my={12}>
                    <Spinner color="brand.500" thickness="4px" size={{ base: "sm", md: "md", lg: "lg" }} />
                </Flex>
            )}
            <Flex
                flexDirection={"column"}
                w="full"
                alignItems="center"
                justifyContent="center"
                mt={{ base: 10, lg: 20 }}
                display={!isFetching ? "flex" : "none"}
            >
                {hasNextPage ? (
                    <Button
                        variant={"outline"}
                        textColor={"brand.800"}
                        colorScheme="brand"
                        alignItems={"center"}
                        justifyContent={"center"}
                        alignContent="center"
                        size="lg"
                        w={"22%"}
                        py={6}
                        fontFamily="body"
                        fontWeight={"medium"}
                        fontSize={"sm"}
                        borderWidth={"1px"}
                        borderRadius={"xl"}
                        onClick={() => {
                            fetchNextPage();
                        }}
                    >
                        {t("table.moreBtnText")}
                    </Button>
                ) : (
                    <Text>{t("table.noMoreDataTxt")}</Text>
                )}
            </Flex>

            <Footer />
        </AppLayout>
    );
};

export default JobDescriptionList;
