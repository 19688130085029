import { FlexProps, Heading, Text } from "@chakra-ui/react";
import React from "react";
import Banner from "../../../components/global/Banner/Banner-component";
import SearchWithLocation from "../../../components/global/SearchWithLocation/SearchWithLocation-component";

const flexProps: FlexProps = {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "196px",
    gridGap: 12,
    color: "white",
};

// TODO: Remove once API is done

const locationSuggestions = [
    {
        label: "Delhi",
        value: "DELHI",
    },
];
const searchSuggestions = [
    {
        label: "Web Developer",
        value: "WEB_DEVELOPER",
    },
];

const ClientViewConsultantSearchBanner = () => {
    return (
        <Banner bgUrl="" flexProps={flexProps}>
            <Heading>
                <Text as="span" color="blue.400">
                    Search Your
                </Text>{" "}
                Consultant
            </Heading>
            <SearchWithLocation
                debounce={300}
                // TODO: Remove when API functions are available.
                loadLocationOptions={() => {
                    console.log("debounced call locationSuggestions");
                    return new Promise((resolve) => resolve(locationSuggestions));
                }}
                loadSearchOptions={() => {
                    console.log("debounced call searchSuggestions");
                    return new Promise((resolve) => resolve(searchSuggestions));
                }}
                onSubmit={console.log}
            />
        </Banner>
    );
};

export default ClientViewConsultantSearchBanner;
