import Axios, { AxiosRequestConfig } from "axios";
import { AUTH_TOKEN_LOCAL_STORAGE_KEY } from "../constants/Auth-constants";

interface GetDataProps {
    url: string;
    queryParams?: Record<string, unknown>;
}

interface PostDataProps {
    url: string;
    requestBody?: Record<string, unknown>;
    queryParams?: Record<string, unknown>;
    customHeader?: AxiosRequestConfig;
}

interface PutDataProps extends PostDataProps {}

const authToken = JSON.parse(localStorage.getItem(AUTH_TOKEN_LOCAL_STORAGE_KEY));

const initializers = {
    // baseURL: process.env.REACT_APP_API_SERVER,
    headers: {
        "content-type": "application/json",
        Authorization: authToken,
    },
};

export const axiosInstance = Axios.create(initializers);

function getAxiosInstance() {
    return axiosInstance;
}

axiosInstance.interceptors.request.use(function (config) {
    const token = JSON.parse(localStorage.getItem(AUTH_TOKEN_LOCAL_STORAGE_KEY));
    config.headers.Authorization = token ? token : null;
    return config;
});

const defaultPropsOfGetData = {
    queryParams: {},
};

// Use this only when you need to send the bearer token.
export function get(props: GetDataProps): any {
    const { url, queryParams = defaultPropsOfGetData.queryParams } = props;
    const axios = getAxiosInstance();
    return axios.get(url, { params: queryParams });
}

const defaultPropsOfPostData = {
    requestBody: {},
    queryParams: {},
};

export function post(props: PostDataProps) {
    const { url, queryParams, requestBody, customHeader } = { ...defaultPropsOfPostData, ...props };
    const axios = getAxiosInstance();
    if (customHeader) {
        axios.defaults.headers = { ...axios.defaults.headers, ...customHeader.headers };
    }
    return axios.post(url, requestBody, { params: queryParams });
}

export function put(props: PutDataProps) {
    const { url, queryParams, requestBody, customHeader } = { ...defaultPropsOfPostData, ...props };
    const axios = getAxiosInstance();
    if (customHeader) {
        axios.defaults.headers = { ...axios.defaults.headers, ...customHeader.headers };
    }
    return axios.put(url, requestBody, { params: queryParams });
}

export function patch(props: PutDataProps) {
    const { url, queryParams, requestBody, customHeader } = { ...defaultPropsOfPostData, ...props };
    const axios = getAxiosInstance();
    if (customHeader) {
        axios.defaults.headers = { ...axios.defaults.headers, ...customHeader.headers };
    }
    return axios.patch(url, requestBody, { params: queryParams });
}
