import { useMutation, useQuery } from "react-query";
import { get, patch, put } from "../../adapters";
import {
    CLIENT_ALL_INVITATION_REQS,
    CLIENT_JOB_APPL_RESPOND,
    POCKETBASE_URL,
} from "../../constants/ApiPaths-constants";

export interface RespondJobApplHookInf {
    jobApplnId: number;
    reqBody: any;
}

export const useRespondJobAppl = () => {
    return useMutation((args: RespondJobApplHookInf) =>
        patch({
            url: `${POCKETBASE_URL}/api/collections/jobApplications/records/${args?.jobApplnId}`,
            requestBody: args.reqBody,
        }),
    );
};

export const useAllJobInterviewList = (clientId: string | number | null) => {
    // TODO @hari-anitha : Need to change this to infinite query to support infinite data fetch
    return useQuery(
        ["allJobInterviewList"],
        async () => {
            const res = await get({
                url: `${POCKETBASE_URL}/api/collections/jobApplications/records?perPage=1000&expand=consultant&filter=(jobDescription.clientDetails='${clientId}')`,
            })
                .then((res: any) => {
                    return res?.data?.items || [];
                })
                .catch((err: any) => {
                    throw new Error(`error occured => ${JSON.stringify(err)}`);
                });
            return res;
        },
        {
            refetchOnWindowFocus: true,
            enabled: !!clientId,
        },
    );
};
