import { Box, Flex, Heading, Text, useBreakpointValue } from "@chakra-ui/react";
import * as React from "react";
import BrandLogoForDarkTheme from "../../../assets/ReactSvgIcons/BrandLogoForDarkTheme-component";
import DemoAccountBtn from "./DemoAccountBtn";

interface IAuthBanner {
    handleDemoAcntSelectn: (options: { userType: "CLIENT" | "CONSULTANT" }) => void;
}
// TODO @umesh : Replace this dummy strings with the actual value
const AuthBanner = ({ handleDemoAcntSelectn }: IAuthBanner) => (
    <Box flex="1" display={{ base: "none", lg: "block" }} bgColor={"#001524"}>
        <Flex
            direction="column"
            px={{ base: "20", md: "36" }}
            py={{ base: "4", md: "10" }}
            color="on-accent"
            alignItems="start"
            h="full"
            justifyContent="center"
            my="auto"
            className="container-1"
        >
            <Flex gap={4}>
                <Box h={8}>
                    <BrandLogoForDarkTheme />
                </Box>
                <Heading
                    color={"white"}
                    size={useBreakpointValue({ md: "md", xl: "lg" })}
                    mb={12}
                    fontFamily={"body"}
                    fontWeight={"semibold"}
                >
                    Welcome to IT Map
                </Heading>
            </Flex>
            <Text color={"blue.400"} fontSize={"xl"} fontWeight={"semibold"} mb={4}>
                About IT Map
            </Text>
            <Text color={"blue.100"} lineHeight={"7"} fontSize={"sm"}>
                IT Map helps clients or companies to post their job opportunities with consultants and freelancers, get
                interact, recruit and organize the contracts or hiring process with ease.
            </Text>
            <Text color={"blue.400"} fontSize={"xl"} fontWeight={"semibold"} mt={8}>
                Check out Demo
            </Text>
            <Text color={"gray.200"} fontSize={"x-small"} fontWeight={"normal"} mb={4}>
                Click an option below and try our app without an account.
            </Text>
            <Flex direction={"column"} justifyContent={"start"} w="full" gap={4}>
                <DemoAccountBtn
                    mainText="Login as Client"
                    subText="List new jobs, schedule interviews & manage recruitments"
                    onClick={() => handleDemoAcntSelectn({ userType: "CLIENT" })}
                />
                <DemoAccountBtn
                    mainText="Login as Consultant"
                    subText="See new jobs, attend interviews, get selected"
                    onClick={() => handleDemoAcntSelectn({ userType: "CONSULTANT" })}
                />
            </Flex>
        </Flex>
    </Box>
);

export default AuthBanner;
