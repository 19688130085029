import { Flex, Spinner } from "@chakra-ui/react";
import React from "react";
import { ConsultantProfileResponse } from "../../../@types/consultant.types";
import ConsultantProfileView from "../../../components/consultantViewPage-components/ConsultantProfile/ConsultantProfileView-component";
import { useGetConsultantProfileById } from "../../../hooks/consultantProfile.hook";
import { useGetConsultantId } from "../../../lib/authFns";

const ConsultantProfile = () => {
    const _consultantId = useGetConsultantId();
    const { data, isLoading } = useGetConsultantProfileById(_consultantId);

    if (isLoading) {
        return (
            <Flex alignItems={"center"} justifyContent={"center"} h="100vh" my={12}>
                <Spinner color="brand.500" thickness="4px" size={{ base: "sm", md: "md", lg: "lg" }} />
            </Flex>
        );
    }
    return <ConsultantProfileView data={data as ConsultantProfileResponse} />;
};

export default ConsultantProfile;
