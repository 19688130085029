import { Button, Text, Badge, Box, Link } from "@chakra-ui/react";
import { format } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RRDLink } from "react-router-dom";
import { InvitationTableHeaderType } from "../../../@types/InvitationTable.types";
import { jobInvitationStatuses } from "../../../constants/Enum-constants";
import { CONSULTANT_JOB_SEARCH_PAGE } from "../../../constants/WebAppRoute-constants";

const headerStyles = {
    fontSize: "sm",
    fontFamily: "Raleway",
    fontWeight: "bold",
    alignItems: "center",
    justifyContent: "center",
    color: "gray.900",
};

const textStyles = {
    fontFamily: "Poppins",
    fontSize: "xs",
    lineHeight: 7,
    fontWeight: "normal",
    color: "gray.600",
};

const useInvitationTableTranslation = (translationVariable: string) => {
    const { t } = useTranslation("consultantInvitationTable");
    return t(translationVariable);
};

export const INVITATION_TABLE_COLUMNS: Array<InvitationTableHeaderType> = [
    {
        Header: () => (
            <Text {...headerStyles} textAlign={"center"} textTransform={"none"}>
                Company
            </Text>
        ),
        accessor: "companyName",
        Cell: (props: any) => {
            return (
                <Text {...textStyles} textAlign={"center"}>
                    {props?.value}
                </Text>
            );
        },
    },
    {
        Header: () => (
            <Text {...headerStyles} textAlign={"center"} textTransform={"none"}>
                {useInvitationTableTranslation("invitationTableHeaders.role")}
            </Text>
        ),
        accessor: "role",
        Cell: (props: any) => {
            return (
                <Text {...textStyles} textAlign={"center"} lineHeight={"base"}>
                    {props.row.original.role}
                </Text>
            );
        },
    },
    {
        Header: () => (
            <Text {...headerStyles} textAlign={"center"} textTransform={"none"}>
                {useInvitationTableTranslation("invitationTableHeaders.jobInvitationStatus")}
            </Text>
        ),
        accessor: "jobInvitationStatus",
        // TODO: any to be replaced
        Cell: (props: any) => {
            const badgeAddtnlStyle = {
                colorScheme: "gray",
                color: "gray.600",
            };
            // TODO @hari-anitha : Use i18n translation here
            let badgeTxt = "Unknown";

            switch (props.value) {
                case jobInvitationStatuses.NEW:
                    badgeAddtnlStyle.colorScheme = "blue";
                    badgeAddtnlStyle.color = "blue.600";
                    // TODO @hari-anitha : use JS Capitalize function to text case instead of hard coding
                    badgeTxt = "New";
                    break;
                case jobInvitationStatuses.ACCEPTED:
                    badgeAddtnlStyle.colorScheme = "green";
                    badgeAddtnlStyle.color = "green.400";
                    badgeTxt = "Accepted";
                    break;
                case jobInvitationStatuses.DENIED:
                    badgeAddtnlStyle.colorScheme = "red";
                    badgeAddtnlStyle.color = "red.500";
                    badgeTxt = "Denied";
                    break;
                case jobInvitationStatuses.SELECTED:
                    badgeAddtnlStyle.colorScheme = "red";
                    badgeAddtnlStyle.color = "red.500";
                    badgeTxt = "Selected";
                    break;
                case jobInvitationStatuses.REJECTED:
                    badgeAddtnlStyle.colorScheme = "red";
                    badgeAddtnlStyle.color = "red.500";
                    badgeTxt = "Rejected";
                    break;
                default:
                    break;
            }
            return (
                <Box style={{ textAlign: "center" }}>
                    <Badge
                        fontFamily="Poppins"
                        fontSize="xs"
                        lineHeight={7}
                        fontWeight="normal"
                        width={28}
                        p={0}
                        borderRadius={20}
                        textAlign={"center"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        variant="subtle"
                        textTransform={"none"}
                        {...badgeAddtnlStyle}
                    >
                        {badgeTxt}
                    </Badge>
                </Box>
            );
        },
    },
    {
        Header: () => (
            <Text {...headerStyles} textAlign={"center"} textTransform={"none"}>
                {useInvitationTableTranslation("invitationTableHeaders.jobInterviewStatus")}
            </Text>
        ),
        accessor: "jobInterviewStatus",
        // TODO: any to be replaced
        Cell: (props: any) => {
            const badgeAddtnlStyle = {
                colorScheme: "gray",
                color: "gray.600",
            };
            // TODO @hari-anitha : Use i18n translation here
            let badgeTxt = "Unknown";

            switch (props.value) {
                case jobInvitationStatuses.NEW:
                    badgeAddtnlStyle.colorScheme = "blue";
                    badgeAddtnlStyle.color = "blue.600";
                    // TODO @hari-anitha : use JS Capitalize function to text case instead of hard coding
                    badgeTxt = "New";
                    break;
                case jobInvitationStatuses.ACCEPTED:
                    badgeAddtnlStyle.colorScheme = "green";
                    badgeAddtnlStyle.color = "green.400";
                    badgeTxt = "Accepted";
                    break;
                case jobInvitationStatuses.DENIED:
                    badgeAddtnlStyle.colorScheme = "red";
                    badgeAddtnlStyle.color = "red.500";
                    badgeTxt = "Denied";
                    break;
                case jobInvitationStatuses.SELECTED:
                    badgeAddtnlStyle.colorScheme = "green";
                    badgeAddtnlStyle.color = "green.500";
                    badgeTxt = "Selected";
                    break;
                case jobInvitationStatuses.REJECTED:
                    badgeAddtnlStyle.colorScheme = "red";
                    badgeAddtnlStyle.color = "red.500";
                    badgeTxt = "Rejected";
                    break;
                case null:
                    badgeTxt = "Not invited";
                    break;
                default:
                    break;
            }
            return (
                <Box style={{ textAlign: "center" }}>
                    <Badge
                        fontFamily="Poppins"
                        fontSize="xs"
                        lineHeight={7}
                        fontWeight="normal"
                        width={28}
                        p={0}
                        borderRadius={20}
                        textAlign={"center"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        variant="subtle"
                        textTransform={"none"}
                        {...badgeAddtnlStyle}
                    >
                        {badgeTxt}
                    </Badge>
                </Box>
            );
        },
    },
    {
        Header: () => (
            <Text {...headerStyles} textAlign={"center"} textTransform={"none"}>
                {useInvitationTableTranslation("invitationTableHeaders.interviewDate")}
            </Text>
        ),
        accessor: "interviewDate",
        // TODO: any to be replaced
        Cell: (props: any) => {
            return (
                <Text {...textStyles} textAlign={"center"}>
                    {props.value ? format(new Date(props.value), "yyyy-MM-dd hh:mm a") : "-"}
                </Text>
            );
        },
    },
    {
        Header: () => (
            <Text {...headerStyles} textAlign={"center"} textTransform={"none"}>
                {useInvitationTableTranslation("invitationTableHeaders.jd")}
            </Text>
        ),
        accessor: "jobDescriptionId",
        // TODO: any to be replaced
        Cell: (props: any) => {
            return (
                //  TODO: colorScheme of Button to be confirmed
                <Link as={RRDLink} to={`${CONSULTANT_JOB_SEARCH_PAGE.url}?job_desc_id=${props.value}`}>
                    <Box style={{ textAlign: "center" }}>
                        <Button
                            fontFamily="Poppins"
                            fontWeight="normal"
                            fontSize="xs"
                            lineHeight={6}
                            borderRadius="lg"
                            alignItems="center"
                            bg="White"
                            color="gray.800"
                            width={20}
                            height={7}
                            colorScheme="BlackAlpha"
                            variant="outline"
                            disabled={!props.value}
                            textAlign={"center"}
                        >
                            {useInvitationTableTranslation("actionButtonText.view")}
                        </Button>
                    </Box>
                </Link>
            );
        },
    },
];
