import React from "react";

const UploadIcon = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 11H11V5H14L10 0L6 5H9V11Z" fill="#F4FFF7" />
            <path d="M18 14H2V7H0V14C0 15.103 0.897 16 2 16H18C19.103 16 20 15.103 20 14V7H18V14Z" fill="#F4FFF7" />
        </svg>
    );
};

export default UploadIcon;
