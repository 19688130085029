import { extendTheme } from "@chakra-ui/react";

const customColorPallets = {
    brand: {
        50: "#EBF8FF",
        100: "#BEE3F8",
        200: "#90CDF4",
        300: "#63B3ED",
        400: "#4299E1",
        500: "#3182CE",
        600: "#2B6CB0",
        700: "#2C5282",
        800: "#2A4365",
        900: "#1A365D",
    },
};

const focusVisibleStyle = {
    _focusVisible: {
        borderColor: "brand.500",
        boxShadow: "none",
    },
};

const invalidFieldStyle = {
    _invalid: {
        boxShadow: "none",
    },
};

const inputSelectStyles = {
    variants: {
        outline: {
            field: {
                ...focusVisibleStyle,
                ...invalidFieldStyle,
            },
            ...focusVisibleStyle,
            ...invalidFieldStyle,
        },
    },
    sizes: {
        md: {
            field: {
                borderRadius: "base",
            },
        },
        lg: {
            borderRadius: "lg",
        },
    },
};

const inputLabelStyles = {
    baseStyle: {
        fontFamily: "special",
        fontWeight: 700,
    },
};

const placeHolderStyles = {
    _placeholder: {
        fontSize: "xs",
    },
};

const formLabelStyles = {
    baseStyle: {
        marginBottom: "4",
    },
};

const linkStyles = {
    baseStyle: {
        _hover: {
            textDecoration: "none",
        },
        _active: {
            textDecoration: "none",
        },
        _visited: {
            textDecoration: "none",
        },
        _focus: {
            textDecoration: "none",
        },
    },
};

const theme = extendTheme({
    styles: {
        global: {
            body: {
                input: {
                    ...placeHolderStyles,
                },
                textarea: {
                    ...placeHolderStyles,
                },
                select: {
                    ...placeHolderStyles,
                },
            },
        },
    },
    fonts: {
        heading: `'Poppins', sans-serif`,
        body: `'Poppins', sans-serif`,
        brand: `'Righteous', sans-serif`,
        special: `'Raleway', sans-serif`,
    },
    colors: {
        ...customColorPallets,
    },
    components: {
        Input: { ...inputSelectStyles },
        Select: { ...inputSelectStyles },
        Textarea: { ...inputSelectStyles },
        FormLabel: { ...inputLabelStyles, ...formLabelStyles },
        Link: { ...linkStyles },
    },
});

export default theme;
