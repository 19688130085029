import { Box, Button, Flex, IconButton, Skeleton, Stack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate, useLocation, useParams } from "react-router-dom";
import { useToggle } from "react-use";
import ApplicationsTab from "./ApplicationsTab";
import MatchingProfilesTab from "./MatchingProfilesTab";
import WatchListTab from "./WatchListTab";
import { JobDataInf } from "../../../@types/job.types";
import CardViewIcon from "../../../assets/ReactSvgIcons/CardViewIcon-component";
import FilterIcon from "../../../assets/ReactSvgIcons/FilterIcon-component";
import HomeIcon from "../../../assets/ReactSvgIcons/HomeIcon-component";
import ListViewIcon from "../../../assets/ReactSvgIcons/ListViewIcon-component";
import PageHeader from "../../../assets/ReactSvgIcons/PageHeader-component";
import JobDetailsSection from "../../../components/consultantViewPage-components/JobDetailsSection/JobDetailsSection-component";
import AppLayout from "../../../components/global/AppLayout/AppLayout-component";
import Breadcrumbs from "../../../components/global/Breadcrumbs/Breadcrumbs-component";
import Footer from "../../../components/global/Footer/Footer-component";
import NavBar from "../../../components/global/NavBar/NavBar-component";
import QueryRenderer from "../../../components/global/QueryRenderer/QueryRenderer-component";
import SvgIconWrapper from "../../../components/global/SvgIconWrapper/SvgIconWrapper.component";
import { tableViewTypes } from "../../../constants/Enum-constants";
import { ACTIVE_TAB, CONSULTANT_ID, TABLE_VIEW } from "../../../constants/UrlParams";
import { CLIENT_JOB_DESCRIPTIONS_PAGE, CLIENT_JOB_DETAILS_PAGE } from "../../../constants/WebAppRoute-constants";
import { useGetJobDescriptionById } from "../../../hooks/jobDescriptions.hook";
import { getAllUrlSearchParams } from "../../../lib/routingUtilityFns";

const IndividualJobDetailsPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { jobDescriptionId = 0 } = useParams();

    const [isFilterShown, toggleFilterShow] = useToggle(false);

    const jobDetailsQueryRes = useGetJobDescriptionById(jobDescriptionId ? jobDescriptionId : 0);

    const searchParamsKeys: string[] = [ACTIVE_TAB, TABLE_VIEW];

    const { t } = useTranslation("consultantListing");

    const getAllSearchParams = () => {
        return getAllUrlSearchParams(searchParams, searchParamsKeys);
    };

    const getActiveTab = () => {
        return searchParams.get(ACTIVE_TAB);
    };

    const getActiveTableViewType = () => {
        return searchParams.get(TABLE_VIEW) || tableViewTypes.LIST;
    };

    const getSelectedConsultantId = () => {
        const _id = searchParams.get(CONSULTANT_ID);
        return _id ? parseInt(_id) : null;
    };

    React.useEffect(() => {
        // Setting up default path if the search params are missing
        if (!getActiveTab() || !getActiveTableViewType()) {
            navigate(`${location.pathname}?${ACTIVE_TAB}=${getDefaultTabKey()}&${TABLE_VIEW}=${tableViewTypes.LIST}`, {
                replace: true,
            });
        }
    }, []);

    /**
     * On click consultant card, need to navigate to page with consultant section on right side 
     even if the filter is turned off
     */
    // React.useEffect(() => {
    //     if (isFilterShown && jobDescriptionId) {
    //         navigate(`${location.pathname}?${ACTIVE_TAB}=${getDefaultTabKey()}&${TABLE_VIEW}=${tableViewTypes.CARD}`, {
    //             replace: true,
    //         });
    //     }
    // }, [isFilterShown]);

    /**
     * Need to make filter toggle off if we have an active consultant section in right side
     */
    React.useEffect(() => {
        const toCompareSearchPath = `?${ACTIVE_TAB}=${getDefaultTabKey()}&${TABLE_VIEW}=${
            tableViewTypes.CARD
        }&${CONSULTANT_ID}=${getSelectedConsultantId()}`;
        if (isFilterShown && location.search === toCompareSearchPath) {
            toggleFilterShow();
        }
    }, [location.search]);

    const handleTabSwitch = (_tabKey: string) => {
        setSearchParams({ ...getAllSearchParams(), [ACTIVE_TAB]: _tabKey });
    };

    const handleTableViewChange = (_tabKey: string) => {
        setSearchParams({ ...getAllSearchParams(), [TABLE_VIEW]: _tabKey });
    };

    const getDefaultTabKey = () => {
        // TODO @hari-anitha :  Commenting the below code to prevent the tabsData getting called before initialization.
        // const defaultTabData = tabsData.find((_tabData) => _tabData.isDefault);
        // return defaultTabData?.key || tabsData[0].key;
        return "applications";
    };

    const tabsData = [
        {
            key: "applications",
            isDefault: true,
            _jsx: <>{t("tabs.tab1.title")}</>,

            pageContent: (
                <ApplicationsTab
                    jobDescriptionId={jobDescriptionId}
                    isFilterShown={isFilterShown}
                    activeTableViewType={getActiveTableViewType()}
                    defaultTabKey={getDefaultTabKey()}
                />
            ),
        },
        {
            key: "matching-profiles",
            _jsx: <> {t("tabs.tab2.title")}</>,
            pageContent: (
                <MatchingProfilesTab
                    activeTableViewType={getActiveTableViewType() || tableViewTypes.LIST}
                    jobDescriptionId={jobDescriptionId || 0}
                    isFilterShown={isFilterShown}
                    defaultTabKey={getDefaultTabKey()}
                />
            ),
        },
        {
            key: "watch-list",
            isDisabled: false,
            _jsx: <>{t("tabs.tab3.title")}</>,
            pageContent: (
                <WatchListTab
                    activeTableViewType={getActiveTableViewType() || tableViewTypes.LIST}
                    jobDescriptionId={jobDescriptionId || 0}
                    isFilterShown={isFilterShown}
                    defaultTabKey={getDefaultTabKey()}
                />
            ),
        },
    ];

    const tableViewData = [
        {
            key: tableViewTypes.LIST,
            icon: (color: string) => <ListViewIcon color={color} />,
        },
        {
            key: tableViewTypes.CARD,
            icon: (color: string) => <CardViewIcon color={color} />,
        },
    ];

    const renderTabBasedContent = () => {
        const activeTabKey = getActiveTab();
        const activeTabData = tabsData.find((_tabData) => {
            return _tabData.key === activeTabKey;
        });
        return activeTabData?.key ? activeTabData.pageContent : null;
    };

    const breadCrumbsData = [
        {
            _jsxContent: <SvgIconWrapper svgComp={<HomeIcon />} alignItems="center" w={3} h="full" mt={0} />,
            pageId: CLIENT_JOB_DESCRIPTIONS_PAGE.pageId,
            url: CLIENT_JOB_DESCRIPTIONS_PAGE.url,
        },
        {
            _jsxContent: "Job Description",
            pageId: CLIENT_JOB_DETAILS_PAGE.pageId,
            url: `${
                CLIENT_JOB_DETAILS_PAGE.url
            }/${jobDescriptionId}?${ACTIVE_TAB}=${getDefaultTabKey()}&${TABLE_VIEW}=${tableViewTypes.LIST}`,
            isCurrentPage: true,
        },
    ];

    return (
        <AppLayout minH={"100vh"}>
            <NavBar />
            {QueryRenderer({
                queryResponse: jobDetailsQueryRes,
                loadingElement: <PageHeader pageText={"..."} previousPagePath={"../"} />,
                jsx: (queryResponse: any) => {
                    const { jobPosition } = queryResponse.data;

                    return <PageHeader pageText={jobPosition} previousPagePath={"../"}></PageHeader>;
                },
            })}
            <Breadcrumbs data={breadCrumbsData} />
            <Box px={"4vw"} mt={6}>
                {QueryRenderer({
                    queryResponse: jobDetailsQueryRes,
                    loadingElement: (
                        <Skeleton
                            h={80}
                            w="full"
                            mb={8}
                            borderRadius="lg"
                            startColor={"gray.50"}
                            endColor={"gray.300"}
                        ></Skeleton>
                    ),
                    jsx: (queryResponse: any) => {
                        const data: JobDataInf = queryResponse.data;
                        return <JobDetailsSection {...data} />;
                    },
                })}

                <Flex w="full" justifyContent={"space-between"} alignItems="center" mt={12}>
                    <IconButton
                        colorScheme="blue"
                        bg="black"
                        aria-label="Search database"
                        isRound
                        icon={<SvgIconWrapper svgComp={<FilterIcon />} w={4} h={4} />}
                        _hover={{ bg: "brand.800" }}
                        onClick={toggleFilterShow}
                    />
                    <Stack spacing={1} direction="row" align="center" p={1} boxShadow="lg" borderRadius={8}>
                        {tabsData.map((_tab) => {
                            const isActiveTab = getActiveTab() === _tab.key;
                            return (
                                <Button
                                    key={_tab.key}
                                    bg={isActiveTab ? "black" : "white"}
                                    color={isActiveTab ? "white" : "gray.600"}
                                    onClick={() => handleTabSwitch(_tab.key)}
                                    fontSize="sm"
                                    fontWeight={"medium"}
                                    px={8}
                                    py={1}
                                    _hover={{ bg: "gray.800", color: "white" }}
                                    _active={{ bg: "gray.800", color: "white" }}
                                    isDisabled={_tab.isDisabled ? _tab.isDisabled : false}
                                >
                                    {_tab._jsx}
                                </Button>
                            );
                        })}
                    </Stack>
                    <Stack spacing={1} direction="row" align="center" p={1} boxShadow="sm" borderRadius={8}>
                        {tableViewData.map(({ key, icon }) => {
                            const isActiveView = getActiveTableViewType() === key;
                            return (
                                <IconButton
                                    key={key}
                                    colorScheme="gray"
                                    bg={isActiveView ? "brand.600" : "white"}
                                    aria-label="Search database"
                                    icon={
                                        <SvgIconWrapper svgComp={icon(isActiveView ? "white" : "gray")} w={4} h={4} />
                                    }
                                    onClick={() => handleTableViewChange(key)}
                                    _hover={{ bg: "brand.200" }}
                                />
                            );
                        })}
                    </Stack>
                </Flex>
                <Flex
                    justifyContent={"center"}
                    mt={8}
                    display="block"
                    minH={"30vh"}
                    alignItems="center"
                    overflow={"auto"}
                    ml={0}
                    mr={0}
                >
                    {renderTabBasedContent()}
                </Flex>
            </Box>
            <Footer />
        </AppLayout>
    );
};

export default IndividualJobDetailsPage;
