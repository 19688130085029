import { Button, Flex, Spinner, useToast, Text, ButtonProps, HStack, TextProps } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";
import { JobApplicationReqBodyInf, JobApplicationRequestInf } from "../../../@types/jobApplication.types";
import { jobInvitationStatuses, jobApplicationStatuses, jobInterviewStatuses } from "../../../constants/Enum-constants";
import { JOB_DESC_ID } from "../../../constants/UrlParams";
import { useCheckJobApplnStatus, useConsultantApplyJob } from "../../../hooks/consultant-hooks/applyJob.hook";
import {
    UpdateJobInvitationMtnInf,
    useGetJobInviteData,
    useUpdateJobInvitation,
} from "../../../hooks/consultant-hooks/invitations.hook";
import { useGetConsultantId } from "../../../lib/authFns";
import { nowDateTimeInIsoString } from "../../../lib/dateTimeFns";

const buttonDefaultStyle: ButtonProps = {
    colorScheme: "blue",
    bg: "black",
    px: 12,
    py: 6,
    fontSize: "xs",
    minW: "56",
};

const textDefaultStyle: TextProps = {
    fontSize: "md",
    fontWeight: "bold",
    color: "brand.600",
    w: "full",
    textAlign: "center",
};

interface ButtonPropInf {
    title: string;
    // eslint-disable-next-line no-unused-vars
    onClick: (data?: any) => void;
    customStyle?: ButtonProps;
}

const CustomButton = ({ title, onClick, customStyle = {} }: ButtonPropInf) => {
    return (
        <Button
            {...buttonDefaultStyle}
            {...customStyle}
            onClick={() => {
                onClick();
            }}
        >
            {title}
        </Button>
    );
};

interface TextPropInf {
    title: string;
    customStyle?: TextProps;
}

const CustomText = ({ title, customStyle = {} }: TextPropInf) => {
    return (
        <Text {...textDefaultStyle} {...customStyle}>
            {title}
        </Text>
    );
};

const ConsultantJobApplicationStatus = () => {
    const { t } = useTranslation("consultantViewJobListing");

    const consultantId = useGetConsultantId() || 0;
    const [searchParams] = useSearchParams();
    const getSelectedJobDescId = () => {
        const _id = searchParams.get(JOB_DESC_ID);
        return _id || _id === "0" ? _id : null;
    };
    const jobDescriptionId = getSelectedJobDescId();
    const toast = useToast();
    const queryClient = useQueryClient();
    const { isLoading, data, isError: isJobApplnStausError } = useCheckJobApplnStatus(jobDescriptionId, consultantId);
    const jobApplyMutation = useConsultantApplyJob();
    const updateInvitationMtn = useUpdateJobInvitation();

    const {
        isLoading: isJobInvitationFetchLoading,
        data: jobInvitationData,
        isError: isJobInvitError,
    } = useGetJobInviteData(jobDescriptionId ? jobDescriptionId : null, consultantId);

    const handleUpdateInvite = async (jobInvitationStatus: string) => {
        const args: UpdateJobInvitationMtnInf = {
            ...jobInvitationData,
            jobInvitationStatus,
        };
        try {
            const queryKeyToInvalidate = [
                "check_job_appln_status",
                "jobDescId",
                jobDescriptionId,
                "consultantId",
                consultantId,
            ];
            const query2ToInvalidate = [
                `getJobInvitationDetails`,
                `jobDescId=${jobDescriptionId}`,
                `consultantId=${consultantId}`,
            ];
            await updateInvitationMtn.mutateAsync(args, {
                onSuccess: async () => {
                    await queryClient.invalidateQueries(query2ToInvalidate);
                    await queryClient.invalidateQueries(queryKeyToInvalidate);
                    toast({
                        title: t("jobDetailsPane.jobApplicationStatus.apiResp.updateJobInviteSuccess"),
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                },
                onError: () => {
                    toast({
                        title: t("jobDetailsPane.jobApplicationStatus.apiResp.updateJobInviteFailure"),
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                },
            });
        } catch (error) {
            toast({
                title: t("jobDetailsPane.jobApplicationStatus.apiResp.updateJobInviteFailure"),
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleJobApplSubmit = async () => {
        const reqBody: JobApplicationReqBodyInf = {
            jobDescriptionId: getSelectedJobDescId() || "",
            consultantId,
            jobAppliedDate: nowDateTimeInIsoString(),
            jobApplicationStatus: jobApplicationStatuses.APPLIED,
            isActive: true,
            createdBy: consultantId.toString(),
            createdDate: nowDateTimeInIsoString(),
        };
        try {
            const query1ToInvalidate = [
                "check_job_appln_status",
                "jobDescId",
                jobDescriptionId,
                "consultantId",
                consultantId,
            ];

            const query2ToInvalidate = [
                `getJobInvitationDetails`,
                `jobDescId=${jobDescriptionId}`,
                `consultantId=${consultantId}`,
            ];

            await jobApplyMutation.mutateAsync(reqBody, {
                onSuccess: async () => {
                    await queryClient.invalidateQueries(query2ToInvalidate);
                    await queryClient.invalidateQueries(query1ToInvalidate);

                    toast({
                        title: t("jobDetailsPane.jobApplicationStatus.apiResp.applyJobSuccess"),
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                },
                onError: () => {
                    toast({
                        title: t("jobDetailsPane.jobApplicationStatus.apiResp.applyJobFailure"),
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                },
            });
        } catch (error) {
            toast({
                title: t("jobDetailsPane.jobApplicationStatus.apiResp.applyJobFailure"),
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    if (isLoading || isJobInvitationFetchLoading || jobApplyMutation?.isLoading || updateInvitationMtn?.isLoading) {
        return (
            <HStack justifyContent={"center"} py={2}>
                <Spinner />
            </HStack>
        );
    }

    if (isJobApplnStausError || isJobInvitError || jobApplyMutation?.isError || updateInvitationMtn?.isError) {
        return (
            <CustomText
                title={t("jobDetailsPane.jobApplicationStatus.unexptedError")}
                customStyle={{ py: 4, borderWidth: "1px", borderColor: "gray.200", borderBottomRadius: "lg" }}
            />
        );
    }

    const { jobAppliedStatus, jobApplicationStatus, jobInvitationStatus, jobInterviewStatus } = jobInvitationData;

    const renderJobApplicationStatus = () => {
        if (!jobAppliedStatus && !jobApplicationStatus && !jobInvitationStatus && !jobInterviewStatus) {
            // Use Case: New job

            return (
                <CustomButton
                    title={t("jobDetailsPane.jobApplicationStatus.applyNow")}
                    onClick={() => handleJobApplSubmit()}
                />
            );
        } else if (jobInterviewStatus === jobInterviewStatuses.REJECTED) {
            // Use Case: Client rejected after interview

            return <CustomText title={t("jobDetailsPane.jobApplicationStatus.interviewRejected")} />;
        } else if (jobInterviewStatus === jobInterviewStatuses.SELECTED) {
            // Use Case: Client selected after interview

            return <CustomText title={t("jobDetailsPane.jobApplicationStatus.interviewSelected")} />;
        } else if (
            jobAppliedStatus &&
            jobApplicationStatus === jobApplicationStatuses.APPLIED &&
            !jobInvitationStatus &&
            !jobInterviewStatus
        ) {
            // Use Case: Conultant applied for the job.

            return <CustomText title={t("jobDetailsPane.jobApplicationStatus.applied")} />;
        } else if (
            jobAppliedStatus &&
            jobApplicationStatus === jobApplicationStatuses.REJECTED &&
            !jobInvitationStatus &&
            !jobInterviewStatus
        ) {
            // Use Case: Client rejected application
            // UI MSG: Client rejected your application

            return <CustomText title={t("jobDetailsPane.jobApplicationStatus.applicationRejected")} />;
        } else if (
            jobInvitationStatus === jobInvitationStatuses.NEW &&
            (!jobApplicationStatus || jobApplicationStatus === jobApplicationStatuses.ACCEPTED)
        ) {
            // Use Case: Client sent invitation to consultant

            return (
                <>
                    <CustomButton
                        title={t("jobDetailsPane.actions.deny")}
                        onClick={() => {
                            handleUpdateInvite(jobInvitationStatuses.DENIED);
                        }}
                        customStyle={{ bg: "red.500", mr: 2, _hover: { bg: "red.600" } }}
                    />

                    <CustomButton
                        title={t("jobDetailsPane.actions.accept")}
                        onClick={() => {
                            handleUpdateInvite(jobInvitationStatuses.ACCEPTED);
                        }}
                    />
                </>
            );
        } else if (
            jobInvitationStatus === jobInvitationStatuses.ACCEPTED &&
            (!jobApplicationStatus || jobApplicationStatus === jobApplicationStatuses.ACCEPTED) &&
            !jobInterviewStatus
        ) {
            // Use Case: Consultant accepted client's invitation

            return <CustomText title={t("jobDetailsPane.jobApplicationStatus.acceptedInvitation")} />;
        } else if (
            jobInvitationStatus === jobInvitationStatuses.DENIED &&
            (!jobApplicationStatus || jobApplicationStatus === jobApplicationStatuses.ACCEPTED)
        ) {
            // Use Case: Consultant denied the invitation.

            return <CustomText title={t("jobDetailsPane.jobApplicationStatus.deniedInvitation")} />;
        } else if (
            jobAppliedStatus &&
            jobApplicationStatus === jobApplicationStatuses.ACCEPTED &&
            !jobInvitationStatus &&
            !jobInterviewStatus
        ) {
            // Use Case: Client accepted consultant job application

            return <CustomText title={t("jobDetailsPane.jobApplicationStatus.applicationAccepted")} />;
        } else {
            return <CustomText title={t("jobDetailsPane.jobApplicationStatus.unexptedError")} />;
        }
    };

    return (
        <Flex
            justifyContent={"flex-end"}
            py={4}
            px={2}
            borderWidth={"1px"}
            borderColor="gray.200"
            borderBottomRadius={"lg"}
        >
            {renderJobApplicationStatus()}
        </Flex>
    );
};

export default ConsultantJobApplicationStatus;
