import { format, formatDistanceStrict, getYear } from "date-fns";
import { formatISO } from "date-fns/esm";

export const dateDistanceFromToday = (toDate: string) => {
    return formatDistanceStrict(new Date(toDate), new Date(), {
        roundingMethod: "ceil",
    });
};

export const nowDateTimeInIsoString = () => {
    return formatISO(new Date());
};

export const distanceFromNowInMonths = (date: string) => {
    const result = formatDistanceStrict(new Date(date), new Date(), {
        unit: "month",
    });
    return result || 0;
};

export const getYearFromDate = (date: string) => {
    return getYear(new Date(date)) || 0;
};

export const convertDateTo_yyyy_MM_dd = (date: string) => {
    let result = "";
    try {
        result = format(new Date(date), "yyyy-MM-dd");
    } catch {
        console.log("Passed invalid date value");
    }
    return result;
};

export const convertDaysToMilliSeconds = (days = 1) => {
    return days * 24 * 60 * 60 * 1000;
};
