export const CLIENT_BASE_PATH = "/client-view";
export const CONSULTANT_BASE_PATH = "/consultant-view";

// CLIENT PAGES
export const CLIENT_JOB_DESCRIPTIONS_PAGE = {
    pageId: "client_job_descriptions_page",
    pageName: "HOME",
    url: CLIENT_BASE_PATH,
    reactRouteWithPathParams: "/client-view",
};

export const CLIENT_JOB_DETAILS_PAGE = {
    pageId: "client_job_details_page",
    pageName: "Job Details",
    url: `${CLIENT_BASE_PATH}/job`,
    reactRouteWithPathParams: `${CLIENT_BASE_PATH}/job/:jobDescriptionId`,
};

export const CLIENT_JOB_ENTRY_FORM_PAGE = {
    pageId: "client_job_entry_form_page",
    pageName: "New Job Description",
    url: `/create-job-description`,
    reactRouteWithPathParams: `/create-job-description`,
};

export const CLIENT_CONSULTANT_SEARCH_PAGE = {
    pageId: "client_consultant_search_page",
    pageName: "Search Consultant",
    url: `${CLIENT_BASE_PATH}/consultant-search`,
    reactRouteWithPathParams: `${CLIENT_BASE_PATH}/consultant-search`,
};

export const CLIENT_INTERVIEW_REQ_PAGE = {
    pageId: "client_interview_req_page",
    pageName: "Sent Interview Requests",
    url: `${CLIENT_BASE_PATH}/interview-list`,
    reactRouteWithPathParams: `${CLIENT_BASE_PATH}/interview-list`,
};

export const CLIENT_LOGIN_PAGE = {
    pageId: "client_login_page",
    pageName: "Login",
    url: "/client/login",
    reactRouteWithPathParams: "/client/login",
};

// CONSULTANT PAGES
export const CONSULTANT_JOB_SEARCH_PAGE = {
    pageId: "consultant_job_search_page",
    pageName: "Job Search",
    url: `${CONSULTANT_BASE_PATH}/job-search-list`,
    reactRouteWithPathParams: `${CONSULTANT_BASE_PATH}/job-search-list`,
};

export const CONSULTANT_INTERVIEW_APPLNS_PAGE = {
    pageId: "consultant_interview_applns_page",
    pageName: "Applied Jobs",
    url: `${CONSULTANT_BASE_PATH}/applications`,
    reactRouteWithPathParams: `${CONSULTANT_BASE_PATH}/applications`,
};

export const CONSULTANT_PROFILE_VIEW_PAGE = {
    pageId: "consultant_profile_page",
    pageName: "Profile View",
    url: `${CONSULTANT_BASE_PATH}/profile`,
    reactRouteWithPathParams: `${CONSULTANT_BASE_PATH}/profile`,
};

export const CONSULTANT_CREATE_PROFILE_PAGE = {
    pageId: "consultant_create_profile_page",
    pageName: "Create Profile",
    url: `${CONSULTANT_BASE_PATH}/create-profile`,
    reactRouteWithPathParams: `${CONSULTANT_BASE_PATH}/create-profile`,
};

export const CONSULTANT_EDIT_PROFILE_PAGE = {
    pageId: "consultant_edit_profile_page",
    pageName: "Edit Profile",
    url: `${CONSULTANT_BASE_PATH}/edit-profile`,
    reactRouteWithPathParams: `${CONSULTANT_BASE_PATH}/edit-profile`,
};

export const CONSULTANT_LOGIN_PAGE = {
    pageId: "consultant_login_page",
    pageName: "Login",
    url: "/consultant/login",
    reactRouteWithPathParams: "/consultant/login",
};
