import React from "react";

const PhoneIcon = () => {
    return (
        <svg width="100%" 
            height="100%" 
            viewBox="0 0 32 32" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
            {/* { eslint-disable-next-line max-len} */}
            <path d="M11.6709 2.7733L11.671 2.77329C13.1233 2.57457 14.3327 3.46454 14.9008 
            4.60012L14.9008 4.60014L16.2455 7.28881L16.2455 7.28882C17.0243 8.84649 16.574 
            10.6664 15.4027 11.8074L15.4026 11.8074C14.7378 12.4543 14.079 13.1847 13.6998 
            13.8219C13.6922 13.8366 13.6899 13.8536 13.6933 13.8698L13.6942 13.874L13.6942 
            13.874C14.0466 15.7838 15.346 17.6854 16.5207 19.0622C16.5601 19.1055 16.6113 
            19.1364 16.6681 19.151C16.7254 19.1658 16.7858 19.1634 16.8417 19.1441L16.8459 
            19.1427L16.8459 19.1427L19.4959 18.3147C19.4959 18.3147 19.4959 18.3147 19.4959 
            18.3147C20.2404 18.0821 21.0397 18.094 21.7769 18.3487C22.5141 18.6034 23.1503 
            19.0876 23.5923 19.7302L23.4687 19.8152L23.5923 19.7302L25.5103 22.5196L25.5103 
            22.5197C26.0506 23.3066 26.3327 24.3862 25.9104 25.4321C25.5493 26.3266 24.8461 
            27.6302 23.5579 28.5002L23.5579 28.5002C22.2138 29.4073 20.383 29.7416 18.0147 
            28.9298L18.0146 28.9298C15.3693 28.022 12.8872 25.7538 10.8391 22.8769L10.8391
             22.8769C8.77684 19.9788 7.08093 16.3619 6.05261 12.5914C5.07657 9.01414 5.65792 
             6.5694 7.07685 4.98324L11.6709 2.7733ZM11.6709 2.7733C10.3814 2.95039 8.43827 
             3.46098 7.07686 4.98323L11.6709 2.7733ZM11.7599 14.2307C12.2093 16.6674 13.7949 
             18.8976 15.0266 20.3406C15.6312 21.049 16.5869 21.2839 17.4327 21.0198L17.4327 
             21.0197L20.0827 20.1917C20.426 20.0845 20.7947 20.0901 21.1346 20.2076C21.4746 
             20.3251 21.7679 20.5485 21.9717 20.8449L22.0953 20.7599L21.9717 20.8449L23.8897 
             23.6342L24.0133 23.5492L23.8897 23.6342C24.1593 24.0262 24.201 24.4133 24.0869 
             24.6957C23.792 25.4258 23.2792 26.3149 22.4573 26.8703C21.6906 27.3873 20.5173 
             27.7088 18.6527 27.0694C16.5587 26.3511 14.3748 24.4526 12.4415 21.7363L12.3193 
             21.8232L12.4415 21.7363C10.5225 19.0403 8.92173 15.6366 7.94938 12.0738C7.08887 
             8.91747 7.70314 7.23401 8.54314 6.29455L8.54316 6.29454C9.44041 5.29081 10.8172 
             4.87609 11.9384 4.72184C12.3644 4.66338 12.8427 4.90723 13.127 5.45064L13.1418 
             5.48034L14.4865 8.16827C14.8367 8.86937 14.6653 9.78002 14.03 10.3984L14.03 10.3984C13.335 
             11.0754 12.5234 11.9525 12.0077 12.8192L12.0075 12.8197C11.7567 13.2445 11.6687 13.7459 11.7599 
             14.2307ZM11.7599 14.2307C11.7598 14.2306 11.7598 14.2305 11.7598 14.2304L11.9073 14.2032M11.7599
             14.2307C11.7599 14.2308 11.7599 14.2309 11.7599 14.231L11.9073 14.2032M11.9073 14.2032C11.8228 
             13.7541 11.9043 13.2895 12.1367 12.8959L20.038 20.0486L17.388 20.8766C16.596 21.1239 15.704 
             20.9032 15.1407 20.2432C13.9133 18.8052 12.3493 16.5999 11.9073 14.2032Z" 
            fill="#8BB9E2" stroke="#878787" strokeWidth="0.3"/>
        </svg>

    );
};

export default PhoneIcon;
