// TODO @hari-anitha : Use translation for toast messages
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { SortOrderEnums } from "../@types/apiRelated.types";
import { JobDescriptionPayloadInf } from "../@types/jobDescritionForm.types";

// import PocketBase from "pocketbase";

// const pb = new PocketBase(process.env.POCKETBASE_API);

import { get, patch, post, put } from "../adapters";
import {
    CREATE_JOB_DESCRIPTION,
    GET_JOB_DESCRIPTION,
    JOB_DESCRIPTIONS,
    EDIT_JOB_DESCRIPTION,
    CLIENT_JOB_APPLN_LIST,
    CLIENT_JOB_DESCRIPTIONS_ON_CLIENT_ID,
    CLIENT_JOB_WATCH_LIST_PROFILES,
    CLIENT_WATCH_LIST_TOGGLE_API_URL,
    CLIENT_JOB_MATCHING_PROFILES,
    POCKETBASE_URL,
} from "../constants/ApiPaths-constants";

/* Contain all the query hooks for job descriptions
 * Includes the entire CRUD queries related to job descriptions
 */

export const useJobDescriptionsByClientId = (clientId: string | number | null) => {
    return useInfiniteQuery(
        `/client-view/jobList/clientID=${clientId}`, // Query key
        async ({ pageParam = 1 }) => {
            const res: any = await get({
                url: `${POCKETBASE_URL}/api/collections/jobDescriptions/records?page=${pageParam}&perPage=10&expand=clientDetails`,
            })
                .then((response: any) => {
                    return response.data;
                })
                .catch((err: any) => {
                    throw new Error(`error occured => ${JSON.stringify(err)}`);
                });
            return res;
        },
        {
            getNextPageParam: (lastPage, pages, ...rest) => {
                // checks if the last page data is empty(needs to be improved in backend), if not passing next page no.
                return lastPage.page < lastPage.totalPages;
            },
            // enabled: !!clientId,
        },
    );
};

export const useJobDescriptions = () => {
    return useInfiniteQuery(
        `/client-view/jobList`, // Query key
        async ({ pageParam = 1 }) => {
            const res: any = await get({
                url: `${POCKETBASE_URL}/api/collections/jobDescriptions/records?page=${pageParam}&perPage=10&expand=clientDetails`,
            })
                .then((response: any) => {
                    return response.data.items;
                })
                .catch((err: any) => {
                    throw new Error(`error occured => ${JSON.stringify(err)}`);
                });
            return res;
        },
        {
            getNextPageParam: (lastPage, pages) => {
                // checks if the last page data is empty(needs to be improved in backend), if not passing next page no.
                return lastPage.page < lastPage.totalPages;
            },
        },
    );
};

const returnOnlyNumberValue = (value: string) => {
    const valueAsInt = parseInt(value);
    if (isNaN(valueAsInt)) {
        return 0;
    }
    return valueAsInt;
};

export const useCreateJobDescription = () => {
    const toast = useToast();
    const { t } = useTranslation("jobDescriptionForm");
    const navigate = useNavigate();

    return useMutation(
        (formData: JobDescriptionPayloadInf) =>
            post({
                url: `${POCKETBASE_URL}/api/collections/jobDescriptions/records`,
                requestBody: formData,
                // requestBody: JSON.parse(
                //     JSON.stringify({
                //         ...formData,
                //         jobExpectedExperienceYrs: returnOnlyNumberValue(formData.jobExpectedExperienceYrs),
                //     }),
                // ),
            }),
        {
            onSuccess: (response) => {
                toast({
                    title: t("createJobDescription.success"),
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                navigate("../");
                console.info("API POST success, response ->", response);
            },
            onError: (error) => {
                toast({
                    title: t("createJobDescription.failure"),
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
                console.info("API POST **failed, error ->", error);
            },
            retry: 1,
            retryDelay: 5000,
        },
    );
};

export const useEditJobDescription = (jobDescriptionId: number) => {
    const toast = useToast();
    const { t } = useTranslation("jobDescriptionForm");
    const navigate = useNavigate();
    return useMutation(
        (formData: JobDescriptionPayloadInf) =>
            patch({
                url: `${POCKETBASE_URL}/api/collections/jobDescriptions/records/${jobDescriptionId}`,
                requestBody: formData,
                // requestBody: JSON.parse(
                //     JSON.stringify({
                //         ...formData,
                //         jobExpectedExperienceYrs: returnOnlyNumberValue(formData.jobExpectedExperienceYrs),
                //     }),
                // ),
            }),
        {
            onSuccess: (response) => {
                toast({
                    title: t("editJobDescription.success"),
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                navigate("../");
                console.info("API POST success, response ->", response);
            },
            onError: (error) => {
                toast({
                    title: t("editJobDescription.failure"),
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
                console.info("API POST **failed, error ->", error);
            },
            retry: 1,
            retryDelay: 5000,
        },
    );
};

export const useGetJobDescriptionById = (jobDescriptionId: any) => {
    return useQuery(
        `getJobDescription/${jobDescriptionId}`,
        async () => {
            const res = await get({
                url: `${POCKETBASE_URL}/api/collections/jobDescriptions/records/${jobDescriptionId}?expand=clientDetails`,
            })
                .then((response: any) => {
                    return response.data;
                })
                .catch((err: any) => {
                    throw new Error(`error occured => ${JSON.stringify(err)}`);
                });
            return res;
        },
        {
            refetchOnWindowFocus: true,
            // enabled: !!Number(jobDescriptionId),
        },
    );
};

interface UseJobAppListPropInf {
    jobDescriptionId: number | null;
}
/*
 * Get all the JOB Applications based on the particular jobDescriptionId
 * TODO: Require support for infinte query later
 */
export const useJobAppList = ({ jobDescriptionId }: UseJobAppListPropInf) => {
    // TODO @hari-anitha : Need to change this to infinite query to support infinite data fetch
    return useQuery(
        ["jobAppList", `${jobDescriptionId}`],
        async () => {
            const res = await get({
                url: `${POCKETBASE_URL}/api/collections/jobApplications/records?perPage=1000&expand=consultant&filter=(jobDescription='${jobDescriptionId}')`,
            })
                .then((res: any) => {
                    return res?.data?.items || [];
                })
                .catch((err: any) => {
                    throw new Error(`error occured => ${JSON.stringify(err)}`);
                });
            return res;
        },
        {
            refetchOnWindowFocus: true,
            enabled: !!jobDescriptionId,
        },
    );
};

export const useGetJobWishlist = (jobDescriptionId: string | number | null) => {
    return useQuery(
        ["GET", "JOB_WATCH_LIST_PROFILES", "jobId=", `${jobDescriptionId}`],
        async () => {
            const res = await get({ url: `${CLIENT_JOB_WATCH_LIST_PROFILES(jobDescriptionId || 0)}` })
                .then((res: any) => {
                    return res.data;
                })
                .catch((err: any) => {
                    throw new Error(`error occured => ${JSON.stringify(err)}`);
                });
            return res;
        },
        {
            /**
             * jobDescID can be zero in the DB
             * since it can come as string or number only loose check.
             */
            enabled: !!jobDescriptionId || jobDescriptionId == 0,
        },
    );
};

export interface InfToggleWatchListHook {
    jobDescriptionId: number;
    consultantId: number;
    userId: number; // client's subscriberId
    isActive: boolean;
}

export const useJobWatchListToggle = () => {
    return useMutation(
        (formData: InfToggleWatchListHook) =>
            post({
                url: CLIENT_WATCH_LIST_TOGGLE_API_URL,
                requestBody: JSON.parse(JSON.stringify(formData)),
            }),
        {
            retry: 1,
            retryDelay: 5000,
        },
    );
};

export const useGetMatchingPrfls = (jobDescriptionId: string | number | null) => {
    return useQuery(
        ["GET", "JOB_MATCHING_PROFILES", "jobId=", `${jobDescriptionId}`],
        async () => {
            const res = await get({ url: `${POCKETBASE_URL}/api/collections/consultants/records?perPage=1000` })
                .then((response: any) => {
                    if (response?.data?.items) {
                        return response?.data?.items.map((_item) => ({
                            ..._item,
                            expand: { consultant: _item },
                        })); /* this is used for a tricky thing since -
                        // in the Ind. Job details page (src/pages/client-pages/individual-job-page/CommonTabArea.tsx) -
                        // UI component we have 2 tabs Application (jobApplication DB coll.) and Matching Profile (consultant collection) -
                        both DB coll have a different schema but we need to use same data transformer (modifyIndConsultantData -> src/lib/dataModifiers.ts#L7) -
                        So we are using this trick for solving it.
                        */
                    }
                    return [];
                })
                .catch((err: any) => {
                    throw new Error(`error occured => ${JSON.stringify(err)}`);
                });
            return res;
        },
        {
            /**
             * jobDescID can be zero in the DB
             * since it can come as string or number only loose check.
             */
            enabled: !!jobDescriptionId || jobDescriptionId == 0,
        },
    );
};
