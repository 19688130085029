// TODO @hari-anitha translation is not used in some places in this component
import {
    Avatar,
    Box,
    Divider,
    Flex,
    Grid,
    GridItem,
    IconButton,
    List,
    ListIcon,
    ListItem,
    Progress,
    Tag,
    Text,
    VStack,
    WrapItem,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { ConsultantDetailsPropsInf } from "../../../@types/consultant.types";
import ExperienceIcon from "../../../assets/ReactSvgIcons/ExperienceIcon-component";
import LocationIcon from "../../../assets/ReactSvgIcons/LocationIcon-component";
import RemainingWorkIcon from "../../../assets/ReactSvgIcons/RemainingWorkIcon-component";
import UnorderedListIcon from "../../../assets/ReactSvgIcons/UnorderedListIcon-component";
import WishlistIcon from "../../../assets/ReactSvgIcons/WishlistIcon-component";
import { InfToggleWatchListingFn } from "../../../pages/client-pages/individual-job-page/CommonTabArea";
import SvgIconWrapper from "../../global/SvgIconWrapper/SvgIconWrapper.component";
interface ConsultantDetailsCompPropsInf extends ConsultantDetailsPropsInf {
    children?: ReactNode;
    wishlisted: boolean;
    // eslint-disable-next-line no-unused-vars
    toggleWatchListing?: (args: InfToggleWatchListingFn) => void | null;
}
const ConsultantDetails = (props: ConsultantDetailsCompPropsInf) => {
    const {
        consultantId,
        nickName,
        avatarUrl,
        qualification,
        workStatus,
        location,
        expInYrs,
        activeRemainingWrkInMonths,
        wishlisted,
        about,
        currentProjectDetails,
        education,
        certifications,
        experience,
        skills,
        hobbies,
        knownLanguages,
        children,
        toggleWatchListing,
    } = props;

    const { t } = useTranslation("consultantListing");

    const [isWatchListed, setWatchListedState] = React.useState(wishlisted);
    const [isWishListingProgressing, setWatchListingLoading] = React.useState(false);

    React.useEffect(() => {
        setWatchListedState(wishlisted);
    }, [wishlisted]);

    const textStyles = {
        fontSize: "xs",
        lineHeight: 6,
        fontWeight: "medium",
        color: "gray.600",
    };
    const headingStyles = {
        fontSize: "base",
        lineHeight: "tight",
        fontFamily: "Raleway",
        fontWeight: "bold",
        color: "gray.900",
        pt: 4,
    };

    const BulletIcon = () => <SvgIconWrapper w={2} pt={2} svgComp={<UnorderedListIcon />} display="inline-block" />;

    const renderList = (dataArray: Array<string>) => {
        return (
            <List spacing={2} mt={0}>
                {dataArray.map((str) => {
                    return (
                        <ListItem key={str} alignItems="center" justifyContent={"center"}>
                            <ListIcon
                                as={BulletIcon}
                                color="green.500"
                                mr={0}
                                p={0}
                                w="fit-content"
                                display={"inline-block"}
                            />
                            <Text {...textStyles} display={"inline-block"} ml={2}>
                                {str}
                            </Text>
                        </ListItem>
                    );
                })}
            </List>
        );
    };
    const _activeRemainingWrkInMonthsWithoutSuffix =
        typeof activeRemainingWrkInMonths === "string"
            ? parseInt(activeRemainingWrkInMonths)
            : activeRemainingWrkInMonths;
    return (
        <Flex flexDirection={"column"} w="full">
            <Flex flexDirection={"column"} w="full" bg="gray.50" px={8} py={6} borderTopRadius="lg">
                <Flex w="full" justifyContent={"space-between"}>
                    <Flex py={6}>
                        <WrapItem justifyContent={"center"} alignItems="center">
                            <Avatar name={nickName} src={avatarUrl} w={20} h={20} />
                        </WrapItem>
                        <Flex flexDirection={"column"} ml={8}>
                            <Text
                                display={"block"}
                                fontWeight="semibold"
                                fontSize={"sm"}
                                lineHeight="taller"
                                noOfLines={1}
                            >
                                {nickName}
                            </Text>
                            <Text
                                display={"block"}
                                fontWeight="medium"
                                fontSize={10}
                                lineHeight="short"
                                mt={2}
                                noOfLines={1}
                            >
                                {qualification}
                            </Text>

                            <Tag
                                variant="solid"
                                bg="blue.100"
                                color="blue.700"
                                alignItems={"center"}
                                justifyContent="center"
                                py={1}
                                px={4}
                                w={"fit-content"}
                                borderRadius="2xl"
                                mt={4}
                                fontSize="xs"
                            >
                                {workStatus}
                            </Tag>
                        </Flex>
                    </Flex>
                    <Flex flexDirection={"column"} py={6}>
                        <Flex justifyContent={"flex-start"} alignItems="center">
                            <SvgIconWrapper svgComp={<LocationIcon />} w={4} h={8} />
                            <Text fontWeight={"normal"} fontSize={10} ml={4} color={"gray.600"} noOfLines={1}>
                                {location}
                            </Text>
                        </Flex>
                        <Flex justifyContent={"flex-start"} alignItems="center">
                            <SvgIconWrapper svgComp={<ExperienceIcon />} w={4} h={8} />
                            <Text fontWeight={"normal"} fontSize={10} ml={4} color={"gray.600"} noOfLines={1}>
                                {expInYrs} years exp
                            </Text>
                        </Flex>
                        <Flex justifyContent={"flex-start"} alignItems="center">
                            <SvgIconWrapper svgComp={<RemainingWorkIcon />} w={4} h={8} />
                            <Text fontWeight={"normal"} fontSize={10} ml={4} color={"gray.600"} noOfLines={1}>
                                {activeRemainingWrkInMonths} Remaining
                            </Text>
                        </Flex>
                    </Flex>
                    <IconButton
                        ml={2}
                        variant="outline"
                        boxShadow="md"
                        isLoading={isWishListingProgressing}
                        icon={
                            <SvgIconWrapper
                                w="fit-contents"
                                svgComp={<WishlistIcon iconColor={isWatchListed ? "#279BEF" : null} />}
                            />
                        }
                        onClick={(e) => {
                            e.stopPropagation();
                            setWatchListingLoading(true);
                            toggleWatchListing &&
                                toggleWatchListing({
                                    consultantId: consultantId,
                                    isWatchListedCurrently: isWatchListed,
                                    onSuccess: () => {
                                        setWatchListingLoading(false);
                                    },
                                    onFailure: () => {
                                        setWatchListingLoading(false);
                                    },
                                });
                        }}
                        aria-label="Wishlist consultant"
                    />
                </Flex>

                <Box w={"40%"} mt={2}>
                    <Progress
                        value={12 - _activeRemainingWrkInMonthsWithoutSuffix}
                        max={12}
                        w="full"
                        borderRadius={4}
                        h={3}
                    />
                </Box>
                <Divider orientation="horizontal" mt={8} />

                <VStack align="stretch" mt={6}>
                    <Text {...headingStyles} textAlign="left">
                        {t("consultantDetails.about")}
                    </Text>
                    <Text {...textStyles} textAlign="left">
                        {about}
                    </Text>
                    <Text {...headingStyles} textAlign="left">
                        {t("consultantDetails.currentProject")}
                    </Text>
                    {renderList(currentProjectDetails)}
                    <Text {...headingStyles} textAlign="left">
                        {t("consultantDetails.education")}
                    </Text>
                    {renderList(education)}
                    <Text {...headingStyles} textAlign="left">
                        {t("consultantDetails.certification")}
                    </Text>
                    {renderList(certifications)}
                    <Text {...headingStyles} textAlign="left">
                        {t("consultantDetails.experience")}
                    </Text>
                    {renderList(experience)}
                    <Text {...headingStyles} textAlign="left">
                        {t("consultantDetails.skills")}
                    </Text>
                    {renderList(skills)}
                    <Text {...headingStyles}>{t("consultantDetails.hobbies")}</Text>
                    {renderList(hobbies)}
                    <Text {...headingStyles}>{t("consultantDetails.languages")}</Text>
                    {renderList(knownLanguages)}
                </VStack>
            </Flex>
            {children}
        </Flex>
    );
};

export default ConsultantDetails;
