import { Box, Flex, Heading, IconButton, Link, Tag, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RRDLink } from "react-router-dom";
import { JobDataInf } from "../../../@types/job.types";
import EditIcon from "../../../assets/ReactSvgIcons/EditIcon-component";
import SvgIconWrapper from "../../global/SvgIconWrapper/SvgIconWrapper.component";

const JobDetailsSection = (props: JobDataInf) => {
    const {
        id: jobDescriptionId,
        jobProjectOverview,
        jobType,
        jobMinSalary,
        jobMaxSalary,
        jobNoofPositions,
        jobExpectedExperienceYrs,
        jobExpectedSkills,
        jobBenefits,
    } = props;

    const { t } = useTranslation("matchingConsultantsPage");
    const monthlyMaxSalaryAmount = jobMaxSalary ? jobMaxSalary / 1000 : "-";
    const monthlyMinSalaryAmoiunt = jobMinSalary ? jobMinSalary / 1000 : "-";
    const monthlySalary = `${`₹`} ${monthlyMinSalaryAmoiunt}k - \
                            ${`₹`} ${monthlyMaxSalaryAmount}k`;

    const headingStyles = {
        fontSize: "sm",
        fontFamily: `special`,
        fontWeight: "700",
        display: "inline",
        w: "20%",
    };
    const textStyles = {
        fontSize: "xs",
        fontWeight: "medium",
        display: "inline",
        ml: 2,
        w: "80%",
    };
    const textContainerBoxStyles = {
        w: "full",
        display: "flex",
    };
    return (
        /* TODO: @hari-anitha
         * Several fields are not mentioned in the create job form. Need improvements
         * Raleway font weight has some issues, need to checked
         */
        <Flex borderWidth={1} borderColor="gray.200" borderRadius={"lg"} p={7} justifyContent="space-between">
            <VStack spacing={4}>
                <Box {...textContainerBoxStyles} mt={0}>
                    <Heading {...headingStyles} as="h3">
                        {t("jobDetailsSection.heading1")} {" : "}
                    </Heading>
                    <Text {...textStyles}>{jobProjectOverview}</Text>
                </Box>
                <Box {...textContainerBoxStyles}>
                    <Heading {...headingStyles} as="h3">
                        {t("jobDetailsSection.heading2")} {" : "}
                    </Heading>
                    <Text {...textStyles}>{jobExpectedSkills}</Text>
                </Box>
                <Box {...textContainerBoxStyles}>
                    <Heading {...headingStyles} as="h3">
                        {t("jobDetailsSection.heading3")} {" : "}
                    </Heading>
                    <Text {...textStyles}>{jobType}</Text>
                </Box>
                <Box {...textContainerBoxStyles}>
                    <Heading {...headingStyles} as="h3">
                        {t("jobDetailsSection.heading4")} {" : "}
                    </Heading>
                    <Text {...textStyles} color="brand.700" fontWeight={"semibold"}>
                        {monthlySalary}
                    </Text>
                </Box>
                <Box {...textContainerBoxStyles}>
                    <Heading {...headingStyles} as="h3">
                        {t("jobDetailsSection.heading6")} {" : "}
                    </Heading>
                    <Text {...textStyles}>{jobExpectedExperienceYrs}</Text>
                </Box>
                <Box {...textContainerBoxStyles}>
                    <Heading {...headingStyles} as="h3">
                        {t("jobDetailsSection.heading7")} {" : "}
                    </Heading>
                    <Text {...textStyles}>{jobNoofPositions}</Text>
                </Box>
                <Box {...textContainerBoxStyles} alignItems="center">
                    <Heading {...headingStyles} as="h3">
                        {t("jobDetailsSection.heading8")} {" : "}
                    </Heading>
                    {jobBenefits && (
                        <Tag
                            size="sm"
                            fontSize={10}
                            fontWeight="semibold"
                            py={1}
                            px={4}
                            color="brand.700"
                            borderRadius="full"
                            variant="outline"
                            colorScheme="blue"
                            ml={2}
                        >
                            {jobBenefits}
                        </Tag>
                    )}
                </Box>
            </VStack>
            <Link mt={"auto"} as={RRDLink} to={`/edit-job-description/${jobDescriptionId}`}>
                <IconButton
                    icon={<SvgIconWrapper svgComp={<EditIcon />} w={5} />}
                    aria-label={t("jobDetailsSection.editButtonAriaLabel")}
                    variant="outline"
                />
            </Link>
        </Flex>
    );
};

export default JobDetailsSection;
