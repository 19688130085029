import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import React from "react";
import { useTable, usePagination } from "react-table";

export interface TableInf {
    data: Array<any>;
    columns: Array<any>;
    tableStyle?: { [k: string]: unknown };
    tHeadStyle?: { [k: string]: unknown };
    tBodyStyle?: { [k: string]: unknown };
    trStyle?: { [k: string]: unknown };
    thStyle?: { [k: string]: unknown };
    tdStyle?: { [k: string]: unknown };
}

const ReactTable = (props: TableInf) => {
    // TODO: Translation might have to be added in the future
    const { data, columns, tableStyle, tHeadStyle, tBodyStyle, trStyle, thStyle, tdStyle } = props;
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        { columns, data },
        usePagination,
    );

    return (
        <Table {...getTableProps()} {...tableStyle}>
            <Thead {...tHeadStyle} {...thStyle}>
                {headerGroups.map((headerGroup) => (
                    <Tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.getHeaderGroupProps().key} {...trStyle}>
                        {headerGroup.headers.map((column) => (
                            <Th key={column.id}>{column.render("Header")}</Th>
                        ))}
                    </Tr>
                ))}
            </Thead>
            <Tbody {...getTableBodyProps()} {...tBodyStyle}>
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <Tr {...row.getRowProps()} key={row.getRowProps().key} {...trStyle}>
                            {row.cells.map((cell) => (
                                <Td
                                    style={{ paddingTop: 6, paddingBottom: 6 }}
                                    {...cell.getCellProps()}
                                    key={cell.getCellProps().key}
                                    {...tdStyle}
                                >
                                    {cell.render("Cell")}
                                </Td>
                            ))}
                        </Tr>
                    );
                })}
            </Tbody>
        </Table>
    );
};

export default ReactTable;
