import { JobDecsByClientURLParamsInf, PaginationParamsInf } from "../@types/apiRelated.types";

export const JOB_DESCRIPTIONS = ({ pageNo, size, sortOrder, sortParam }: PaginationParamsInf) => {
    return `/client/api/v1/jobDescription/getAll?page=${pageNo}&count=${size}&sort=${sortOrder}&field=${sortParam}`;
};
export const CLIENT_JOB_DESCRIPTIONS_ON_CLIENT_ID = ({
    clientId,
    pageNo,
    size,
    sortOrder,
    sortParam,
}: JobDecsByClientURLParamsInf) => {
    return `/client/api/v1/jobDescription/getAllByClient/${clientId}?page=${pageNo}&count=${size}&sort=${sortOrder}&field=${sortParam}`;
};
export const CREATE_JOB_DESCRIPTION = "/client/api/v1/jobDescription/save";
export const EDIT_JOB_DESCRIPTION = "/client/api/v1/jobDescription/update";
export const GET_JOB_DESCRIPTION = "/client/api/v1/jobDescription/get";

// AUTHENTICATION RELATED
export const CONSULTANT_LOGIN = "/api/v1/auth/consultant/signin";
export const CLIENT_LOGIN = "/api/v1/auth/client/signin";

// CONSULTANT RELATED
export const CONSULTANT_APPLY_JOB = "/client/api/v1/jobApplication";
export const CONSULTANT_INVITATIONS = (consultantId: string) =>
    `/client/api/v1/invitation/getAllByConsultantId/${consultantId}`;

export const CONSULTANT_IND_DATA = (consultantId: string | number | null) =>
    `/consultant/api/v1/consultant/get/${consultantId}`;
export const CONSULTANT_GET_ALL = "/consultant/api/v1/consultant/getAll?page=0&count=100&sort=ASC&field=consultantId";
export const JOB_APPLN_STATUS_CHECK = (jobDescId: number | null, consultantId: number | null) => {
    return `/client/api/v1/jobApplication/get/${jobDescId}/${consultantId}`;
};

export const CREATE_CONSULTANT_PROFILE = "/consultant/api/v1/consultant/save";
export const EDIT_CONSULTANT_PROFILE_DATA = (consultantId: number | null) => {
    return `/consultant/api/v1/consultant/update/${consultantId}`;
};

// CLIENT RELATED
export const CLIENT_JOB_APPLN_LIST = "client/api/v1/jobApplication";
export const CLIENT_JOB_APPL_RESPOND = (jobApplId: string | number) => `client/api/v1/jobApplication/${jobApplId}`; //req. URL to Accept/Reject Job Application
export const CLIENT_ALL_INVITATION_REQS = `/client/api/v1/invitation/getAll`;
export const CLIENT_JOB_MATCHING_PROFILES = (jobDescriptionId: string | number) => {
    return `/client/api/v1/matchingProfile/getAll/${jobDescriptionId}`;
};
export const CLIENT_JOB_WATCH_LIST_PROFILES = (jobDescriptionId: string | number) => {
    return `/client/api/v1/watchList/${jobDescriptionId}`;
};
export const CLIENT_CREATE_JOB_INVITE = `client/api/v1/invitation/sendInvite`;
export const CLIENT_GET_JOB_INVITATION_DETAILS = (jobDescriptionId: number, consultantId: number) =>
    `client/api/v1/invitation/get/${jobDescriptionId}/${consultantId}`;
export const CLIENT_WATCH_LIST_TOGGLE_API_URL = `client/api/v1/watchList`;

// GENERAL APIs
export const GENERAL_UPDATE_JOB_INVITE = (_jobInterviewId: number) => `client/api/v1/invitation/${_jobInterviewId}`;

// DEMO CONTANTS
export const POCKETBASE_URL = process.env.REACT_APP_POCKETBASE_API;
export const DEMO_CONSULTANT_ID = process.env.REACT_APP_DEMO_CONSULTANT_ID;
export const DEMO_CLIENT_ID = process.env.REACT_APP_DEMO_CLIENT_ID;

export const DEMO_CLIENT_ACCOUNT = {
    DEMO_CLIENT_ACCOUNT_ID: process.env.REACT_APP_DEMO_CLIENT_ACCOUNT_ID,
    DEMO_CLIENT_ACCOUNT_PASS: process.env.REACT_APP_DEMO_CLIENT_ACCOUNT_PASS,
};

export const DEMO_CONSULTANT_ACCOUNT = {
    DEMO_CONSULTANT_ACCOUNT_ID: process.env.REACT_APP_DEMO_CONSULTANT_ACCOUNT_ID,
    DEMO_CONSULTANT_ACCOUNT_PASS: process.env.REACT_APP_DEMO_CONSULTANT_ACCOUNT_PASS,
};
