import React from "react";

const LogoutIcon = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.5011 14.359H3.47541C3.04025 14.359 2.62283 14.1861 2.31497 13.8784C2.00728 13.5705 1.83438 13.1531 1.83438 12.7179V3.28205C1.83438 2.84689 2.00728 2.42947 2.31497 2.12161C2.62285 1.81392 3.04026 1.64103 3.47541 1.64103H12.5011C12.7943 1.64103 13.0651 1.48462 13.2117 1.23077C13.3582 0.976921 13.3582 0.6641 13.2117 0.410256C13.0651 0.156413 12.7943 0 12.5011 0H3.47541C2.60492 0 1.7701 0.345787 1.15472 0.961359C0.539147 1.57674 0.193359 2.41156 0.193359 3.28205V12.7179C0.193359 13.5884 0.539147 14.4233 1.15472 15.0386C1.7701 15.6542 2.60492 16 3.47541 16H12.5011C12.7943 16 13.0651 15.8436 13.2117 15.5897C13.3582 15.3359 13.3582 15.0231 13.2117 14.7692C13.0651 14.5154 12.7943 14.359 12.5011 14.359Z"
                fill="#525252"
            />
            <path
                d="M15.5574 7.42157L12.501 4.35271C12.2937 4.14538 11.9913 4.06443 11.708 4.14026C11.4246 4.21626 11.2034 4.43751 11.1275 4.72085C11.0515 5.00419 11.1325 5.30637 11.34 5.51389L12.9933 7.17943H7.16767C6.87445 7.17943 6.60358 7.33584 6.45706 7.58969C6.31054 7.84354 6.31054 8.15636 6.45706 8.4102C6.60358 8.66405 6.87446 8.82046 7.16767 8.82046H12.9933L11.3278 10.486C11.1203 10.6957 11.0404 10.9999 11.1182 11.2843C11.1961 11.5688 11.4197 11.79 11.7049 11.8649C11.9902 11.9397 12.2937 11.8567 12.501 11.6472L15.5657 8.58253C15.7197 8.4285 15.8063 8.21971 15.8063 8.00194C15.8063 7.78417 15.7197 7.57538 15.5657 7.42153L15.5574 7.42157Z"
                fill="#525252"
            />
        </svg>
    );
};

export default LogoutIcon;
