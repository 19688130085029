import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en";

const translationData = {
    en,
};

i18n.use(initReactI18next).init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
    resources: translationData,
});

export default i18n;
