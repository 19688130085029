import { Box, chakra, Flex, Heading, IconButton } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ClientViewConsultantSearchBanner from "./ClientViewConsultantSearchBanner-component";
import BannerBg from "../../../assets/Images/ConsultantSearchBanner.svg";
import FilterIcon from "../../../assets/ReactSvgIcons/FilterIcon-component";
// eslint-disable-next-line max-len
import ConsultantTableCardView from "../../../components/consultantViewPage-components/ConsultantTable/ConsultantTableCardView-component";
import ConsultantTableWithDetailedView from "../../../components/consultantViewPage-components/ConsultantTable/ConsultantTableWithDetailedView-component";
import AppLayout from "../../../components/global/AppLayout/AppLayout-component";
import Footer from "../../../components/global/Footer/Footer-component";
import NavBar from "../../../components/global/NavBar/NavBar-component";
import SvgIconWrapper from "../../../components/global/SvgIconWrapper/SvgIconWrapper.component";
import { CONSULTANT_ID } from "../../../constants/UrlParams";
import { useRespondJobAppl } from "../../../hooks/client-hooks/jobApplMng.hooks";
import { useGetConsultantById, useGetAllConsultants } from "../../../hooks/consultantsCommon.hooks";
import { consultantDataSchemeTransport, modifyConsultantArrayData } from "../../../lib/dataModifiers";
import { InfToggleWatchListingFn } from "../individual-job-page/CommonTabArea";

const ConsultantSearchPage = () => {
    const { t } = useTranslation("consultantSearchPage");

    const allConsultantQuery = useGetAllConsultants();
    const { jobDescriptionId } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const handleConsultantCardClick = (_consultantId: number) => {
        navigate(`/client-view/consultant-search?${CONSULTANT_ID}=${_consultantId}`);
    };

    const getSelectedConsultantId = () => {
        const _id = searchParams.get(CONSULTANT_ID);
        return _id ? _id : null;
    };

    const jobApplnReplyMtnQuery = useRespondJobAppl();

    const selectedConsultantDataFetchQuery = useGetConsultantById(getSelectedConsultantId());

    const toggleWatchListing = (args: InfToggleWatchListingFn) => {
        const { consultantId, isWatchListedCurrently, onSuccess, onFailure } = args;
        onFailure();
        console.info(
            "Dummy fn 🚀 ~ file: ConsultantSearchPage.tsx ~ line 91 ~ toggleWatchListing ~ consultantId, isWatchListedCurrently, onSuccess, onFailure",
            consultantId,
            isWatchListedCurrently,
            onSuccess,
            onFailure,
        );
    };

    const tabsData = [
        {
            key: "consultants_search",
            isDefault: true,

            pageContent: () => {
                if (getSelectedConsultantId()) {
                    return (
                        <ConsultantTableWithDetailedView
                            data={allConsultantQuery.data ? modifyConsultantArrayData(allConsultantQuery.data) : []}
                            selectedCstId={getSelectedConsultantId()}
                            jobDescriptionId={jobDescriptionId || 0}
                            selectedConsultantData={
                                consultantDataSchemeTransport(selectedConsultantDataFetchQuery.data) || null
                            }
                            isReqWaiting={jobApplnReplyMtnQuery.isLoading}
                            handleConsultantCardClick={handleConsultantCardClick}
                            submitAreaTxt={""}
                            submitBtnsData={[]}
                            toggleWatchListing={toggleWatchListing}
                        />
                    );
                }
                return (
                    <ConsultantTableCardView
                        data={allConsultantQuery.data ? modifyConsultantArrayData(allConsultantQuery.data) : []}
                        {...{
                            templateColumns: {
                                base: "repeat(1, 1fr)",
                                md: "repeat(3, 1fr)",
                                lg: "repeat(4, 1fr)",
                                "2xl": "repeat(4, 1fr)",
                            },
                            py: 6,
                        }}
                        jobDescriptionId={0}
                        handleConsultantCardClick={handleConsultantCardClick}
                        toggleWatchListing={toggleWatchListing}
                    />
                );
            },
        },
    ];

    const renderTabBasedContent = () => {
        const activeTabData = tabsData.find((_tabData) => {
            return _tabData.key === "consultants_search";
        });

        return activeTabData?.key ? activeTabData?.pageContent() : null;
    };

    return (
        <AppLayout minH={"100vh"}>
            <NavBar />
            <Box
                borderRadius={"2xl"}
                mt={10}
                mx={"4vw"}
                py={12}
                style={{ backgroundImage: `url(${BannerBg})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}
                textAlign={"center"}
            >
                <ClientViewConsultantSearchBanner />
            </Box>

            <Flex justifyContent={"space-between"} alignItems="center" px={"4vw"} mt={12} textAlign={"center"}>
                <Heading
                    as="h2"
                    color="brand.900"
                    mx="auto"
                    w="full"
                    pl={12}
                    fontWeight="normal"
                    fontSize={"2xl"}
                    fontFamily={"body"}
                >
                    {t("searchResult.header.title1")}{" "}
                    <chakra.span color={"brand.900"} fontWeight={"bold"}>
                        {t("searchResult.header.title2")}
                    </chakra.span>
                </Heading>
                <IconButton
                    icon={<SvgIconWrapper svgComp={<FilterIcon />} />}
                    aria-label="filter-jobs"
                    colorScheme={"blue"}
                    bg={"brand.900"}
                    isRound
                    w={12}
                    h={12}
                />
            </Flex>
            <Box justifyContent={"space-between"} alignItems="center" px={"4vw"} mt={12}>
                {renderTabBasedContent()}
            </Box>

            <Footer />
        </AppLayout>
    );
};

export default ConsultantSearchPage;
