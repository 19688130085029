import React from "react";
import CommonTabArea from "./CommonTabArea";
import { MatchingProfilesTabCompPropsInf } from "../../../@types/consultantTable.types";
import { useGetJobWishlist } from "../../../hooks/jobDescriptions.hook";

const WatchListTab = (props: MatchingProfilesTabCompPropsInf) => {
    const { jobDescriptionId, activeTableViewType, defaultTabKey } = props;
    const watchListProfilesQry = useGetJobWishlist(jobDescriptionId);

    return (
        <CommonTabArea
            tableData={watchListProfilesQry.data || []}
            jobDescriptionId={jobDescriptionId}
            activeTableViewType={activeTableViewType}
            isFilterShown={false}
            defaultTabKey={defaultTabKey}
            isTableDataLoading={watchListProfilesQry.isLoading}
        />
    );
};

export default WatchListTab;
