import React from "react";

const BrandLogoForDarkTheme = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.5746 39.1608C19.5681 39.1558 19.5603 39.1526 19.5522 39.1517C19.1599 38.9192 18.8371 38.5825 18.6181 38.1777L13.584 29.0716V0.510254H28.0574V29.0686L23.0177 38.1837C22.8546 38.4781 22.6362 38.7369 22.3749 38.9454C22.1136 39.1538 21.8146 39.3077 21.4949 39.3985C21.1751 39.4892 20.841 39.5149 20.5115 39.4741C20.1821 39.4334 19.8637 39.3269 19.5746 39.1608Z"
                fill="#E2E2E2"
            />
            <path
                d="M11.5497 25.3962L7.06042 17.2779L7.04688 17.2504L0.399298 5.22056C0.13325 4.73925 -0.00437993 4.1954 0.000106266 3.64311C0.00459249 3.09082 0.151036 2.54936 0.424866 2.07262C0.698698 1.59587 1.09036 1.20047 1.56088 0.925759C2.0314 0.65105 2.56437 0.506619 3.10673 0.506836H11.5497V25.3962Z"
                fill="#3A6D92"
            />
            <path
                d="M30.0363 25.3962L34.5255 17.2779L34.5391 17.2504L41.1866 5.22056C41.4527 4.73925 41.5903 4.1954 41.5858 3.64311C41.5813 3.09082 41.4349 2.54936 41.1611 2.07262C40.8872 1.59587 40.4956 1.20047 40.0251 0.925759C39.5545 0.65105 39.0216 0.506619 38.4792 0.506836H30.0363V25.3962Z"
                fill="#3A6D92"
            />
            <g style={{ "mix-blend-mode": "multiply" }} opacity="0.28">
                <g style={{ "mix-blend-mode": "multiply" }} opacity="0.28">
                    <path
                        d="M19.5727 39.1607C19.5661 39.1557 19.5584 39.1526 19.5502 39.1517C19.158 38.9192 18.8351 38.5825 18.6162 38.1777L13.582 29.0716V8.51172C13.6408 9.36641 13.6768 10.2254 13.7128 11.0801C13.7487 11.9348 13.794 12.7938 13.8164 13.6528C13.8752 15.3665 13.9158 17.0754 13.9382 18.7895C13.97 20.5032 13.9805 22.2169 13.9835 23.9306L13.9788 26.5032L13.9611 28.9704C14.8771 30.6752 15.7778 32.3873 16.6634 34.1067C17.1148 34.9846 17.5611 35.8574 17.9943 36.744L18.6487 38.0674C18.7518 38.287 18.8822 38.4924 19.0367 38.6782C19.191 38.865 19.3717 39.0276 19.5727 39.1607Z"
                        fill="#231F20"
                    />
                </g>
            </g>
            <g style={{ "mix-blend-mode": "screen" }} opacity="0.35">
                <g style={{ "mix-blend-mode": "screen" }} opacity="0.35">
                    <path
                        d="M28.0541 24.7437C27.9817 23.7331 27.9411 22.7224 27.8916 21.7161C27.8421 20.7098 27.815 19.6944 27.7744 18.6838L27.7021 15.6562L27.6598 12.6286L27.6463 9.59625L27.6598 6.56865L27.7021 3.54107L27.7651 0.784006C26.8586 0.751697 25.9516 0.728867 25.0446 0.678034C24.0432 0.622893 23.0415 0.586274 22.041 0.508301H28.0549L28.0541 24.7437Z"
                        fill="white"
                    />
                </g>
            </g>
        </svg>
    );
};

export default BrandLogoForDarkTheme;
