import { Box } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { CONSULTANT_APPLICATION_COLUMNS } from "./consultantTableColumns";
import { consultantTableInf } from "../../../@types/consultantTable.types";
import ReactTable from "../../global/Table/Table-component";

const ConsultantTable = ({ data, handleConsultantCardClick }: consultantTableInf) => {
    //  TODO: data to be replaced by that from API

    const tableData = useMemo(() => data, [data]);
    const columns = useMemo(() => CONSULTANT_APPLICATION_COLUMNS(handleConsultantCardClick), []);

    //  TODO: header styles to be modified
    return (
        <Box w="full">
            <ReactTable data={tableData} columns={columns} />
        </Box>
    );
};

export default ConsultantTable;
