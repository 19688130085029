import { Divider, InputGroup, InputLeftElement, FormControl, Button, Flex, Box } from "@chakra-ui/react";
import {
    AsyncSelect,
    OptionBase,
    chakraComponents,
    LoadingIndicatorProps,
    GroupBase,
    SelectComponentsConfig,
    ChakraStylesConfig,
    SingleValue,
} from "chakra-react-select";

import debouncePromise from "debounce-promise";
import React, { useState, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import LocationThinIcon from "../../../assets/ReactSvgIcons/LocationThinIcon-component";
import SearchIcon from "../../../assets/ReactSvgIcons/SearchIcon";
import SvgIconWrapper from "../SvgIconWrapper/SvgIconWrapper.component";

interface OptionItem extends OptionBase {
    label: string;
    value: string;
}

// eslint-disable-next-line no-unused-vars
type OptionsLoader = (value: string) => Promise<Array<OptionItem>>;

interface SearchWithLocationProps {
    debounce: number;
    // eslint-disable-next-line no-unused-vars
    onSubmit: (value: { searchInput: string | undefined; locationInput: string | undefined }) => void;
    // eslint-disable-next-line no-unused-vars
    loadSearchOptions: OptionsLoader;
    // eslint-disable-next-line no-unused-vars
    loadLocationOptions: OptionsLoader;
}

const TEXT_FIELD_COLOR = "#727272";

const asyncComponents: Partial<SelectComponentsConfig<OptionItem, false, GroupBase<OptionItem>>> = {
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
    LoadingIndicator: (props: LoadingIndicatorProps<OptionItem, false, GroupBase<OptionItem>>) => {
        return (
            <chakraComponents.LoadingIndicator
                color={"blue.500"}
                emptyColor={"blue.100"}
                speed="750ms"
                spinnerSize="md"
                thickness="3px"
                {...props}
            />
        );
    },
};

const chakraStyles: ChakraStylesConfig<OptionItem, false, GroupBase<OptionItem>> = {
    placeholder: (provided) => ({
        ...provided,
        color: TEXT_FIELD_COLOR,
    }),
    control: (provided) => ({
        ...provided,
        borderWidth: "0px",
        fontSize: "sm",
    }),
};

const SearchWithLocation = ({
    debounce = 300,
    onSubmit = () => null,
    loadSearchOptions,
    loadLocationOptions,
}: SearchWithLocationProps) => {
    const { t } = useTranslation("searchWithLocation");
    const [searchInput, setSearchInput] = useState<string | undefined>("");
    const [locationInput, setLocationInput] = useState<string | undefined>("");

    const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onSubmit({ searchInput, locationInput });
    };

    const handleLocationInputValueChange = (newValue: SingleValue<OptionItem>) => {
        const value = newValue?.value || newValue?.label;
        setLocationInput(value);
    };

    const handleSearchInputValueChange = (newValue: SingleValue<OptionItem>) => {
        const value = newValue?.value || newValue?.label;
        setSearchInput(value);
    };

    const debounceLoadOption = (loadOptions: OptionsLoader) =>
        debouncePromise(loadOptions, debounce, {
            leading: true,
        });

    return (
        <Flex
            direction={["column","row"]}
            borderRadius={"lg"}
            justifyContent="space-between"
            alignItems="center"
            background={"white"}
            p={1}
            width={["xs","md","2xl"]}
        >
            <FormControl>
                <InputGroup color="gray.900" w="full">
                    <InputLeftElement>
                        <SvgIconWrapper svgComp={<SearchIcon stroke={TEXT_FIELD_COLOR} />} w={4} />
                    </InputLeftElement>
                    <Box w="full" ml={4} fontSize={"sm"}>
                        <AsyncSelect<OptionItem, false, GroupBase<OptionItem>>
                            name="searchInput"
                            size="md"
                            isClearable
                            chakraStyles={chakraStyles}
                            onChange={handleSearchInputValueChange}
                            backspaceRemovesValue
                            placeholder={t("searchInputPlaceholderTxt")}
                            components={asyncComponents}
                            loadOptions={debounceLoadOption(loadSearchOptions)}
                            isDisabled
                        />
                    </Box>
                </InputGroup>
            </FormControl>
            <Divider orientation="vertical" />
            <FormControl>
                <InputGroup color="gray.900" w="full">
                    <InputLeftElement>
                        <SvgIconWrapper svgComp={<LocationThinIcon stroke={TEXT_FIELD_COLOR} />} w={4} />
                    </InputLeftElement>
                    <Box w="full" ml={4} fontSize={"sm"}>
                        <AsyncSelect<OptionItem, false, GroupBase<OptionItem>>
                            name="locationInput"
                            size="md"
                            isClearable
                            chakraStyles={chakraStyles}
                            onChange={handleLocationInputValueChange}
                            backspaceRemovesValue
                            placeholder={t("locationInputPlaceholderTxt")}
                            components={asyncComponents}
                            loadOptions={debounceLoadOption(loadLocationOptions)}
                            isDisabled
                        />
                    </Box>
                </InputGroup>
            </FormControl>
            <FormControl w={["full","fit-content"]}>
                <Button
                    variant={"ghost"}
                    textColor={"white"}
                    bg={"blue.900"}
                    colorScheme="brand"
                    alignItems={"center"}
                    justifyContent={"center"}
                    alignContent="center"
                    size="md"
                    w={["full"]}
                    fontFamily="body"
                    fontWeight={"medium"}
                    fontSize={"sm"}
                    borderRadius={"lg"}
                    px={9}
                    _hover={{
                        background: "brand.700",
                    }}
                    type="submit"
                    onClick={handleSubmit}
                >
                    {t("searchBtnTxt")}
                </Button>
            </FormControl>
        </Flex>
    );
};

export default SearchWithLocation;
