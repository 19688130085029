import React from "react";

const EditIcon = ({
    width = "100%",
    height = "100%",
    stroke = "#001524",
}: {
    width?: string;
    height?: string;
    stroke?: string;
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.8422 3.4787L16.0225 5.65801L13.8422 3.4787ZM15.2442 1.5588L9.34867 7.45437C9.04405 7.75857 8.8363 8.14614 8.7516 8.56822L8.20703 11.2942L10.933 10.7486C11.355 10.6641 11.7421 10.4572 12.0468 10.1525L17.9424 4.25695C18.1196 4.07979 18.2601 3.86947 18.356 3.63799C18.4518 3.40652 18.5012 3.15842 18.5012 2.90788C18.5012 2.65733 18.4518 2.40924 18.356 2.17776C18.2601 1.94629 18.1196 1.73597 17.9424 1.5588C17.7652 1.38164 17.5549 1.24111 17.3234 1.14523C17.092 1.04935 16.8439 1 16.5933 1C16.3428 1 16.0947 1.04935 15.8632 1.14523C15.6317 1.24111 15.4214 1.38164 15.2442 1.5588V1.5588Z"
                stroke={stroke}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.4415 13.3529V16.4412C16.4415 16.9873 16.2246 17.511 15.8385 17.8971C15.4524 18.2832 14.9287 18.5001 14.3826 18.5001H3.05887C2.51282 18.5001 1.98914 18.2832 1.60303 17.8971C1.21692 17.511 1 16.9873 1 16.4412V5.11746C1 4.57142 1.21692 4.04773 1.60303 3.66162C1.98914 3.27551 2.51282 3.05859 3.05887 3.05859H6.14717"
                stroke={stroke}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default EditIcon;
